


















import Vue from 'vue';
import {authConstraints, authRules} from './auth.rules';

export default Vue.extend({
  data() {
    return {
      isValid: false,
      constraint: authConstraints,
      rules: authRules.call(this),
      form: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    formRef(): HTMLFormElement {
      return this.$refs.form as HTMLFormElement;
    },
  },
  methods: {
    async submit() {
      if (!this.formRef.validate()) {
        return;
      }

      try {
        await this.$store.dispatch('auth/signIn', this.form);
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    clear() {
      this.formRef.reset();
    },
  },
});
