





























































import Vue from 'vue';
import {AdminDialog} from '~/components/admin';
import {DeleteDialog, TableRow, TooltipButton, TooltipDate} from '~/components/common';
import {Admin} from '~/models';

export default Vue.extend({
  components: {
    TooltipButton,
    TooltipDate,
    TableRow,
    AdminDialog,
    DeleteDialog,
  },
  data() {
    return {
      editDialog: false,
      removeDialog: false,
    };
  },
  async created() {
    await this.$store.dispatch('admin/fetchAdmin', this.$route.params.id);
    document.title = `LTMS - Admin: ${this.admin.name}`;
  },
  computed: {
    admin(): Admin {
      return Admin.query().with('roles|permissions').find(Number(this.$route.params.id));
    },
    breadcrumbs(): any[] {
      return [
        {text: this.$t('nav.admins'), to: {name: 'admins'}, exact: true},
        {text: this.admin?.name || this.$route.params.id, disabled: true},
      ];
    },
  },
  methods: {
    async deleteAdmin() {
      try {
        await this.$store.dispatch('admin/deleteAdmin', this.admin);
        await this.$store.dispatch('showSuccess', this.$t('msg.deleteSuccess', {item: this.$tc('p.admin')}));
        await this.$router.push({name: 'admins'});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
