











































































































import Vue from 'vue';
import {TranslateResult} from 'vue-i18n';
import {TooltipButton, VueEditor} from '~/components/common';
import {questionRules} from '~/components/question/question.rules';

export default Vue.extend({
  name: 'question-edit-dialog',
  components: {TooltipButton, VueEditor},
  props: {
    value: {type: Boolean, default: false},
    question: {type: Object, default: null},
  },
  data() {
    return {
      rule: questionRules.call(this),
      isValid: true,
      formDefaults: null,
      files: {
        figures: [],
        explanationFigures: [],
      },
      form: {
        number: '',
        language: 'de',
        points: 1,
        text: '',
        figures: [],
        explanation: '',
        explanationFigures: [],
        answers: [],
      },
    };
  },
  async created() {
    this.setFormDefaults();
  },
  watch: {
    async question() {
      if (this.question) {
        await this.$store.dispatch('question/fetchById', this.question.id);
      }
      this.setFormDefaults();
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    headline(): TranslateResult {
      return this.question ? this.$t('action.editItem', {item: this.$tc('p.question')}) : this.$t('action.createItem', {item: this.$tc('p.question')});
    },
    languages(): any[] {
      return [
        {value: 'de', text: this.$t('language.german')},
        {value: 'en', text: this.$t('language.english')},
      ];
    },
    headers(): any[] {
      return [
        {value: 'text', text: this.$t('label.text'), sortable: false},
        {value: 'isCorrect', text: this.$t('label.isCorrect'), sortable: false},
        {value: 'action', text: '', sortable: false},
      ];
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      try {
        const payload = {
          form: Object.assign({}, this.form, {id: this.question.id}),
          figures: this.files.figures,
          explanationFigures: this.files.explanationFigures,
        };

        payload.form.points = Number.isInteger(this.form.points) && this.form.points > 0 ? this.form.points : undefined;

        await this.$store.dispatch('question/updateQuestion', payload);
        await this.$store.dispatch('showSuccess', this.$t('msg.updateSuccess', {item: this.$tc('p.question')}));
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    addAnswer() {
      this.form.answers.push({text: '', isCorrect: false, index: this.form.answers.length});
    },
    removeAnswer(item) {
      const index = this.form.answers.findIndex(a => a.index === item.index);
      this.form.answers.splice(index, 1);
    },
    onSetAsCorrect(item) {
      this.form.answers.forEach(a => a.isCorrect = item.id ? a.id === item.id : a.index === item.index);
    },
    setFormDefaults() {
      this.formDefaults = Object.assign({}, this.form);
      if (this.question) {
        this.formDefaults = {
          number: this.question.number,
          text: this.question.text,
          points: this.question.points,
          answers: this.question.answers.map((a, idx) => ({text: a.text, isCorrect: a.isCorrect, id: a.id, index: idx})),
          language: this.question.language,
          figures: this.question.figures,
          explanation: this.question.explanation,
          explanationFigures: this.question.explanationFigures,
        };
      }
      this.reset();
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      this.form.answers = this.formDefaults.answers.map(a => ({...a}));
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
