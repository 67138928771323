









































import Vue from 'vue';
import {DataTable, DeleteDialog, StateChip, TooltipButton, TooltipDate} from '~/components/common';
import AssignStudentsDialog from '~/components/test/AssignStudentsDialog.vue';
import {Test} from '~/models';

export default Vue.extend({
  name: 'TestTable',
  components: {DataTable, TooltipButton, TooltipDate, DeleteDialog, AssignStudentsDialog, StateChip},
  props: {
    preExam: {type: Boolean, default: false},
    dense: {type: Boolean, default: false},
    tests: {type: Array, default: () => []},
    actionsWidth: {type: String, default: null},
    hideFields: {type: Array, default: () => []},
    actions: {type: Array, default: () => ['edit', 'delete', 'assign']},
    itemsPerPage: {type: Number, default: 10},
  },
  data() {
    return {
      isLoading: false,
      search: '',
      assign: {dialog: false, item: {students: []} as any},
      del: {dialog: false, item: null},
      filterState: 'active',
    };
  },
  computed: {
    items(): Test[] {
      return Test.query().with('students').all().filter(test => (test.type === 'preExam') === this.preExam);
    },
    filteredItems(): Test[] {
      return this.filterState === 'all' ? this.items : this.items.filter(t => {
        switch (this.filterState) {
          case 'active':
            return t.state === '' || t.state === 'active';
          case 'archived':
            return t.state === 'archived';
        }
      });
    },
    filterOptions(): any[] {
      return [
        {text: this.$t('label.all'), value: 'all'},
        {text: this.$t('label.active'), value: 'active'},
        {text: this.$t('label.archived'), value: 'archived'},
      ];
    },
    additionalActions(): any[] {
      return this.actions.filter((action: any) => action && action.icon && action.tooltip && action.emit);
    },
    headers(): any[] {
      const fields = [
        {text: this.$t('label.title'), value: 'title'},
        {text: this.$t('label.state'), value: 'state'},
        {text: this.$t('label.updatedAt'), value: 'updatedAt'},
        {text: this.$t('label.actions'), value: 'actions', align: 'center', sortable: false, width: this.actionsWidth || this.actions.length * 52 + 'px'},
      ];
      return fields.filter(field => !this.hideFields.includes(field.value));
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.$store.dispatch('test/fetchAll');
    } catch (err) {
      await this.$store.dispatch('showError', err);
    }
    this.isLoading = false;
  },
  methods: {
    async onAssignStudents(students: any[]) {
      const payload = {testId: this.assign.item.id, students};
      await this.$store.dispatch('test/assignStudents', payload);
    },
    showField(field: string) {
      return !this.hideFields.includes(field);
    },
    searchFilter(title: string, search: string, test: Test) {
      const searchTerms = search.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && test.title.toLowerCase().includes(term), true);
    },
    async deleteTest() {
      await this.$store.dispatch('test/delete', this.del.item);
      await this.$store.dispatch('showSuccess', this.$root.$t('msg.deleteSuccess', {item: this.$root.$t('label.test')}));
    },
    onEditClick(test: Test) {
      this.$router.push({name: 'test-edit', params: {id: String(test.id)}});
    },
    async archive(test: Test) {
      await this.$store.dispatch('test/archive', test);
    },
    async unarchive(test: Test) {
      await this.$store.dispatch('test/activate', test);
    },
  },
});
