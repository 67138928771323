import {Model} from '@vuex-orm/core';

export class SchoolTestGroup extends Model {
  static entity = 'school_test-group';

  static primaryKey = ['schoolId', 'testGroupId'];

  static fields() {
    return {
      schoolId: this.attr(null),
      testGroupId: this.attr(null),
    };
  }

  schoolId: string;
  testGroupId: string;
}
