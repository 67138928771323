import {Model} from '@vuex-orm/core';
import {Question, TestInstance} from '~/models';

export class TestInstanceQuestion extends Model {
  static entity = 'test-instance_questions';

  static primaryKey = ['questionId', 'testInstanceId'];

  static fields() {
    return {
      testInstanceId: this.attr(null),
      testInstance: this.belongsTo(TestInstance, 'testInstanceId'),
      questionId: this.attr(null),
      question: this.belongsTo(Question, 'questionId'),
    };
  }

  testInstanceId: number;
  testInstance: TestInstance;
  questionId: number;
  question: Question;
}
