













import Resumable from 'resumablejs';
import Vue from 'vue';
import TooltipButton from '~/components/common/TooltipButton.vue';
import {filesize} from '~/util';

export default Vue.extend({
  components: {TooltipButton},
  props: {
    label: {type: String},
    hint: {type: String},
    rules: {type: Array},
  },
  data() {
    return {
      resumable: null,
      isUploadComplete: false,
      isUploading: false,
      uploadProgress: 0,
    };
  },
  mounted() {
    const token = this.$store.state.auth.accessToken;
    this.resumable = new Resumable({
      target: '/api/document/resumable',
      headers: {Authorization: 'Bearer ' + token},
      maxFiles: 1,
    });

    this.resumable.assignBrowse(this.$refs.browseTrigger);

    if (!this.resumable.support) {
      console.log('resumable.js not supported, use fallback instead');
    }
  },
  methods: {
    resumableUpload() {
      this.resumable.on('fileError', (file, message) => {
        console.log('fileError', file, message);
        this.$emit('error', message);
        this.isUploading = false;
      });

      this.resumable.on('fileProgress', resumable => {
        this.uploadProgress = Math.round(resumable.progress() * 100);
      });

      this.resumable.on('complete', () => {
        this.$emit('complete', this.file);
        this.isUploadComplete = true;
        this.isUploading = false;
      });

      this.isUploading = true;
      this.resumable.upload();
    },
    remove() {
      this.resumable.removeFile(this.resumable.files[0]);
    },
    browse() {
      if (this.isUploadComplete || this.isUploading) {
        return;
      }
      this.browseTriggerRef.click();
    },
  },
  computed: {
    browseTriggerRef(): HTMLElement {
      return this.$refs.browseTrigger as HTMLElement;
    },
    hasFile(): boolean {
      return this.resumable && this.resumable.files.length === 1;
    },
    hasValidFile(): boolean {
      return this.fileName.toLowerCase().endsWith('.pdf');
    },
    file(): any {
      try {
        return this.resumable.files[0].file;
      } catch (err) {
        return {};
      }
    },
    fileName(): string {
      return this.file.name || '';
    },
    fileSize(): string {
      if (this.file.size) {
        return filesize(this.file.size);
      }
      return '';
    },
  },
});
