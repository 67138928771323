




























































































































































































import Vue from 'vue';
import {DeleteDialog, StateChip, TooltipButton} from '~/components/common';
import AssignStudentsDialog from '~/components/test/AssignStudentsDialog.vue';
import {Test} from '~/models';

export default Vue.extend({
  components: {TooltipButton, AssignStudentsDialog, DeleteDialog, StateChip},
  data() {
    return {
      assignDialog: false,
      deleteDialog: false,
    };
  },
  async created() {
    await this.$store.dispatch('test/fetchById', this.testId);
    document.title = `LTMS - Test: ${this.test.title}`;
  },
  watch: {
    async '$route.params.id'() {
      await this.$store.dispatch('test/fetchById', this.testId);
    },
  },
  computed: {
    testId() {
      return Number(this.$route.params.id);
    },
    isArchived(): boolean {
      return this.test.state === 'archived';
    },
    breadcrumbs(): any[] {
      return [
        {text: this.$t('nav.tests'), to: {name: 'tests'}, exact: true},
        {text: this.test?.title || this.$route.params.id, disabled: true},
      ];
    },
    test(): Test {
      return Test.query().whereId(this.testId).withAll()
          .with('licences.licence')
          .with('subjects.subject')
          .with('areas.area')
          .with('subAreas.subArea')
          .with('instances.student')
          .first();
    },
    headers(): any[] {
      return [
        {text: this.$t('label.number'), value: 'number', width: '98px'},
        {text: this.$tc('p.question'), value: 'text'},
      ];
    },
    testGroupHeaders(): any[] {
      return [
        {text: this.$t('label.title'), value: 'title'},
      ];
    },
    studentHeaders(): any[] {
      return [
        {text: this.$t('label.name'), value: 'name'},
      ];
    },
    feedback(): string {
      return this.test.feedback ? this.$t(`label.${this.test.feedback}`).toString() : this.$tc('p.none');
    },
    instanceHeaders(): any[] {
      return [
        {text: this.$tc('p.student'), value: 'student.fullName'},
        {text: this.$tc('p.correctAnswer', 2), value: 'correctAnswers'},
        {text: this.$t('label.passed'), value: 'passed'},
        {text: this.$t('label.finishedAt'), value: 'finishedAt'},
      ];
    },
    sharedHeaders(): any[] {
      return [
        {text: this.$t('label.name'), value: 'name'},
        {text: this.$tc('p.question', 2), value: 'limit', width: '100px'},
      ];
    },
    questionAmount(): number {
      return this.test.licences.reduce((prev, licence) => prev + licence.limit, 0)
          + this.test.subjects.reduce((prev, subject) => prev + subject.limit, 0)
          + this.test.areas.reduce((prev, area) => prev + area.limit, 0)
          + this.test.subAreas.reduce((prev, subArea) => prev + subArea.limit, 0)
          + this.test.questions.length;
    },
  },
  methods: {
    getLanguage(locale: string) {
      switch (locale) {
        case 'de':
          return this.$t('language.german');
        case 'en':
          return this.$t('language.english');
        default:
          return '';
      }
    },
    async onAssignStudents(students: any[]) {
      const payload = {testId: this.test.id, students};
      await this.$store.dispatch('test/assignStudents', payload);
    },
    async deleteTest() {
      await this.$store.dispatch('test/delete', this.test);
      await this.$store.dispatch('showSuccess', this.$root.$t('msg.deleteSuccess', {item: this.$root.$t('label.test')}));
      await this.$router.push({name: 'tests'});
    },
    async archiveTest() {
      await this.$store.dispatch('test/archive', this.test);
      await this.$store.dispatch('showSuccess', this.$root.$t('msg.archiveSuccess', {item: this.$root.$t('label.test')}));
    },
    async activateTest() {
      await this.$store.dispatch('test/activate', this.test);
      await this.$store.dispatch('showSuccess', this.$root.$t('msg.activateSuccess', {item: this.$root.$t('label.test')}));
    },
  },
});
