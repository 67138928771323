


























































import Vue from 'vue';
import {DeleteDialog, StateChip, TableRow, TooltipButton, TooltipDate} from '~/components/common';
import {CourseDialog} from '~/components/course';
import {DocumentTable} from '~/components/document';
import {StudentTable} from '~/components/student';
import {Course} from '~/models';

export default Vue.extend({
  components: {
    TooltipButton,
    TooltipDate,
    StateChip,
    TableRow,
    CourseDialog,
    DeleteDialog,
    DocumentTable,
    StudentTable,
  },
  data() {
    return {
      editDialog: false,
      removeDialog: false,
    };
  },
  async created() {
    await this.$store.dispatch('course/fetchCourse', this.$route.params.id);
    document.title = `LTMS - Course: ${this.course.name}`;
  },
  computed: {
    course(): Course {
      return Course.query()
          .with('students.devices|courses')
          .with('documents.versions|courses')
          .find(this.$route.params.id);
    },
    breadcrumbs(): any[] {
      return [
        {text: this.$t('nav.courses'), to: {name: 'courses'}, exact: true},
        {text: this.course?.name || this.$route.params.id, disabled: true},
      ];
    },
  },
  methods: {
    async deleteCourse() {
      try {
        await this.$store.dispatch('course/deleteCourse', this.course);
        await this.$store.dispatch('showSuccess', this.$t('msg.deleteSuccess', {item: this.$tc('p.course')}));
        await this.$router.push({name: 'courses'});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
