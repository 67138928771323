import Vue from 'vue';
import Router from 'vue-router';
import TestGroupCreation from '~/pages/tests/TestGroupCreation.vue';
import TestGroupDetails from '~/pages/tests/TestGroupDetails.vue';
import TestGroupOverview from '~/pages/tests/TestGroupOverview.vue';
import { store } from '~/store';
import { UserRole } from '~/util';
import AdminDetails from './pages/admin/AdminDetails.vue';
import Administration from './pages/admin/Administration.vue';
import Admins from './pages/admin/Admins.vue';
import PermissionDetails from './pages/admin/PermissionDetails.vue';
import Permissions from './pages/admin/Permissions.vue';
import RoleDetails from './pages/admin/RoleDetails.vue';
import Roles from './pages/admin/Roles.vue';
import Analysis from './pages/Analysis.vue';
import CourseDetails from './pages/CourseDetails.vue';
import Courses from './pages/Courses.vue';
import Dashboard from './pages/Dashboard.vue';
import DocumentDetails from './pages/DocumentDetails.vue';
import Documents from './pages/Documents.vue';
import NotFoundPage from './pages/error/NotFoundPage.vue';
import ProgressTracker from './pages/ProgressTracker.vue';
import QuestionDetails from './pages/question/QuestionDetails.vue';
import Questions from './pages/question/Questions.vue';
import SchoolClassDetails from './pages/SchoolClassDetails.vue';
import SchoolDetails from './pages/SchoolDetails.vue';
import Schools from './pages/Schools.vue';
import StudentDetails from './pages/StudentDetails.vue';
import Students from './pages/Students.vue';
import TestCreation from './pages/tests/TestCreation.vue';
import TestDetails from './pages/tests/TestDetails.vue';
import TestEdit from './pages/tests/TestEdit.vue';
import TestInstanceDetails from './pages/tests/TestInstanceDetails.vue';
import TestInstances from './pages/tests/TestInstances.vue';
import TestOverview from './pages/tests/TestOverview.vue';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/', name: 'home', component: Dashboard, async beforeEnter(to, from, next) {
        await store.getters.waitForAuth;
        // @ts-ignore
        if (!store.state.auth.isAuthenticated) {
          next(false);
        } else if (store.getters.hasRole(UserRole.Author)) {
          next('/test-instance');
        } else {
          next('/dashboard');
        }
      },
    },
    {
      path: '/dashboard', name: 'dashboard', component: Dashboard, async beforeEnter(to, from, next) {
        await store.getters.waitForAuth;
        if (store.getters.hasRole(UserRole.Author)) {
          next('/test-instance');
        } else {
          next();
        }
      },
    },
    {path: '/document', name: 'documents', component: Documents},
    {path: '/document/:id', name: 'document-details', component: DocumentDetails},
    {path: '/student', name: 'students', component: Students},
    {path: '/student/:id', name: 'student-details', component: StudentDetails},
    {path: '/course', name: 'courses', component: Courses},
    {path: '/course/:id', name: 'course-details', component: CourseDetails},
    {path: '/school', name: 'schools', component: Schools},
    {path: '/school/:id', name: 'school-details', component: SchoolDetails},
    {path: '/school-class/:id', name: 'school-class-details', component: SchoolClassDetails},
    {path: '/question', name: 'questions', component: Questions},
    {path: '/question/:id', name: 'question-details', component: QuestionDetails},
    {path: '/test', name: 'tests', component: TestOverview},
    {path: '/test-group', name: 'test-groups', component: TestGroupOverview},
    {path: '/test-group/create', name: 'test-group-create', component: TestGroupCreation},
    {path: '/test-group/:id', name: 'test-group-details', component: TestGroupDetails},
    {path: '/test/create', name: 'create-test', component: TestCreation},
    {path: '/test/:id', name: 'test-details', component: TestDetails},
    {path: '/test/:id/edit', name: 'test-edit', component: TestEdit},
    {path: '/test-instance', name: 'test-instances', component: TestInstances},
    {path: '/test-instance/:id', name: 'test-instance-details', component: TestInstanceDetails},
    {path: '/analysis', name: 'analysis', component: Analysis},
    {path: '/progress-tracker', name: 'progress-tracker', component: ProgressTracker},
    {
      path: '/administration', name: 'administration', component: Administration, redirect: '/administration/admins', children: [
        {path: 'admins', name: 'admins', component: Admins},
        {path: 'admins/:id', name: 'admin-details', component: AdminDetails},
        {path: 'roles', name: 'roles', component: Roles},
        {path: 'roles/:id', name: 'role-details', component: RoleDetails},
        {path: 'permissions', name: 'permissions', component: Permissions},
        {path: 'permissions/:id', name: 'permission-details', component: PermissionDetails},
      ],
    },

    {path: '*', name: 'not-found', component: NotFoundPage},
  ],
});
