import {Model} from '@vuex-orm/core';
import {Course, CourseDocument, DocumentVersion, SchoolClass, SchoolClassDocument, Student, StudentBlacklistDocument, StudentDocument} from '../index';

export class Document extends Model {
  static entity = 'documents';

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(''),
      description: this.attr(''),
      displayName: this.attr(''),
      state: this.attr('active'),
      versions: this.hasMany(DocumentVersion, 'documentId'),
      courses: this.belongsToMany(Course, CourseDocument, 'documentId', 'courseId'),
      schoolClasses: this.belongsToMany(SchoolClass, SchoolClassDocument, 'documentId', 'schoolClassId'),
      students: this.belongsToMany(Student, StudentDocument, 'documentId', 'studentId'),
      blacklistedForStudents: this.belongsToMany(Student, StudentBlacklistDocument, 'documentId', 'studentId'),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
    };
  }

  id: string;
  title: string;
  description: string;
  displayName: string;
  state: string;
  versions: DocumentVersion[];
  courses: Course[];
  schoolClasses: SchoolClass[];
  students: Student[];
  blacklistedForStudents: Student[];
  createdAt: string;
  updatedAt: string;

  get downloads(): number {
    return this.versions.reduce((prev, version) => prev + version.downloads, 0);
  }

  get latestVersion(): DocumentVersion | undefined {
    if (this.versions.length > 0) {
      return this.versions.reduce((latestVersion, version) => !latestVersion || latestVersion.createdAt < version.createdAt ? version : latestVersion, null);
    }
  }
}
