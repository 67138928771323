






import Vue from 'vue';

export default Vue.extend({
  name: 'StateChip',
  props: {
    state: {type: String},
  },
  computed: {
    label(): string {
      switch (this.state) {
        case '':
        case 'active':
          return this.$t('label.active') as string;
        case 'inactive':
          return this.$t('label.inactive') as string;
        case 'archived':
          return this.$t('label.archived') as string;
      }
      return '';
    },
    color(): string {
      switch (this.state) {
        case '':
        case 'active':
          return 'green';
        default:
          return 'red';
      }
    },
  },
});
