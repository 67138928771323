












import Vue from 'vue';
import {SignInPanel} from '~/components/auth';

export default Vue.extend({
  components: {SignInPanel},
  created() {
    document.title = 'LTMS - Sign In';
  },
});
