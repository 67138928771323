







































import Vue from 'vue';
import {DeleteDialog, TableRow, TooltipButton, TooltipDate} from '~/components/common';
import {PermissionDialog} from '~/components/permission';
import {Permission} from '~/models';

export default Vue.extend({
  components: {
    TooltipButton,
    TooltipDate,
    TableRow,
    PermissionDialog,
    DeleteDialog,
  },
  data() {
    return {
      editDialog: false,
      removeDialog: false,
    };
  },
  async created() {
    await this.$store.dispatch('permission/fetchPermission', this.$route.params.id);
    document.title = `LTMS - Permission: ${this.permission.name}`;
  },
  computed: {
    permission(): Permission {
      return Permission.query().find(this.$route.params.id);
    },
    breadcrumbs(): any[] {
      return [
        {text: this.$t('nav.permissions'), to: {name: 'permissions'}, exact: true},
        {text: this.permission?.name || this.$route.params.id, disabled: true},
      ];
    },
  },
  methods: {
    async deletePermission() {
      try {
        await this.$store.dispatch('permission/deletePermission', this.permission);
        await this.$store.dispatch('showSuccess', this.$t('msg.deleteSuccess', {item: this.$tc('p.permission')}));
        await this.$router.push({name: 'permissions'});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
