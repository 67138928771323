





















import Vue from 'vue';
import {datetime} from '~/util';
import {AppVersionDialog, LanguageChanger, TooltipButton} from '../common';
import UserMenu from './UserMenu.vue';

export default Vue.extend({
  name: 'app-toolbar',
  filters: {datetime},
  components: {UserMenu, LanguageChanger, AppVersionDialog, TooltipButton},
  data() {
    return {
      appVersion: {dialog: false},
    };
  },
  computed: {
    screen() {
      return this.$store.state.screen;
    },
    app() {
      return this.$store.state.app;
    },
    build() {
      return {
        /* eslint-disable no-undef */
        // @ts-ignore
        time: BUILD_TIME,
        // @ts-ignore
        version: BUILD_VERSION,
        /* eslint-enable no-undef */
      };
    },
  },
  methods: {
    showSidebar() {
      this.$store.dispatch('app/showSidebar', true);
    },
  },
});
