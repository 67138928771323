






import Vue from 'vue';
import {VueEditor} from 'vue2-editor';

export default Vue.extend({
  components: {VueEditor},
  props: {
    value: {type: String, default: ''},
  },
  data() {
    return {
      editorToolbar: [
        [{header: [false, 1, 2, 3, 4, 5, 6]}],
        ['bold', 'italic', 'underline', 'strike'],
        [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
        [{list: 'ordered'}, {list: 'bullet'}, {list: 'check'}],
        [{indent: '-1'}, {indent: '+1'}],
        [{color: []}, {background: []}],
        ['link'],
        ['clean'],
      ],
    };
  },
  computed: {
    content: {
      get(): string {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
  },
});
