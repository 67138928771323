import {Model} from '@vuex-orm/core';
import {Student, Test} from '~/models';


export class StudentTestState extends Model {
  static entity = 'student_test_state';

  static primaryKey = ['studentId', 'testId'];

  static fields() {
    return {
      student: this.belongsTo(Student, 'studentId'),
      studentId: this.attr(null),
      test: this.belongsTo(Test, 'testId'),
      testId: this.attr(null),
      password: this.attr(null),
      state: this.attr(null),
      autoInsight: this.attr(null),
    };
  }

  studentId: string;
  student: Student;
  testId: number;
  test: Test;
  password: string;
  state: string;
  autoInsight: boolean;
}
