








































import Vue from 'vue';
import { TooltipDate } from '~/components/common';
import { TestInstance } from '~/models';

enum FilterState {
  All = 0,
  Passed = 1,
  NotPassed = 2,
  Attention = 3,
}

export default Vue.extend({
  name: 'TestInstanceTable',
  components: {TooltipDate},
  props: {
    loading: {type: Boolean, default: false},
    instances: {type: Array, default: () => []},
    studentNames: {
      type: Object, default: () => {
      },
    },
  },
  data() {
    return {
      search: {
        name: '',
        test: '',
      },
      state: FilterState.All,
      names: {},
      filterFields: ['user', 'displayName'],
    };
  },
  computed: {
    items(): any[] {
      const nameTerms = this.search.name.trim().toLowerCase().split(' ').map(term => term.trim());
      const testTerms = this.search.test.trim().toLowerCase().split(' ').map(term => term.trim());

      const searchFilter = (instance) => {
        const stateFilter = !this.state || (
            this.state === FilterState.Passed && instance.passed
            || this.state === FilterState.NotPassed && !instance.passed
            || this.state === FilterState.Attention && !instance.passed && instance.try >= instance.tries);
        return stateFilter
            && nameTerms.reduce((result, term) => result && this.studentNames[instance.studentId].toLowerCase().includes(term), true)
            && testTerms.reduce((result, term) => result && instance.displayName.toLowerCase().includes(term), true);
      };
      return this.instances.filter(searchFilter);
    },
    filterInFields(): any[] {
      return [
        {text: this.$t('label.displayName'), value: 'displayName'},
        {text: this.$tc('p.student'), value: 'user'},
      ];
    },
    states() {
      return [
        {text: this.$t('label.all'), value: FilterState.All},
        {text: this.$t('label.passed'), value: FilterState.Passed},
        {text: this.$t('label.notPassed'), value: FilterState.NotPassed},
        {text: this.$t('label.attention'), value: FilterState.Attention},
      ];
    },
    headers(): any[] {
      return [
        {text: this.$tc('p.student'), value: 'student'},
        {text: this.$t('label.displayName'), value: 'displayName'},
        {text: this.$tc('label.type'), value: 'type'},
        {text: this.$tc('p.correctAnswer', 2), value: 'correctAnswers'},
        {text: this.$tc('p.try'), value: 'try'},
        {text: this.$tc('label.state'), value: 'passed'},
        {text: this.$t('label.finishedAt'), value: 'finishedAt', align: 'right'},
      ];
    },
  },
  methods: {
    onRowClick(instance: any) {
      this.$router.push({name: 'test-instance-details', params: {id: instance.id}});
    },

    getStudentName: function (id: number) {
      return this.studentNames[id];
    },
    getTypeLabel(type: string) {
      switch (type) {
        case 'default':
          return this.$t('label.default');
        case 'progress':
          return this.$t('label.progressTest');
        case 'final':
          return this.$t('label.finalTest');
        case 'preExam':
          return this.$t('label.preExam');
      }
    },
    getColorClass(item: TestInstance) {
      if (item.passed) {
        return 'instance-green';
      }
      if (item.try < item.tries) {
        return 'instance-orange';
      }

      if (item.try >= item.tries) {
        return 'instance-red';
      }

      return 'instance-orange';
    },
  },
});
