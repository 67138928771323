


















import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {DocumentDialog, DocumentTable} from '~/components/document';
import {Document} from '~/models';

export default Vue.extend({
  components: {DocumentTable, DocumentDialog, TooltipButton},
  data() {
    return {
      add: {dialog: false},
    };
  },
  computed: {
    documents: function (): Document[] {
      return Document.query().with('versions').all();
    },
    text(): string {
      const documentCount = this.documents.length;
      const totalDownloads = this.documents.reduce((pre, doc) => pre + doc.versions.reduce((pre, version) => pre + version.downloads, 0), 0);
      return this.$tc('page.dashboard.documents', documentCount, {documentCount, totalDownloads});
    },
    documentVersionCount(): number {
      return this.documents.reduce((pre, doc) => pre + doc.versions.length, 0);
    },
  },
});
