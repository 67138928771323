

























import Vue from 'vue';
import {studentConstraints, studentRules} from '~/components/student/student.rules';
import {Student, Test, TestGroup} from '~/models';

export default Vue.extend({
  name: 'assign-tests-dialog',
  props: {
    value: {type: Boolean, default: false},
    studentId: {type: String},
  },
  data() {
    return {
      isValid: false,
      isLoading: false,
      showPassword: true,
      formDefaults: null,
      form: {
        tests: [],
        testGroups: [],
      },
      rules: studentRules.call(this),
      constraint: studentConstraints,
    };
  },
  watch: {
    async value(val) {
      if (val) {
        this.isLoading = true;
        await this.$store.dispatch('testGroup/fetchTestAndGroupOptions');
        this.setFormDefaults();
        this.reset();
        this.isLoading = false;
      }
    },
    studentId: {
      immediate: true,
      handler() {
        this.setFormDefaults();
        this.reset();
      },
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    student(): Student {
      return Student.query().whereId(this.studentId).withAll().first();
    },
    tests(): Test[] {
      return Test.all().filter(test => test.state !== 'archived');
    },
    testGroups(): TestGroup[] {
      return TestGroup.all().filter(testGroup => testGroup.state !== 'archived');
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }
      try {
        const payload = {studentId: this.student.id, body: this.form};
        await this.$store.dispatch('student/assignTests', payload);
        this.formDefaults = Object.assign({}, this.form);
        this.dialog = false;
        this.reset();
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    setFormDefaults() {
      this.formDefaults = Object.assign({}, this.form, {
        tests: this.student?.tests.map(test => test.id) || [],
        testGroups: this.student?.testGroups.map(testGroup => testGroup.id) || [],
      });
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
