

























import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {CsvImportDialog, StudentAddDialog, StudentPaginationTable} from '~/components/student';

export default Vue.extend({
  components: {TooltipButton, StudentAddDialog, CsvImportDialog, StudentPaginationTable},
  data() {
    return {
      addDialog: false,
      csvDialog: false,
      searchInput: this.$store.state.student.search,
    };
  },
  created() {
    document.title = 'LTMS - Students';
  },
  computed: {
    search: {
      get(): string {
        return this.$store.state.student.search;
      },
      set(value: string) {
        this.$store.dispatch('student/updateSearch', value);
      },
    },
  },
  methods: {
    submitSearch() {
      this.search = this.searchInput;
    },
    onClearClick() {
      this.search = '';
    },
  },
});
