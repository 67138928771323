import {RuleGenerator} from '~/util/RuleGenerator';

export const authConstraints = {
  name: {isRequired: true, maxLength: 20},
  email: {isRequired: true, maxLength: 40, isEmail: true},
  password: {isRequired: true, minLength: 6},
  initKey: {isRequired: true, length: 60},
};

export function authRules(this: any) {
  return RuleGenerator.generateRules.call(this, authConstraints);
}
