


























import Vue from 'vue';
import {Test, TestGroup} from '~/models';

export default Vue.extend({
  name: 'assign-tests-to-school-dialog',
  props: {
    value: {type: Boolean, default: false},
    school: {type: Object},
  },
  data() {
    return {
      isLoading: false,
      isValid: false,
      formDefaults: null,
      form: {
        tests: [],
        testGroups: [],
      },
    };
  },
  watch: {
    async value(value) {
      if (value) {
        this.isLoading = true;
        await this.$store.dispatch('testGroup/fetchTestAndGroupOptions');
        this.formDefaults = Object.assign({}, this.form, {
          tests: this.school?.tests?.map(test => test.id) || [],
          testGroups: this.school?.testGroups?.map(testGroup => testGroup.id) || [],
        });
        this.reset();
        this.isLoading = false;
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    tests(): Test[] {
      return Test.all().filter(test => test.state !== 'archived');
    },
    testGroups(): TestGroup[] {
      return TestGroup.all().filter(testGroup => testGroup.state !== 'archived');
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }
      try {
        const payload = {schoolId: this.school.id, body: this.form};
        await this.$store.dispatch('school/assignTests', payload);
        this.formDefaults = Object.assign({}, this.form);
        this.dialog = false;
        this.reset();
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
