





















import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {SchoolDialog, SchoolTable} from '~/components/school';

export default Vue.extend({
  components: {TooltipButton, SchoolTable, SchoolDialog},
  data() {
    return {
      addDialog: false,
    };
  },
  created() {
    document.title = 'LTMS - Schools';
  },
  computed: {
    search: {
      get(): string {
        return this.$store.state.school.search;
      },
      set(value: string) {
        this.$store.dispatch('school/updateSearch', value);
      },
    },
  },
});
