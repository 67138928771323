




























































































































import moment from 'moment';
import Vue from 'vue';
import {Course, Document, School} from '~/models';
import {date, sortBy, StorageManager} from '~/util';
import {options, studentConstraints, studentRules} from './student.rules';

export default Vue.extend({
  name: 'student-edit-dialog',
  props: {
    value: {type: Boolean, default: false},
    student: {type: Object, default: null},
  },
  data() {
    return {
      isLoading: false,
      isValid: true,
      menu: {
        validUntil: false,
        validFrom: false,
        testStartFrom: false,
      },
      changePassword: {dialog: false},
      formDefaults: null,
      form: {
        login: '',
        forename: '',
        surname: '',
        email: '',
        phoneNr: '',
        courses: [],
        documents: [],
        school: '',
        schoolClass: [],
        validFrom: '',
        validUntil: '',
        testStartFrom: '',
        noTestEndDate: false,
        deviceSlots: 0,
        language: '',
        type: null,
        notify: false,
      },
      rules: studentRules.call(this),
      constraint: studentConstraints,
      options: {
        classes: this.student?.school?.classes || [],
        deviceSlots: options.deviceSlots,
      },
    };
  },
  async created() {
    this.setFormDefaults();
  },
  watch: {
    async value(value) {
      if (value) {
        this.isLoading = true;
        await this.$store.dispatch('student/fetchDialogOptions');
        this.isLoading = false;
        this.setFormDefaults();
      }
    },
    'form.email'(newValue, oldValue) {
      if (oldValue === this.form.login) {
        this.form.login = newValue;
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    languages(): any[] {
      return [
        {text: this.$t('language.german'), value: 'de'},
        {text: this.$t('language.english'), value: 'en'},
      ];
    },
    userTypes(): any[] {
      return [
        {text: this.$t('user.distance'), value: 'distance'},
        {text: this.$t('user.presence'), value: 'presence'},
        {text: this.$t('user.staff'), value: 'staff'},
        {text: this.$t('user.script'), value: 'script'},
      ];
    },
    courses(): Course[] {
      return Course.all().sort(sortBy('name'));
    },
    schools(): School[] {
      return School.query().with('classes').all().sort(sortBy('name'));
    },
    documents(): Document[] {
      return Document.all();
    },
    locale(): string {
      return StorageManager.getLocale();
    },
    formattedValidFrom(): string {
      return this.form.validFrom ? date(this.form.validFrom) : '';
    },
    formattedValidUntil(): string {
      return this.form.validUntil ? date(this.form.validUntil) : '';
    },
    formattedTestStartFrom(): string {
      return this.form.testStartFrom ? date(this.form.testStartFrom) : '';
    },
    selectedSchool(): string {
      return this.schools.find(school => school.id === this.form.school).name;
    },
    isSchoolClassDropdownDisabled(): boolean {
      return !this.form.school || this.options.classes.length === 0;
    },
    testEndDateHint(): string {
      if (!this.form.testStartFrom || this.form.noTestEndDate) {
        return null;
      }
      const endDate = new Date(this.form.testStartFrom);
      endDate.setMonth(endDate.getMonth() + 18);
      return this.$t('label.endsAt') + ' ' + moment(endDate).format('DD.MM.YYYY');
    },
  },
  methods: {
    courseSearchFilter(course, input, text) {
      const lowerText = text.toLowerCase();
      const searchTerms = input.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && lowerText.includes(term), true);
    },
    documentSearchFilter(document, input, text) {
      const lowerText = text.toLowerCase();
      const searchTerms = input.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && lowerText.includes(term), true);
    },
    onSchoolSelected(schoolId) {
      const school = this.schools.find(s => s.id === schoolId);
      this.form.schoolClass = null;
      if (school && school.classes.length > 0) {
        this.options.classes = school.classes.map(schoolClass => ({name: schoolClass.name, id: schoolClass.id}));
      } else {
        this.options.classes = [];
      }
    },
    async submit() {
      if (!this.isValid) {
        return;
      }
      const payload = Object.assign({}, this.form, {
        validFrom: this.form.validFrom ? moment(this.form.validFrom).toISOString(true) : null,
        validUntil: this.form.validUntil ? moment(this.form.validUntil).toISOString(true) : null,
        testStartFrom: this.form.testStartFrom ? moment(this.form.testStartFrom).toISOString(true) : null,
      });

      try {
        await this.$store.dispatch('student/updateStudent', {id: this.student.id, data: payload});
        await this.$store.dispatch('showSuccess', this.$t('msg.updateSuccess', {item: payload.login}));
        this.dialog = false;
        this.reset();
      } catch (err) {
        await this.$store.dispatch('showError', this.getErrorMessage(err));
      }
    },
    getErrorMessage(err) {
      if (err.response.status === 409) {
        return this.$t('error.emailOrLoginInUse');
      }
      return err;
    },
    setFormDefaults() {
      if (this.student) {
        this.formDefaults = Object.assign({}, {
          login: this.student.login,
          forename: this.student.forename,
          surname: this.student.surname,
          email: this.student.email,
          phoneNr: this.student.phoneNr,
          type: this.student.type,
          courses: this.student.courses?.map(course => course.id) || [],
          documents: this.student.documents?.map(document => document.id) || [],
          school: this.student.schoolId,
          schoolClass: this.student.schoolClassId,
          validFrom: this.student.validFrom ? moment(this.student.validFrom).toISOString(true).substr(0, 10) : null,
          validUntil: this.student.validUntil ? moment(this.student.validUntil).toISOString(true).substr(0, 10) : null,
          testStartFrom: this.student.testStartFrom ? moment(this.student.testStartFrom).toISOString(true).substr(0, 10) : null,
          noTestEndDate: this.student.noTestEndDate,
          deviceSlots: this.student.deviceSlots,
          language: this.student.language,
          notify: false,
        });
        this.options.classes = this.student.school?.classes || [];
      } else {
        this.formDefaults = Object.assign({}, this.form);
      }
      this.reset();
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
