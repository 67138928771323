








































import Vue from 'vue';
import {DataTable, DeleteDialog, StateChip, TooltipButton, TooltipDate} from '~/components/common';
import {CourseDialog} from '~/components/course';
import {DocumentTable} from '~/components/document';
import {StudentTable} from '~/components/student';
import {Course} from '~/models';

export default Vue.extend({
  name: 'course-table',
  components: {TooltipDate, TooltipButton, DeleteDialog, CourseDialog, DataTable, StateChip, StudentTable, DocumentTable},
  props: {
    search: {type: String, default: ''},
    dense: {type: Boolean, default: false},
    courses: {type: Array, default: null},
    actionsWidth: {type: String, default: null},
    hideFields: {type: Array, default: () => []},
    actions: {type: Array, default: () => ['edit', 'delete']},
    itemsPerPage: {type: Number, default: -1},
    expansion: {type: Boolean, default: false},
  },
  data() {
    return {
      isLoading: false,
      edit: {dialog: false, course: null},
      remove: {dialog: false, course: null},
    };
  },
  async created() {
    if (!this.courses) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('course/fetchCourses');
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
      this.isLoading = false;
    }
  },
  computed: {
    items(): Course[] {
      return this.courses as Course[] || Course.query()
          .with('students')
          .with('documents')
          .all();
    },
    additionalActions(): any[] {
      return this.actions.filter((action: any) => action && action.icon && action.tooltip && action.emit);
    },
    headers(): any[] {
      const fields = [
        {text: this.$t('label.name'), value: 'name'},
        {text: this.$t('label.state'), value: 'state', class: 'sm-and-up'},
        {text: this.$tc('p.document', 2), value: 'documents', align: 'end', class: 'md-and-up'},
        {text: this.$tc('p.student', 2), value: 'students', align: 'end', class: 'md-and-up'},
        {text: this.$t('label.updatedAt'), value: 'updatedAt', align: 'end', class: 'sm-and-up'},
        {text: this.$t('label.createdAt'), value: 'createdAt', align: 'end', class: 'lg-and-up'},
        {text: this.$t('label.actions'), value: 'actions', align: 'center', sortable: false, width: this.actionsWidth || this.actions.length * 52 + 'px'},
      ];
      return fields.filter(field => !this.hideFields.includes(field.value));
    },
  },
  methods: {
    searchFilter(title: string, search: string, course: Course) {
      const searchTerms = search.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && course.name.toLowerCase().includes(term), true);
    },
    showEditDialog(course) {
      this.edit.dialog = true;
      this.edit.course = course;
    },
    showDeleteDialog(course) {
      this.remove.dialog = true;
      this.remove.course = course;
    },
    showField(field: string) {
      return !this.hideFields.includes(field);
    },
    async deleteCourse() {
      try {
        await this.$store.dispatch('course/deleteCourse', this.remove.course);
        this.remove.course = null;
        const msg = this.$t('msg.deleteSuccess', {item: this.$tc('p.course')});
        await this.$store.dispatch('showSuccess', msg);
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
