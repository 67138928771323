import { render, staticRenderFns } from "./PaginatedDataTable.vue?vue&type=template&id=57c13bd3&scoped=true&"
import script from "./PaginatedDataTable.vue?vue&type=script&lang=ts&"
export * from "./PaginatedDataTable.vue?vue&type=script&lang=ts&"
import style0 from "./PaginatedDataTable.vue?vue&type=style&index=0&id=57c13bd3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57c13bd3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VAlert,VDataTable,VProgressLinear})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
