export class FormHelper {
  static isEqualTo(subject, base) {
    if (subject === base) {
      return true;
    }
    if (Array.isArray(subject) && Array.isArray(base)) {
      return FormHelper.arraysEqual(subject, base);
    }
    if (FormHelper.isObject(subject) && FormHelper.isObject(base)) {
      return Object.keys(subject).reduce((prevVal, key) => prevVal && FormHelper.isEqualTo(subject[key], base[key]), true);
    }

    return false;
  }

  static resetFrom(subject, base) {
    if (typeof (subject) !== 'object' || typeof (base) !== 'object') {
      return;
    }

    Object.keys(subject).forEach(key => {
      subject[key] = base[key];
    });
  }

  static arraysEqual(a, b) {
    if (a === b) {
      return true;
    }
    if (a == null || b == null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    return true;
  }

  static isObject(a) {
    return typeof (a) === 'object' && a !== null;
  }
}
