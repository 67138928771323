









import Vue from 'vue';
import {AdminNavigation} from '~/components/admin';
import {store} from '~/store';

export default Vue.extend({
  components: {AdminNavigation},
  async beforeRouteEnter(to, from, next) {
    await store.getters.waitForAuth;
    if (store.getters.hasPermission('frontend:administration')) {
      next();
    }
  },
});
