import {Module} from 'vuex';


export interface AppState {
  showSidebar: boolean,
  isLoading: boolean,
  title: string,
  titleShort: string,
  openRequests: number,
}

export const appModule: Module<AppState, any> = {
  namespaced: true,
  state: {
    showSidebar: true,
    isLoading: false,
    title: 'LunaTech Mentoring System',
    titleShort: 'LTMS',
    openRequests: 0,
  },
  mutations: {
    SHOW_SIDEBAR: (state, show) => {
      state.showSidebar = show;
    },
    START_REQUEST: (state) => {
      state.openRequests++;
    },
    STOP_REQUEST: (state) => {
      state.openRequests--;
    },
  },
  actions: {
    showSidebar({state, commit}, show: boolean) {
      commit('SHOW_SIDEBAR', show);
    },
    startRequest({commit}) {
      commit('START_REQUEST');
    },
    stopRequest({commit}) {
      commit('STOP_REQUEST');
    },
  },
  getters: {},
};
