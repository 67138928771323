import {Module} from 'vuex';
import {Answer, Area, Database, Licence, Question, SubArea, Subject} from '~/models';
import {api} from '~/util/api';

const UPDATE_THRESHOLD = 60000; // 60 sek

export interface QuestionState {
  search: string,
  fetchedAt: number,
  questionFetchedAt: { [id: number]: number },
}

export const questionModule: Module<QuestionState, any> = {
  namespaced: true,
  state: {
    search: '',
    fetchedAt: 0,
    questionFetchedAt: {},
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
  },
  actions: {
    test: async (context, filter) => {
      const response = await api.get('/api/question/test');
      return response.data;
    },
    importQuestions: async (context, payload: { type: string, targetId: number, import: any }) => {
      const response = await api.put(`/api/question/import/${payload.type}/${payload.targetId}`, payload.import);
      return response.data;
    },
    removeFromSection: async (context, payload: { question: Question, section: { type: string, id: number, name: string } }) => {
      await api.put(`/api/question/remove/${payload.question.id}`, {type: payload.section.type, id: payload.section.id});
    },
    updateSearch: async (context, search) => {
      context.commit('SET_SEARCH', search);
    },
    fetchAll: async (context) => {
      if (Date.now() - context.state.fetchedAt > UPDATE_THRESHOLD) {
        context.state.fetchedAt = Date.now();
        const response = await api.get('/api/question');
        await Question.insert({data: response.data});
      }
    },
    fetchQuestions: async (context, filter) => {
      const response = await api.get('/api/question', {params: filter});
      return response.data;
    },
    createLicence: async (context, payload) => {
      const response = await api.post('/api/question/licence', payload);
      await Licence.insert({data: response.data});
    },
    updateLicence: async (context, payload) => {
      const response = await api.put(`/api/question/licence/${payload.id}`, payload.data);
      await Licence.update({where: payload.id, data: payload.data});
    },
    deleteLicence: async (context, licenceId) => {
      await api.delete(`/api/question/licence/${licenceId}`);
      await Licence.delete(licenceId);
    },
    createSubject: async (context, payload) => {
      const response = await api.post('/api/question/subject', payload);
      await Subject.insert({data: response.data});
    },
    updateSubject: async (context, payload) => {
      const response = await api.put(`/api/question/subject/${payload.id}`, payload.data);
      await Subject.update({where: payload.id, data: payload.data});
    },
    deleteSubject: async (context, subjectId) => {
      await api.delete(`/api/question/subject/${subjectId}`);
      await Subject.delete(subjectId);
    },
    createArea: async (context, payload) => {
      const response = await api.post('/api/question/area', payload);
      await Area.insert({data: response.data});
    },
    updateArea: async (context, payload) => {
      await api.put(`/api/question/area/${payload.id}`, payload.data);
      await Area.update({where: payload.id, data: payload.data});
    },
    deleteArea: async (context, areaId) => {
      await api.delete(`/api/question/area/${areaId}`);
      await Area.delete(areaId);
    },
    createSubArea: async (context, payload) => {
      const response = await api.post('/api/question/subarea', payload);
      await SubArea.insert({data: response.data});
    },
    updateSubArea: async (context, payload) => {
      await api.put(`/api/question/subarea/${payload.id}`, payload.data);
      await SubArea.update({where: payload.id, data: payload.data});
    },
    deleteSubArea: async (context, subareaId) => {
      await api.delete(`/api/question/subarea/${subareaId}`);
      await SubArea.delete(subareaId);
    },
    createQuestion: async (context, payload) => {
      const formData = new FormData();
      const blob = new Blob([JSON.stringify(payload.form)], {type: 'application/json'});

      formData.append('json', blob);
      payload.figures.forEach(figure => formData.append('figures', figure));
      payload.explanationFigures.forEach(explanationFigure => formData.append('explanationFigures', explanationFigure));

      const response = await api.post('/api/question', formData);
      await Question.insert({data: response.data});
      return response.data;
    },
    updateQuestion: async (context, payload) => {
      const formData = new FormData();
      const blob = new Blob([JSON.stringify(payload.form)], {type: 'application/json'});

      formData.append('json', blob);
      payload.figures.forEach(figure => formData.append('figures', figure));
      payload.explanationFigures.forEach(explanationFigure => formData.append('explanationFigures', explanationFigure));

      const response = await api.post(`/api/question/update`, formData);
      //TODO: update existing answers?!
      await Answer.delete(answer => answer.questionId === payload.form.id);
      await Question.insertOrUpdate({data: response.data});
      return response.data;
    },
    fetchDatabases: async (context) => {
      const response = await api.get('/api/question/databases');
      await Database.insert({data: response.data});
    },
    fetchDatabase: async (context, id) => {
      const response = await api.get(`/api/question/database/${id}`);
      await Database.insert({data: response.data});
    },
    fetchLicencesDeep: async (context) => {
      const response = await api.get('/api/question/licences/deep');
      await Licence.insert({data: response.data});
    },
    fetchLicences: async (context) => {
      const response = await api.get('/api/question/licences');
      await Licence.insert({data: response.data});
    },
    fetchLicence: async (context, payload: { licence: string, deep: boolean }) => {
      let url = `/api/question/licence/${payload.licence}`;
      url = payload.deep ? url + '/deep' : url;
      const response = await api.get(url);
      await Licence.insert({data: response.data});
    },
    fetchSubjects: async (context, payload: { licence: string }) => {
      const response = await api.get('/api/question/subjects', {params: {licence: payload.licence}});
      await Subject.insert({data: response.data});
    },
    fetchSubject: async (context, payload: { subject: string, deep: boolean }) => {
      let url = `/api/question/subject/${payload.subject}`;
      url = payload.deep ? url + '/deep' : url;
      const response = await api.get(url);
      await Subject.insert({data: response.data});
    },
    fetchAreas: async (context, payload: { subject: string }) => {
      const response = await api.get('/api/question/areas', {params: {subject: payload.subject}});
      await Area.insert({data: response.data});
    },
    fetchArea: async (context, payload: { area: string, deep: boolean }) => {
      let url = `/api/question/area/${payload.area}`;
      url = payload.deep ? url + '/deep' : url;
      const response = await api.get(url);
      await Area.insert({data: response.data});
    },
    fetchSubAreas: async (context, payload: { area: string }) => {
      const response = await api.get('/api/question/sub-areas', {params: {area: payload.area}});
      await SubArea.insert({data: response.data});
    },
    fetchSubArea: async (context, payload: { subArea: string, deep: boolean }) => {
      let url = `/api/question/sub-area/${payload.subArea}`;
      url = payload.deep ? url + '/deep' : url;
      const response = await api.get(url);
      await SubArea.insert({data: response.data});
    },
    getCount: async (context, options: { type: string, ids: number[] }) => {
      const response = await api.post('/api/question/count', options);
      return response.data;
    },
    fetchById: async (context, id) => {
      if (!context.state.questionFetchedAt[id] || Date.now() - context.state.questionFetchedAt[id] > UPDATE_THRESHOLD) {
        context.state.questionFetchedAt[id] = Date.now();
        const response = await api.get(`/api/question/${id}`);
        await Question.insert({data: response.data});
      }
    },
    fetchByNumber: async (context, number) => {
      const response = await api.get(`/api/question/number/${number}`);
      await Question.insert({data: response.data});
    },
    export: async (context, questionIds) => {
      const response = await api.post('/api/question/export', {questionIds: questionIds});
      return response.data;
    },
    removeAll: async (context) => {
      await api.delete('/api/question/removeAll');
      await Question.deleteAll();
    },
    delete: async (context, question: Question) => {
      await api.delete(`/api/question/${question.id}`);
      await Question.delete(question.id);
    },
    getWeakpointData: async (context, payload) => {
      const response = await api.post(`/api/question/weakpoint`, payload);
      return response.data;
    },
  },
  getters: {},
};
