













































import Vue from 'vue';
import {DataTable, DeleteDialog, StateChip, TooltipButton, TooltipDate} from '~/components/common';
import {AssignTestsToSchoolDialog, SchoolDialog} from '~/components/school';
import {School} from '~/models';

export default Vue.extend({
  name: 'school-table',
  components: {TooltipDate, TooltipButton, DeleteDialog, SchoolDialog, DataTable, StateChip, AssignTestsToSchoolDialog},
  props: {
    search: {type: String, default: ''},
    schools: {type: Array, default: null},
    hideFields: {type: Array, default: () => []},
    actions: {type: Array, default: () => ['edit', 'change-password', 'delete']},
    itemsPerPage: {type: Number, default: -1},
  },
  data() {
    return {
      isLoading: false,
      edit: {dialog: false, school: null},
      remove: {dialog: false, school: null},
      assign: {dialog: false, school: null},
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.$store.dispatch('school/fetchSchools');
    } catch (err) {
      await this.$store.dispatch('showError', err);
    }
    this.isLoading = false;
  },
  computed: {
    items(): School[] {
      return this.schools as School[] || School.query().withAll().all();
    },
    additionalActions(): any[] {
      return this.actions.filter((action: any) => action && action.icon && action.tooltip && action.emit);
    },
    headers(): any[] {
      const fields = [
        {text: this.$t('label.name'), value: 'name'},
        {text: this.$t('label.state'), value: 'state', class: 'md-and-up'},
        {text: this.$tc('p.student', 2), value: 'students', class: 'sm-and-up'},
        {text: this.$t('label.classes'), value: 'classes', class: 'sm-and-up'},
        {text: this.$t('label.logo'), value: 'logo', sortable: false, align: 'center', class: 'md-and-up'},
        {text: this.$t('label.updatedAt'), value: 'updatedAt', align: 'end', class: 'md-and-up'},
        {text: this.$t('label.createdAt'), value: 'createdAt', align: 'end', class: 'lg-and-up'},
        {text: this.$t('label.actions'), value: 'actions', align: 'center', sortable: false, width: '112px'},
      ];
      return fields.filter(field => !this.hideFields.includes(field.value));
    },
  },
  methods: {
    showEditDialog(school) {
      this.edit.dialog = true;
      this.edit.school = school;
    },
    showAssignDialog(school) {
      this.assign.dialog = true;
      this.assign.school = school;
    },
    showDeleteDialog(school) {
      this.remove.dialog = true;
      this.remove.school = school;
    },
    showField(field: string) {
      return !this.hideFields.includes(field);
    },
    searchFilter(title: string, search: string, school: School) {
      const searchTerms = search.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && school.name.toLowerCase().includes(term), true);
    },
    async deleteSchool() {
      try {
        await this.$store.dispatch('school/deleteSchool', this.remove.school);
        await this.$store.dispatch('showSuccess', this.$root.$t('msg.deleteSuccess', {item: this.$root.$tc('p.school')}));
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
      this.remove.school = null;
    },
  },
});
