import {Model} from '@vuex-orm/core';
import {SubArea, Test} from '~/models';

export class TestSubArea extends Model {
  static entity = 'test_sub-areas';

  static primaryKey = ['testId', 'subAreaId'];

  static fields() {
    return {
      limit: this.attr(null),
      subAreaId: this.attr(null),
      testId: this.attr(null),
      subArea: this.belongsTo(SubArea, 'subAreaId'),
      test: this.belongsTo(Test, 'testId'),
    };
  }

  testId: string;
  test: Test;
  subAreaId: string;
  subArea: SubArea;
  limit: number;
}
