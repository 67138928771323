














import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import Vue from 'vue';
import {AppState} from '~/store/app.module';
import AppSidebar from './AppSidebar.vue';
import AppToolbar from './AppToolbar.vue';

export default Vue.extend({
  name: 'AppLayout',
  components: {AppSidebar, AppToolbar},
  mounted() {
    this.$store.dispatch('screen/updateScreenSize');
    this.registerToScreenSizeChanges();
  },
  computed: {
    app(): AppState {
      return this.$store.state.app;
    },
  },
  methods: {
    registerToScreenSizeChanges() {
      fromEvent(window, 'resize')
          .pipe(debounceTime(100))
          .subscribe(() => this.$store.dispatch('screen/updateScreenSize'));
    },
  },
});
