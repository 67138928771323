import {RuleGenerator} from '~/util/RuleGenerator';

export const schoolConstraints = {
  name: {isRequired: true, maxLength: 100},
  login: {isRequired: false, maxLength: 100},
  passRate: {isRequired: false, isNumeric: true, minValue: 0, maxValue: 100},
};

export const schoolClassConstraints = {
  name: {isRequired: true, maxLength: 15},
};

export function schoolRules(this: any) {
  return RuleGenerator.generateRules.call(this, schoolConstraints);
}

export function schoolClassRules(this: any) {
  return RuleGenerator.generateRules.call(this, schoolClassConstraints);
}
