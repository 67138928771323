


























import {api} from '~/util';
import Vue from 'vue';

const isVersionRegex = /^[0-9]+.[0-9]+.[0-9]+(.[0-9]+)?$/;

export default Vue.extend({
  props: {
    value: {type: Boolean, default: true},
  },
  data() {
    return {
      isLoading: false,
      form: {
        isValid: false,
        model: {
          windows: '',
          mac: '',
          android: '',
          ios: '',
        },
        rules: [
          v => !!v || this.$t('form.isRequired', {field: ''}),
          v => !v || isVersionRegex.test(v) || this.$t('error.isInvalid', {field: this.$tc('p.version')}),
        ],
      },
    };
  },
  mounted() {
  },
  watch: {
    value(value) {
      if (value) {
        this.fetchAppVersions();
      }
    },
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    formRef(): HTMLFormElement {
      return this.$refs.form as HTMLFormElement;
    },
  },
  methods: {
    async fetchAppVersions() {
      try {
        this.isLoading = true;
        const response = await api.get('/api/app-version');
        this.form.model = response.data;
        this.isLoading = false;
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    async submit() {
      try {
        if (this.formRef.validate()) {
          await api.post('/api/app-version', this.form.model);
          await this.$store.dispatch('showSuccess', this.$t('msg.updateSuccess', {item: 'app-version'}));
          this.showDialog = false;
        }
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
