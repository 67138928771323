import {Model} from '@vuex-orm/core';
import {Question} from '.';

export class Answer extends Model {
  static entity = 'answers';

  static fields() {
    return {
      id: this.attr(null),
      questionId: this.attr(null),
      text: this.attr(''),
      isCorrect: this.attr(false),
      question: this.belongsTo(Question, 'questionId'),
    };
  }

  id: number;
  questionId: number;
  text: string;
  isCorrect: boolean;
  question: Question;
}
