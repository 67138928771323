var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"responsiveContainer"},[_c('h3',{staticClass:"mt-3 my-1"},[_vm._v(_vm._s(_vm.$tc('p.document', 2))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("( "+_vm._s(_vm.combinedDocumentCount)+" )")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('hint.documentOverview')))])])],1),_c('v-tabs',{on:{"change":function($event){_vm.updateKey++}}},[_c('v-tab',{key:"1"},[_vm._v(_vm._s(_vm.$tc('p.student'))+" ( "+_vm._s(_vm.studentDocumentCount)+" )")]),_c('v-tab',{key:"2"},[_vm._v(_vm._s(_vm.$tc('p.course'))+" ( "+_vm._s(_vm.courseDocumentCount)+" )")]),_c('v-tab',{key:"3"},[_vm._v(_vm._s(_vm.$tc('p.schoolClass'))+" ( "+_vm._s(_vm.classDocumentCount)+" )")]),_c('v-tab-item',{key:"1"},[_c('document-table',{key:_vm.updateKey,attrs:{"documents":_vm.student.documents,"student":_vm.student,"dense":_vm.dense,"hide-fields":_vm.hideFields,"actions-width":"55px"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip-button',{attrs:{"small":"","color":_vm.isBlacklisted(item) ? 'red' : 'green',"icon":_vm.isBlacklisted(item) ? 'mdi-lock' : 'mdi-lock-open-variant-outline',"tooltip":_vm.isBlacklisted(item) ? _vm.$t('action.removeDocFromBlacklist') : _vm.$t('action.addDocToBlacklist')},on:{"click":function($event){_vm.isBlacklisted(item) ? _vm.removeFromBlacklist(item) : _vm.addToBlacklist(item)}}})]}}])})],1),_c('v-tab-item',{key:"2"},[_c('document-table',{key:_vm.updateKey,attrs:{"documents":_vm.courseDocuments,"student":_vm.student,"dense":_vm.dense,"hide-fields":_vm.hideFields,"actions-width":"55px"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip-button',{attrs:{"small":"","color":_vm.isBlacklisted(item) ? 'red' : 'green',"icon":_vm.isBlacklisted(item) ? 'mdi-lock' : 'mdi-lock-open-variant-outline',"tooltip":_vm.isBlacklisted(item) ? _vm.$t('action.removeDocFromBlacklist') : _vm.$t('action.addDocToBlacklist')},on:{"click":function($event){_vm.isBlacklisted(item) ? _vm.removeFromBlacklist(item) : _vm.addToBlacklist(item)}}})]}}])})],1),_c('v-tab-item',{key:"3"},[_c('document-table',{key:_vm.updateKey,attrs:{"documents":_vm.classDocuments,"student":_vm.student,"dense":_vm.dense,"hide-fields":_vm.hideFields,"actions-width":"55px"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip-button',{attrs:{"small":"","color":_vm.isBlacklisted(item) ? 'red' : 'green',"icon":_vm.isBlacklisted(item) ? 'mdi-lock' : 'mdi-lock-open-variant-outline',"tooltip":_vm.isBlacklisted(item) ? _vm.$t('action.removeDocFromBlacklist') : _vm.$t('action.addDocToBlacklist')},on:{"click":function($event){_vm.isBlacklisted(item) ? _vm.removeFromBlacklist(item) : _vm.addToBlacklist(item)}}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }