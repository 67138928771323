













































































































































































import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {Area, Database, Licence, SubArea, Subject} from '~/models';

export default Vue.extend({
  name: 'question-browser',
  components: {TooltipButton},
  props: {
    databaseId: {type: Number},
    licenceId: {type: Number},
    subjectId: {type: Number},
    areaId: {type: Number},
    subAreaId: {type: Number},
  },
  data() {
    return {
      selectedQuestions: [],
      selection: {
        licences: {},
        subjects: {},
        areas: {},
        subAreas: {},
      },
      database: null,
      waitForResponse: false,
      isLoading: false,
      searchExecuted: false,
      searchTerm: '',
      isCustom: false,
      licence: null,
      subject: null,
      area: null,
      subArea: null,
      questions: [],
    };
  },
  async created() {
    try {
      this.$store.dispatch('question/fetchDatabases');

      if (this.subAreaId) {
        await this.loadSubArea(this.subAreaId, true);
        await this.setArea(this.subArea.area.id);
        await this.setSubject(this.subArea.area.subject.id);
        await this.setLicence(this.subArea.area.subject.licence.id);
        await this.setDatabase(this.subArea.area.subject.licence.databaseId);
      } else if (this.areaId) {
        await this.loadArea(this.areaId, true);
        await this.setSubject(this.area.subject.id);
        await this.setLicence(this.area.subject.licence.id);
        await this.setDatabase(this.area.subject.licence.databaseId);
      } else if (this.subjectId) {
        await this.loadSubject(this.subjectId, true);
        await this.setSubject(this.subjectId);
        await this.setLicence(this.subject.licenceId);
        await this.setDatabase(this.subject.licence.databaseId);
      } else if (this.licenceId) {
        await this.loadLicence(this.licenceId, true);
        await this.setDatabase(this.licence.databaseId);
      } else if (this.databaseId) {
        await this.loadDatabase(this.databaseId);
      }
    } catch (err) {
      console.log(err);
    }
  },
  watch: {
    async subArea(newValue: SubArea) {
      if (newValue) {
        this.searchTerm = '';
      }
    },
    databaseId() {
      this.checkReset();
    },
    licenceId() {
      this.checkReset();
    },
    subjectId() {
      this.checkReset();
    },
    areaId() {
      this.checkReset();
    },
    subAreaId() {
      this.checkReset();
    },
  },
  computed: {
    licenceCategories(): any[] {
      return [
        {title: this.$t('language.german'), items: this.licencesDe},
        {title: this.$t('language.english'), items: this.licencesEn},
      ];
    },
    breadcrumbs(): any[] {
      const breadcrumbs = [];
      if (this.database) {
        breadcrumbs.push({text: this.database.name});
      }
      if (this.licence) {
        breadcrumbs.push({text: `${this.licence.name}`});
      }
      if (this.subject) {
        breadcrumbs.push({text: `<b>${this.subject.number}</b>&nbsp;- ${this.subject.name}`});
      }
      if (this.area) {
        breadcrumbs.push({text: `<b>${this.area.number}</b>&nbsp;- ${this.area.name}`});
      }
      if (this.subArea) {
        breadcrumbs.push({text: `<b>${this.subArea.number}</b>&nbsp;- ${this.subArea.name}`});
      }
      return breadcrumbs;
    },
    selectedLicences(): number {
      return Object.values(this.selection.licences).reduce((prev: number, isSelected) => isSelected ? prev + 1 : prev, 0);
    },
    selectedSubjects(): number {
      return Object.values(this.selection.subjects).reduce((prev: number, isSelected) => isSelected ? prev + 1 : prev, 0);
    },
    selectedAreas(): number {
      return Object.values(this.selection.areas).reduce((prev: number, isSelected) => isSelected ? prev + 1 : prev, 0);
    },
    selectedSubAreas(): number {
      return Object.values(this.selection.subAreas).reduce((prev: number, isSelected) => isSelected ? prev + 1 : prev, 0);
    },
    ddDatabase: {
      set(database: Database): void {
        if (database) {
          this.loadDatabase(database.id);
        }
      },
      get(): Database {
        return this.database;
      },
    },
    ddLicence: {
      set(licence: Licence): void {
        if (licence) {
          this.loadLicence(licence.id);
        }
      },
      get(): Licence {
        return this.licence;
      },
    },
    ddSubject: {
      set(subject: Subject): void {
        if (subject) {
          this.loadSubject(subject.id);
        }
      },
      get(): Subject {
        return this.subject;
      },
    },
    ddArea: {
      set(area: Area): void {
        if (area) {
          this.loadArea(area.id);
        }
      },
      get(): Area {
        return this.area;
      },
    },
    ddSubArea: {
      set(subArea: SubArea): void {
        if (subArea) {
          this.loadSubArea(subArea.id);
        }
      },
      get(): SubArea {
        return this.subArea;
      },
    },
    databases(): Database[] {
      return Database.query().all().sort((a, b) => a.name < b.name ? -1 : 1);
    },
    licences(): Licence[] {
      return Licence.query().where('databaseId', this.database?.id).all().sort((a, b) => a.fullName < b.fullName ? -1 : 1);
    },
    licencesDe(): Licence[] {
      return this.licences.filter(licence => licence.language === 'de');
    },
    licencesEn(): Licence[] {
      return this.licences.filter(licence => licence.language === 'en');
    },
    subjects(): Subject[] {
      return Subject.query().where('licenceId', this.licence?.id).all().sort((a, b) => a.fullName < b.fullName ? -1 : 1);
    },
    areas(): Area[] {
      return Area.query().where('subjectId', this.subject?.id).all().sort((a, b) => a.fullName < b.fullName ? -1 : 1);
    },
    subAreas(): SubArea[] {
      return SubArea.query().where('areaId', this.area?.id).all().sort((a, b) => a.fullName < b.fullName ? -1 : 1);
    },
    questionHeaders(): any[] {
      return [
        {text: this.$t('label.number'), value: 'number', width: '98px'},
        {text: this.$t('label.text'), value: 'text'},
      ];
    },
  },
  methods: {
    checkReset() {
      if (!this.databaseId && !this.licenceId && !this.subjectId && !this.areaId && !this.subAreaId) {
        this.database = this.licence = this.subject = this.area = this.subArea = null;
        this.removeSearchResults();
      }
    },
    async loadDatabase(id): Promise<void> {
      this.isLoading = true;
      this.removeSearchResults();
      this.licence = this.subject = this.area = this.subArea = null;
      await this.$store.dispatch('question/fetchDatabase', id);
      await this.setDatabase(id);
      this.isLoading = false;
    },
    async setDatabase(id) {
      this.database = await Database.query().whereId(id).with('licences').first();
    },
    async loadLicence(id, deep = false) {
      this.isLoading = true;
      this.removeSearchResults();
      this.subject = this.area = this.subArea = null;
      await this.$store.dispatch('question/fetchLicence', {licence: id, deep});
      await this.setLicence(id);
      this.isLoading = false;
    },
    async setLicence(id) {
      this.licence = await Licence.query().whereId(id).with('subjects').first();
    },
    async loadSubject(id, deep = false) {
      this.isLoading = true;
      this.removeSearchResults();
      this.area = this.subArea = null;
      await this.$store.dispatch('question/fetchSubject', {subject: id, deep});
      await this.setSubject(id);
      this.isLoading = false;
    },
    async setSubject(id) {
      this.subject = await Subject.query().whereId(id).with(['areas', 'licence']).first();
    },
    async loadArea(id, deep = false) {
      this.isLoading = true;
      this.removeSearchResults();
      this.subArea = null;
      await this.$store.dispatch('question/fetchArea', {area: id, deep});
      await this.setArea(id);
      this.isLoading = false;
    },
    async setArea(id) {
      this.area = await Area.query().whereId(id).with(['subAreas', 'subject.licence']).first();
    },
    async loadSubArea(id, deep = false) {
      this.isLoading = true;
      await this.$store.dispatch('question/fetchSubArea', {subArea: id, deep});
      await this.setSubArea(id);
      this.questions = await this.$store.dispatch('question/fetchQuestions', {subArea: this.subArea.id});
      this.isLoading = false;
    },
    async setSubArea(id) {
      this.subArea = await SubArea.query().whereId(id).with('area.subject.licence').first();
    },
    goBack() {
      this.removeSearchResults();
      if (this.subArea) {
        this.subArea = null;
        return;
      }
      if (this.area) {
        this.area = null;
        return;
      }
      if (this.subject) {
        this.subject = null;
        return;
      }
      if (this.licence) {
        this.licence = null;
        return;
      }
      if (this.database) {
        this.database = null;
      }
    },
    async search() {
      const condition: any = {text: this.searchTerm.trim()};

      if (this.isCustom) {
        condition.isCustom = true;
      }

      if (this.subArea) {
        condition.subArea = this.subArea.id;
      } else if (this.area) {
        condition.area = this.area.id;
      } else if (this.subject) {
        condition.subject = this.subject.id;
      } else if (this.licence) {
        condition.licence = this.licence.id;
      } else if (this.database) {
        condition.database = this.database.id;
      }

      this.isLoading = true;
      this.questions = await this.$store.dispatch('question/fetchQuestions', condition);
      this.isLoading = false;
      this.searchExecuted = true;
    },
    removeSearchResults() {
      this.questions = [];
      this.searchExecuted = false;
    },
    async selectLicences() {
      const licenceIds = Object.keys(this.selection.licences).filter(id => this.selection.licences[id]).map(id => Number(id));
      this.$emit('select-licences', licenceIds);
      this.selection.licences = {};
    },
    async selectSubjects() {
      const subjectIds = Object.keys(this.selection.subjects).filter(id => this.selection.subjects[id]).map(id => Number(id));
      this.$emit('select-subjects', subjectIds);
      this.selection.subjects = {};
    },
    async selectAreas() {
      const areaIds = Object.keys(this.selection.areas).filter(id => this.selection.areas[id]).map(id => Number(id));
      this.$emit('select-areas', areaIds);
      this.selection.areas = {};
    },
    async selectSubAreas() {
      const subAreaIds = Object.keys(this.selection.subAreas).filter(id => this.selection.subAreas[id]).map(id => Number(id));
      this.$emit('select-sub-areas', subAreaIds);
      this.selection.subAreas = {};
    },
    async selectQuestions() {
      this.$emit('select-questions', this.selectedQuestions);
      this.selectedQuestions = [];
    },
  },
});
