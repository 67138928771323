
























































import Vue from 'vue';
import {DeleteDialog, TableRow, TooltipDate} from '~/components/common';
import {date, datetime, fromNow} from '~/util/filters';

export default Vue.extend({
  name: 'StudentInfoTable',
  components: {TooltipDate, TableRow, DeleteDialog},
  filters: {fromNow, datetime, date},
  props: {
    student: {type: Object, required: true},
    dense: {type: Boolean, default: false},
    fullWidth: {type: Boolean, default: false},
  },
  data() {
    return {
      remove: {dialog: false, device: null},
    };
  },
  methods: {
    async onRemoveDeviceClick(device) {
      this.remove.device = device;
      this.remove.dialog = true;
    },
    async removeDevice() {
      try {
        await this.$store.dispatch('student/removeDevice', this.remove.device);
        await this.$store.dispatch('showSuccess', this.$t('msg.deleteSuccess', {item: this.remove.device.id}));
        this.remove.device = null;
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    getLanguage(lang) {
      return lang === 'en' ? this.$t('language.english') : this.$t('language.german');
    },
  },
});
