import {Model} from '@vuex-orm/core';
import {CourseDocument, CourseStudent, Document, SchoolClass, SchoolClassCourse, Student} from '../index';

export class Course extends Model {
  static entity = 'courses';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      state: this.attr(''),
      documents: this.belongsToMany(Document, CourseDocument, 'courseId', 'documentId'),
      students: this.belongsToMany(Student, CourseStudent, 'courseId', 'studentId'),
      schoolClasses: this.belongsToMany(SchoolClass, SchoolClassCourse, 'courseId', 'schoolClassId'),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
    };
  }

  id: string;
  name: string;
  state: string;
  documents: Document[];
  students: Student[];
  schoolClasses: SchoolClass;
  createdAt: string;
  updatedAt: string;
}
