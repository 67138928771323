import {Module} from 'vuex';

export interface SnackbarStateInterface {
  show: boolean;
  msg: string;
  color: string;
}

export interface SnackInterface {
  msg: string;
  color: string;
}

export const snackbarModule: Module<SnackbarStateInterface, any> = {
  state: {
    show: false,
    msg: '',
    color: 'green',
  },
  mutations: {
    SHOW_SNACK: (state, snack: SnackInterface) => {
      state.show = true;
      state.color = snack.color;
      state.msg = snack.msg;
    },
    HIDE_SNACK: (state) => {
      state.show = false;
    },
  },
  actions: {
    async showError({commit}, msgOrErrors: string | any | any[]) {
      let msg = msgOrErrors;

      if (Array.isArray(msgOrErrors)) {
        const createListItems = (constraints: string[]) => Object.values(constraints).reduce((prev: string, c: string) => `${prev}<li>${c}</li>`, '');
        const createLists = (errors: any[]) => errors.reduce((prev: string, e: any) => `${prev}${e.property}<ul>${createListItems(e.constraints)}</ul>`, '');
        msg = createLists(msgOrErrors);
      } else {
        msg = msgOrErrors?.response?.data?.message || msgOrErrors?.message || msgOrErrors;
      }
      commit('SHOW_SNACK', {msg, color: 'error'});
    },
    async showSuccess({commit}, msg: string) {
      commit('SHOW_SNACK', {msg, color: 'success'});
    },
    async showSnack({commit}, snack: SnackInterface) {
      commit('SHOW_SNACK', snack);
    },
    async hideSnack({commit}) {
      commit('HIDE_SNACK');
    },
  },
};
