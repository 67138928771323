




















































import Vue from 'vue';
import {Document, Student} from '~/models';
import {TooltipButton} from '../common';
import DocumentTable from '../document/DocumentTable.vue';

export default Vue.extend({
  components: {
    TooltipButton, DocumentTable,
  },
  props: {
    studentId: {type: String, required: true},
    dense: {type: Boolean, required: false},
  },
  data() {
    return {
      updateKey: 0,
      hideFields: ['latest-version', 'versions', 'downloads', 'courses'],
    };
  },
  async created() {
    if (this.student.schoolClassId && (!this.student.schoolClass?.documents || this.student.schoolClass?.documents.length === 0)) {
      await this.$store.dispatch('school/fetchSchoolClass', this.student.schoolClassId);
    }
  },
  computed: {
    student(): Student {
      return Student.query()
          .with('courses.documents')
          .with('documents')
          .with('schoolClass.documents')
          .with('schoolClass.courses.documents')
          .with('documentBlacklist')
          .find(this.studentId);
    },
    courseDocuments(): Document[] {
      return this.student.courses.reduce((documents, course) => documents.concat(course.documents), []);
    },
    classDocuments(): Document[] {
      if (!this.student.schoolClass) {
        return [];
      }

      let documents = [];
      if (this.student.schoolClass.documents) {
        documents = documents.concat(this.student.schoolClass.documents);
      }
      this.student.schoolClass.courses.forEach(course => documents = documents.concat(course.documents));
      return documents;
    },
    classDocumentCount(): number {
      return this.getCount(this.classDocuments);
    },
    courseDocumentCount(): number {
      return this.getCount(this.courseDocuments);
    },
    studentDocumentCount(): number {
      return this.getCount(this.student.documents);
    },
    combinedDocumentCount(): string {
      const documents = this.student.documents.concat(this.courseDocuments).concat(this.classDocuments);
      const distinct = documents.filter((val, index, self) => self.findIndex(doc => doc.id === val.id) === index);
      const available = this.filterAvailableDocuments(distinct);

      return available.length + '/' + distinct.length + '/' + documents.length;
    },
  },
  methods: {
    isBlacklisted(document: Document): boolean {
      return !!this.student.documentBlacklist.find(doc => doc.id === document.id);
    },
    async addToBlacklist(document: Document) {
      await this.$store.dispatch('student/addToBlacklist', {documentId: document.id, studentId: this.student.id});
    },
    async removeFromBlacklist(document: Document) {
      await this.$store.dispatch('student/removeFromBlacklist', {documentId: document.id, studentId: this.student.id});
    },
    filterAvailableDocuments(documents) {
      return documents.filter(doc => !this.student.documentBlacklist.find(blackDoc => blackDoc.id === doc.id));
    },
    getCount(documents) {
      const availableDocuments = this.filterAvailableDocuments(documents);
      if (availableDocuments.length === documents.length) {
        return documents.length;
      }
      return availableDocuments.length + '/' + documents.length;
    },
  },
});
