import {Model} from '@vuex-orm/core';
import {Student, Subject, Test, TestGroup} from '~/models';

export class TestGroupStudent extends Model {
  static entity = 'test-group_students';

  static primaryKey = ['testGroupId', 'studentId'];

  static fields() {
    return {
      testGroupId: this.attr(null),
      studentId: this.attr(null),
      testGroup: this.belongsTo(Test, 'testGroupId'),
      student: this.belongsTo(Subject, 'studentId'),
    };
  }

  testGroupId: number;
  testGroup: TestGroup;
  studentId: number;
  student: Student;
}
