






























import Vue from 'vue';
import {DataTable, DeleteDialog, TooltipButton, TooltipDate} from '~/components/common';
import {SchoolClassDialog} from '~/components/school';
import {SchoolClass} from '~/models';

export default Vue.extend({
  name: 'school-class-table',
  components: {TooltipDate, TooltipButton, DeleteDialog, SchoolClassDialog, DataTable},
  props: {
    search: {type: String, default: ''},
    classes: {type: Array, default: null},
    hideFields: {type: Array, default: () => []},
    actions: {type: Array, default: () => ['edit', 'delete']},
    itemsPerPage: {type: Number, default: 10},
  },
  data() {
    return {
      edit: {dialog: false, schoolClass: null},
      remove: {dialog: false, schoolClass: null},
    };
  },
  computed: {
    items(): SchoolClass[] {
      return this.classes as SchoolClass[] || [];
    },
    additionalActions(): any[] {
      return this.actions.filter((action: any) => action && action.icon && action.tooltip && action.emit);
    },
    headers(): any[] {
      const fields = [
        {text: this.$t('label.name'), value: 'name'},
        {text: this.$tc('p.student', 2), value: 'students', align: 'end'},
        {text: this.$tc('p.document', 2), value: 'documents', align: 'end'},
        {text: this.$tc('p.course', 2), value: 'courses', align: 'end'},
        {text: this.$t('label.updatedAt'), value: 'updatedAt', align: 'end', class: 'md-and-up'},
        {text: this.$t('label.createdAt'), value: 'createdAt', align: 'end', class: 'lg-and-up'},
        {text: this.$t('label.actions'), value: 'actions', align: 'center', sortable: false, width: '112px'},
      ];
      return fields.filter(field => !this.hideFields.includes(field.value));
    },
  },
  methods: {
    showEditDialog(schoolClass) {
      this.edit.dialog = true;
      this.edit.schoolClass = schoolClass;
    },
    showDeleteDialog(schoolClass) {
      this.remove.dialog = true;
      this.remove.schoolClass = schoolClass;
    },
    hasAction(action: string) {
      return this.actions.includes(action);
    },
    showField(field: string) {
      return !this.hideFields.includes(field);
    },
    async deleteSchoolClass() {
      try {
        await this.$store.dispatch('school/deleteSchoolClass', this.remove.schoolClass);
        await this.$store.dispatch('showSuccess', this.$root.$t('msg.deleteSuccess', {item: this.$root.$tc('p.schoolClass')}));
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
      this.remove.schoolClass = null;
    },
  },
});
