import {Module} from 'vuex';
import {School, SchoolClass, SchoolClassCourse, SchoolClassDocument, SchoolTest, SchoolTestGroup, Student} from '~/models';
import {api} from '~/util/api';

const UPDATE_THRESHOLD = 60000; // 60 sek

export interface SchoolState {
  search: string,
  fetchedAt: number,
  optionsFetchedAt: number,
  schoolFetchedAt: { [id: number]: number },
}

export const schoolModule: Module<SchoolState, any> = {
  namespaced: true,
  state: {
    search: '',
    fetchedAt: 0,
    optionsFetchedAt: 0,
    schoolFetchedAt: {},
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
  },
  actions: {
    updateSearch: async (context, search) => {
      context.commit('SET_SEARCH', search);
    },
    fetchSchools: async (context) => {
      if (Date.now() - context.state.fetchedAt > UPDATE_THRESHOLD) {
        context.state.fetchedAt = Date.now();
        const response = await api.get('/api/school');
        await School.insert({data: response.data});
      }
    },
    fetchOptions: async (context) => {
      if (Date.now() - context.state.optionsFetchedAt > UPDATE_THRESHOLD) {
        context.state.optionsFetchedAt = Date.now();
        const response = await api.get('/api/school/options');
        await School.insertOrUpdate({data: response.data});
      }
    },
    fetchSchool: async (context, id) => {
      if (!context.state.schoolFetchedAt[id] || Date.now() - context.state.schoolFetchedAt[id] > UPDATE_THRESHOLD) {
        context.state.schoolFetchedAt[id] = Date.now();
        const response = await api.get(`/api/school/${id}`);
        await School.insert({data: response.data});
      }
    },
    fetchSchoolStudents: async (context, id) => {
      const response = await api.get(`/api/school/${id}/students`);
      await Student.insert({data: response.data});
    },
    fetchSchoolClass: async (context, schoolClassId) => {
      const response = await api.get(`/api/school/class/${schoolClassId}`);
      await SchoolClass.insert({data: response.data});
    },
    createSchool: async (context, payload) => {
      const formData = new FormData();
      const blob = new Blob([JSON.stringify(payload.form)], {type: 'application/json'});

      formData.append('json', blob);
      if (payload.img) {
        formData.append('img', payload.img);
      }

      const response = await api.post(`/api/school`, formData);
      await School.insert({data: response.data});
    },
    updateSchool: async (context, payload) => {
      const formData = new FormData();
      const blob = new Blob([JSON.stringify(payload.form)], {type: 'application/json'});

      formData.append('json', blob);
      if (payload.img) {
        formData.append('img', payload.img);
      }

      const response = await api.put(`/api/school/${payload.id}`, formData);

      await Student.update({
        where: (student) => student.schoolId === payload.id,
        data: {schoolId: null},
      });
      await SchoolClass.update({
        where: (schoolClass) => schoolClass.schoolId === payload.id,
        data: {schoolId: null},
      });

      await School.insert({data: response.data});
    },
    updateSchoolClass: async (context, payload) => {
      const response = await api.put(`/api/school/class/${payload.id}`, payload.data);
      await Student.update({
        where: (student) => student.schoolClassId === payload.id,
        data: {schoolClassId: null},
      });
      await SchoolClassCourse.delete(scd => scd.schoolClassId === payload.id);
      await SchoolClassDocument.delete(scd => scd.schoolClassId === payload.id);
      await SchoolClass.insertOrUpdate({data: response.data});
    },
    deleteSchool: async (context, payload) => {
      await api.delete(`/api/school/${payload.id}`);
      await School.delete(payload.id);
    },
    deleteSchoolClass: async (context, payload) => {
      await api.delete(`/api/school/class/${payload.id}`);
      await SchoolClass.delete(payload.id);
    },
    assignTests: async (context, payload) => {
      await api.put(`/api/school/${payload.schoolId}/assign-tests`, payload.body);
      await SchoolTest.delete(ts => ts.schoolId === payload.schoolId);
      const testRelations = payload.body.tests.map(testId => ({schoolId: payload.schoolId, testId: testId}));
      await SchoolTest.insert({data: testRelations});

      await SchoolTestGroup.delete(ts => ts.schoolId === payload.schoolId);
      const testGroupRelations = payload.body.testGroups.map(testGroupId => ({schoolId: payload.schoolId, testGroupId: testGroupId}));
      await SchoolTestGroup.insert({data: testGroupRelations});
    },
  },
  getters: {},
};
