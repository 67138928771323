import {Model} from '@vuex-orm/core';
import {AdminPermission, AdminRole, Permission, Role} from '~/models';

export class Admin extends Model {
  static entity = 'admins';

  static fields() {
    return {
      id: this.attr(null),
      email: this.attr(''),
      firstName: this.attr(''),
      lastName: this.attr(''),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
      lastLoginAt: this.attr(''),
      roles: this.belongsToMany(Role, AdminRole, 'adminId', 'roleId'),
      permissions: this.belongsToMany(Permission, AdminPermission, 'adminId', 'permissionId'),
    };
  }

  get name(): string {
    return this.firstName + ' ' + this.lastName;
  }

  id: string;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  updatedAt: string;
  lastLoginAt: string;
  roles: Role[];
  permissions: Permission[];
}
