

























































import Vue from 'vue';
import {DeleteDialog, StateChip, TooltipButton} from '~/components/common';
import AssignStudentsDialog from '~/components/test/AssignStudentsDialog.vue';
import TestGroupDialog from '~/components/test/TestGroupDialog.vue';
import {TestGroup} from '~/models';

export default Vue.extend({
  components: {TooltipButton, DeleteDialog, StateChip, TestGroupDialog, AssignStudentsDialog},
  data() {
    return {
      editDialog: false,
      deleteDialog: false,
      assignDialog: false,
    };
  },
  async created() {
    await this.$store.dispatch('testGroup/fetchById', this.testGroupId);
    document.title = `LTMS - TestGroup: ${this.testGroup.title}`;
  },
  watch: {
    async '$route.params.id'() {
      await this.$store.dispatch('testGroup/fetchById', this.testGroupId);
    },
  },
  computed: {
    testGroupId() {
      return Number(this.$route.params.id);
    },
    isArchived(): boolean {
      return this.testGroup.state === 'archived';
    },
    breadcrumbs(): any[] {
      return [
        {text: this.$t('nav.testGroups'), to: {name: 'test-groups'}, exact: true},
        {text: this.testGroup?.title || this.$route.params.id, disabled: true},
      ];
    },
    testGroup(): TestGroup {
      return TestGroup.query().whereId(this.testGroupId).withAll().first();
    },
    studentHeaders(): any[] {
      return [
        {text: this.$tc('label.name'), value: 'name'},
      ];
    },
    testHeaders(): any[] {
      return [
        {text: this.$tc('label.title'), value: 'title'},
      ];
    },
  },
  methods: {
    async onAssignStudents(students: any[]) {
      const payload = {testGroupId: this.testGroup.id, students};
      await this.$store.dispatch('testGroup/assignStudents', payload);
    },
    async archiveTestGroup() {
      await this.$store.dispatch('testGroup/archive', this.testGroup);
      await this.$store.dispatch('showSuccess', this.$root.$t('msg.archiveSuccess', {item: this.$root.$tc('p.testGroup')}));
    },
    async activateTestGroup() {
      await this.$store.dispatch('testGroup/activate', this.testGroup);
      await this.$store.dispatch('showSuccess', this.$root.$t('msg.activateSuccess', {item: this.$root.$tc('p.testGroup')}));
    },
    async deleteTestGroup() {
      await this.$store.dispatch('testGroup/delete', this.testGroup);
      await this.$store.dispatch('showSuccess', this.$root.$t('msg.deleteSuccess', {item: this.$root.$tc('p.testGroup')}));
      await this.$router.push({name: 'tests-groups'});
    },
  },
});
