import {Model} from '@vuex-orm/core';

export class DocumentVersion extends Model {
  static entity = 'document-versions';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      author: this.attr(''),
      filename: this.attr(''),
      isReleaseVersion: this.attr(false),
      downloads: this.attr(''),
      url: this.attr(''),
      cover: this.attr(''),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
      hasLogo: this.attr(false),
      isDuplex: this.attr(false),
      // foreignKeys
      documentId: this.attr(null),
    };
  }

  id: string;
  name: string;
  author: string;
  filename: string;
  isReleaseVersion: boolean;
  downloads: number;
  url: string;
  cover: string;
  createdAt: string;
  updatedAt: string;
  hasLogo: boolean;
  isDuplex: boolean;
}
