import {Model} from '@vuex-orm/core';

export class CourseStudent extends Model {
  static entity = 'course_students';

  static primaryKey = ['courseId', 'studentId'];

  static fields() {
    return {
      courseId: this.attr(null),
      studentId: this.attr(null),
    };
  }

  courseId: string;
  studentId: string;
}
