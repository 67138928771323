




















import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {type: Boolean, default: false},
    cta: {type: String, default: ''},
    name: {type: String, default: ''},
    headline: {type: String, default: ''},
  },
  computed: {
    model: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      this.close();
    },
    close() {
      this.$emit('input', false);
    },
  },
});
