



















































import Vue from 'vue';
import {DeleteDialog, PaginatedDataTable, TooltipButton, TooltipDate} from '~/components/common';
import {ChangePasswordDialog, StudentEditDialog, StudentInfo} from '~/components/student';
import {Student} from '~/models';

export default Vue.extend({
  name: 'StudentPaginationTable',
  components: {
    TooltipDate, TooltipButton, DeleteDialog, ChangePasswordDialog, StudentEditDialog, PaginatedDataTable, StudentInfo,
    CourseTable: () => import('@/components/course/CourseTable.vue'),
  },
  props: {
    search: {type: String, default: ''},
    dense: {type: Boolean, default: false},
    students: {type: Array, default: null},
    actionsWidth: {type: String, default: null},
    hideFields: {type: Array, default: () => []},
    actions: {type: Array, default: () => ['edit', 'change-password', 'delete', 'expand']},
    itemsPerPage: {type: Number, default: 10},
  },
  data() {
    return {
      action: 'student/fetchStudentsPaginated',
      isLoading: false,
      edit: {dialog: false, student: null},
      remove: {dialog: false, student: null},
      changePassword: {dialog: false, student: null},
      serverItemLength: -1,
    };
  },
  computed: {
    studentModel() {
      return Student;
    },
    additionalActions(): any[] {
      return this.actions.filter((action: any) => action && action.icon && action.tooltip && action.emit);
    },
    headers(): any[] {
      const fields = [
        {text: this.$t('label.login'), value: 'login'},
        {text: this.$t('label.forename'), value: 'forename', class: 'md-and-up'},
        {text: this.$t('label.surname'), value: 'surname', class: 'sm-and-up'},
        {text: this.$t('label.email'), value: 'email', class: 'lg-and-up'},
        {text: this.$tc('p.device', 2), value: 'devices', align: 'end', class: 'sm-and-up'},
        {text: this.$tc('p.course', 2), value: 'courses', align: 'end', class: 'lg-and-up'},
        {text: this.$t('label.updatedAt'), value: 'updatedAt', align: 'end', class: 'lg-and-up'},
        {text: this.$t('label.createdAt'), value: 'createdAt', align: 'end', class: 'xl-and-up'},
        {text: this.$t('label.actions'), value: 'actions', align: 'center', sortable: false, width: this.actionsWidth || this.actions.length * 52 + 'px'},
      ];
      return fields.filter(field => !this.hideFields.includes(field.value));
    },
  },
  methods: {
    searchFilter(students, search, student) {
      const searchTerms = search.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && (student.forename.toLowerCase().includes(term)
          || student.surname.toLowerCase().includes(term)
          || student.email.toLowerCase().includes(term)
          || student.login.toLowerCase().includes(term)), true);
    },
    showEditDialog(student) {
      this.edit.dialog = true;
      this.edit.student = student;
    },
    showDeleteDialog(student) {
      this.remove.dialog = true;
      this.remove.student = student;
    },
    showChangePasswordDialog(student) {
      this.changePassword.dialog = true;
      this.changePassword.student = student;
    },
    showField(field: string) {
      return !this.hideFields.includes(field);
    },
    async deleteUser() {
      try {
        await this.$store.dispatch('student/deleteStudent', this.remove.student.id);
        this.remove.student = null;
        this.remove.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
