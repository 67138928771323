import {Model} from '@vuex-orm/core';

export class AdminRole extends Model {
  static entity = 'admin_roles';

  static primaryKey = ['adminId', 'roleId'];

  static fields() {
    return {
      adminId: this.attr(null),
      roleId: this.attr(null),
    };
  }

  adminId: number;
  roleId: number;
}
