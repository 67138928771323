import {breakpoint} from './constants';


export function findIn(needle, haystack) {
  return haystack.toLowerCase().indexOf(needle.toLowerCase()) >= 0;
}

export function sortBy(prop: string = 'name') {
  return (a, b) => a[prop]?.toLowerCase() < b[prop]?.toLowerCase() ? -1 : 1;
}

export function getResponsiveClass(width) {
  if (width > breakpoint.xl) {
    return 'is-xl';
  }
  if (width > breakpoint.lg) {
    return 'is-lg';
  }
  if (width > breakpoint.md) {
    return 'is-md';
  }
  if (width > breakpoint.sm) {
    return 'is-sm';
  }

  return 'is-xs';
}
