export class Randomizer {
  public static getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.round(Math.random() * (max - min)) + min;
  }

  public static generateRandomStringOfLength(length: number) {
    const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_-+:?!';
    let password = '';

    for (let i = 0; i < length; i++) {
      const index = Randomizer.getRandomInt(0, alphabet.length - 1);
      const character = alphabet[index];
      password += character;
    }
    return password;
  }
}
