










































import Vue from 'vue';
import {ChunkedFileUpload} from '~/components/common';
import {versionConstraints, versionRules} from './document.rules';

export default Vue.extend({
  name: 'version-upload-dialog',
  components: {ChunkedFileUpload},
  props: {
    value: {type: Boolean, default: false},
    document: {type: Object, default: null},
  },
  data() {
    return {
      isValid: true,
      formDefaults: null,
      form: {
        versionName: '',
        versionAuthor: '',
        hasLogo: false,
        isDuplex: false,
      },
      updateKey: 0,
      file: null,
      rules: versionRules.call(this),
      constraint: versionConstraints,
    };
  },
  created() {
    this.setFormDefaults();
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    documentTitle(): string {
      return this.document?.title;
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      const payload = {
        id: this.document.id,
        data: {
          ...this.form,
          fileIdentifier: this.file.uniqueIdentifier,
          fileName: this.file.name,
        },
      };

      try {
        await this.$store.dispatch('document/uploadVersion', payload);
        await this.$store.dispatch('showSuccess', this.$t('msg.uploadSuccess', {item: this.form.versionName}));
        this.dialog = false;
        this.reset();
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    setFormDefaults() {
      this.formDefaults = Object.assign({}, this.form);
      this.reset();
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      this.file = null;
      this.updateKey++;
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
