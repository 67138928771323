import {Model} from '@vuex-orm/core';

export class SchoolTest extends Model {
  static entity = 'school_test';

  static primaryKey = ['schoolId', 'testId'];

  static fields() {
    return {
      schoolId: this.attr(null),
      testId: this.attr(null),
    };
  }

  schoolId: string;
  testId: string;
}
