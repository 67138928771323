









































import Vue from 'vue';
import {TranslateResult} from 'vue-i18n';
import {Document} from '~/models';
import {courseConstraints, courseRules} from './course.rules';

export default Vue.extend({
  name: 'course-dialog',
  props: {
    value: {type: Boolean, default: false},
    course: {type: Object, default: null},
  },
  data() {
    return {
      isLoading: false,
      isValid: true,
      formDefaults: null,
      form: {
        name: '',
        state: 'active',
        documents: [],
      },
      options: {
        state: [
          {val: 'active', name: this.$t('label.active')},
          {val: 'inactive', name: this.$t('label.inactive')},
        ],
      },
      rules: courseRules.call(this),
      constraint: courseConstraints,
    };
  },
  watch: {
    async value(value) {
      if (value) {
        this.isLoading = true;
        await this.$store.dispatch('document/fetchDocumentsOptions');
        this.setFormDefaults();
        this.isLoading = false;
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    documents(): Document[] {
      return Document.all();
    },
    headline(): TranslateResult {
      return this.course ? this.$t('action.editItem', {item: this.$tc('p.course')}) : this.$t('action.createItem', {item: this.$tc('p.course')});
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      try {
        let msg;
        if (this.course) {
          await this.$store.dispatch('course/updateCourse', {id: this.course.id, data: this.form});
          msg = this.$t('msg.updateSuccess', {item: this.$tc('p.course')});
        } else {
          await this.$store.dispatch('course/createCourse', this.form);
          msg = this.$t('msg.addSuccess', {item: this.$tc('p.course')});
        }
        await this.$store.dispatch('showSuccess', msg);
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', this.getError(err));
      }
    },
    getError(err) {
      if (err.response?.status === 409) {
        return this.$t('error.alreadyInUse', {item: this.$t('label.name'), value: this.form.name});
      }
      return err;
    },
    setFormDefaults() {
      if (this.course) {
        this.formDefaults = {
          name: this.course.name,
          state: this.course.state,
          documents: this.course.documents.map(document => document.id),
        };
      } else {
        this.formDefaults = Object.assign({}, this.form);
      }
      this.reset();
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
