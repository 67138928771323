import {Model} from '@vuex-orm/core';
import {Database, Question} from '~/models';

export class DatabaseQuestion extends Model {
  static entity = 'database_questions';

  static primaryKey = ['databaseId', 'questionId'];

  static fields() {
    return {
      databaseId: this.attr(null),
      database: this.belongsTo(Database, 'databaseId'),
      questionId: this.attr(null),
      question: this.belongsTo(Question, 'questionId'),
    };
  }

  databaseId: number;
  database: Database;
  questionId: number;
  question: Question;
}
