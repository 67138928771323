











import Vue from 'vue';
import TestGroupTable from '~/components/test/TestGroupTable.vue';

export default Vue.extend({
  components: {TestGroupTable},
  created() {
    document.title = 'LTMS - TestGroups';
  },
});
