import {Model} from '@vuex-orm/core';

export class AreaQuestion extends Model {
  static entity = 'area_questions';

  static primaryKey = ['areaId', 'questionId'];

  static fields() {
    return {
      areaId: this.attr(null),
      questionId: this.attr(null),
    };
  }

  areaId: string;
  questionId: string;
}
