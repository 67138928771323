

















import Vue from 'vue';
import TestTable from '~/components/test/TestTable.vue';

export default Vue.extend({
  components: {TestTable},
  created() {
    document.title = 'LTMS - Tests';
  },
});
