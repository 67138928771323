




























































































































































































import Vue from 'vue';
import {ConfirmDialog, DeleteDialog, TooltipButton} from '~/components/common';
import AreaDialog from '~/components/question/AreaDialog.vue';
import ImportQuestionsDialog from '~/components/question/ImportQuestionsDialog.vue';
import LicenceDialog from '~/components/question/LicenceDialog.vue';
import QuestionDialog from '~/components/question/QuestionCreateDialog.vue';
import SubAreaDialog from '~/components/question/SubAreaDialog.vue';
import SubjectDialog from '~/components/question/SubjectDialog.vue';
import {Area, Database, Licence, SubArea, Subject} from '~/models';

export default Vue.extend({
  name: 'question-browser',
  components: {TooltipButton, QuestionDialog, LicenceDialog, DeleteDialog, ImportQuestionsDialog, SubjectDialog, AreaDialog, SubAreaDialog, ConfirmDialog},
  props: {
    databaseId: {type: Number},
    licenceId: {type: Number},
    subjectId: {type: Number},
    areaId: {type: Number},
    subAreaId: {type: Number},
  },
  data() {
    return {
      deleteDialog: {show: false, item: '', action: () => ({})},
      confirmDialog: {show: false, text: '', action: () => ({})},
      importDialog: {show: false, item: '', action: () => ({})},
      licenceDialog: false,
      subjectDialog: false,
      areaDialog: false,
      subAreaDialog: false,
      addQuestionDialog: false,
      database: null,
      waitForResponse: false,
      isLoading: false,
      searchExecuted: false,
      searchTerm: '',
      isCustom: false,
      licence: null,
      subject: null,
      area: null,
      subArea: null,
      questions: [],
    };
  },
  async created() {
    try {
      await this.$store.dispatch('question/fetchDatabases');

      if (this.subAreaId) {
        await this.loadSubArea(this.subAreaId, true);
        await this.setArea(this.subArea.area.id);
        await this.setSubject(this.subArea.area.subject.id);
        await this.setLicence(this.subArea.area.subject.licence.id);
        await this.setDatabase(this.subArea.area.subject.licence.databaseId);
      } else if (this.areaId) {
        await this.loadArea(this.areaId, true);
        await this.setSubject(this.area.subject.id);
        await this.setLicence(this.area.subject.licence.id);
        await this.setDatabase(this.area.subject.licence.databaseId);
      } else if (this.subjectId) {
        await this.loadSubject(this.subjectId, true);
        await this.setSubject(this.subjectId);
        await this.setLicence(this.subject.licenceId);
        await this.setDatabase(this.subject.licence.databaseId);
      } else if (this.licenceId) {
        await this.loadLicence(this.licenceId, true);
        await this.setDatabase(this.licence.databaseId);
      } else if (this.databaseId) {
        await this.loadDatabase(this.databaseId);
      }
    } catch (err) {
      await this.$store.dispatch('showError', err);
    }
  },
  watch: {
    async subArea(newValue: SubArea) {
      if (newValue) {
        this.searchTerm = '';
      }
    },
    databaseId() {
      this.checkReset();
    },
    licenceId() {
      this.checkReset();
    },
    subjectId() {
      this.checkReset();
    },
    areaId() {
      this.checkReset();
    },
    subAreaId() {
      this.checkReset();
    },
  },
  computed: {
    showExport() {
      const roles = this.$store.state.auth.user.roles.map(role => role.name);
      return roles.includes('Administrator') || roles.includes('Super User');
    },
    licenceCategories(): any[] {
      return [
        {title: this.$t('language.german'), items: this.licencesDe},
        {title: this.$t('language.english'), items: this.licencesEn},
      ];
    },
    breadcrumbs(): any[] {
      const breadcrumbs = [];
      if (this.database) {
        breadcrumbs.push({text: this.database.name});
      }
      if (this.licence) {
        breadcrumbs.push({text: `${this.licence.name}`});
      }
      if (this.subject) {
        breadcrumbs.push({text: `<b>${this.subject.number}</b>&nbsp;- ${this.subject.name}`});
      }
      if (this.area) {
        breadcrumbs.push({text: `<b>${this.area.number}</b>&nbsp;- ${this.area.name}`});
      }
      if (this.subArea) {
        breadcrumbs.push({text: `<b>${this.subArea.number}</b>&nbsp;- ${this.subArea.name}`});
      }
      return breadcrumbs;
    },
    databaseShown(): boolean {
      return this.database && !this.licence;
    },
    licenceShown(): boolean {
      return this.licence && !this.subject;
    },
    subjectShown(): boolean {
      return this.subject && !this.area;
    },
    areaShown(): boolean {
      return this.area && !this.subArea;
    },
    subAreaShown(): boolean {
      return this.subArea;
    },
    ddDatabase: {
      set(database: Database): void {
        if (database) {
          this.loadDatabase(database.id);
        }
      },
      get(): Database {
        return this.database;
      },
    },
    ddLicence: {
      set(licence: Licence): void {
        if (licence) {
          this.loadLicence(licence.id);
        }
      },
      get(): Licence {
        return this.licence;
      },
    },
    ddSubject: {
      set(subject: Subject): void {
        if (subject) {
          this.loadSubject(subject.id);
        }
      },
      get(): Subject {
        return this.subject;
      },
    },
    ddArea: {
      set(area: Area): void {
        if (area) {
          this.loadArea(area.id);
        }
      },
      get(): Area {
        return this.area;
      },
    },
    ddSubArea: {
      set(subArea: SubArea): void {
        if (subArea) {
          this.loadSubArea(subArea.id);
        }
      },
      get(): SubArea {
        return this.subArea;
      },
    },
    databases(): Database[] {
      return Database.query().all().sort((a, b) => a.name < b.name ? -1 : 1);
    },
    licences(): Licence[] {
      return Licence.query().where('databaseId', this.database?.id).all().sort((a, b) => a.fullName < b.fullName ? -1 : 1);
    },
    licencesDe(): Licence[] {
      return this.licences.filter(licence => licence.language === 'de');
    },
    licencesEn(): Licence[] {
      return this.licences.filter(licence => licence.language === 'en');
    },
    subjects(): Subject[] {
      return Subject.query().where('licenceId', this.licence?.id).all().sort((a, b) => a.fullName < b.fullName ? -1 : 1);
    },
    areas(): Area[] {
      return Area.query().where('subjectId', this.subject?.id).all().sort((a, b) => a.fullName < b.fullName ? -1 : 1);
    },
    subAreas(): SubArea[] {
      return SubArea.query().where('areaId', this.area?.id).all().sort((a, b) => a.fullName < b.fullName ? -1 : 1);
    },
    questionHeaders(): any[] {
      return [
        {text: this.$t('label.number'), value: 'number', width: '98px'},
        {text: this.$t('label.text'), value: 'text'},
        {text: '', value: 'action', sortable: false},
      ];
    },
    deepestSection(): any {
      if (this.subArea) {
        return {id: this.subArea.id, name: this.subArea.fullName, type: 'subArea'};
      }
      if (this.area) {
        return {id: this.area.id, name: this.area.fullName, type: 'area'};
      }
      if (this.subject) {
        return {id: this.subject.id, name: this.subject.fullName, type: 'subject'};
      }
      if (this.licence) {
        return {id: this.licence.id, name: this.licence.fullName, type: 'licence'};
      }
      if (this.database) {
        return {id: this.database.id, name: this.database.name, type: 'database'};
      }
      return null;
    },
  },
  methods: {
    checkReset() {
      if (this.subAreaId) {
        this.setSubArea(this.subAreaId);
        return;
      }
      if (this.areaId) {
        this.setArea(this.areaId);
        this.subArea = null;
        return;
      }
      if (this.subjectId) {
        this.setSubject(this.subjectId);
        this.area = null;
        this.subArea = null;
        return;
      }
      if (this.licenceId) {
        this.setLicence(this.licenceId);
        this.subject = null;
        this.area = null;
        this.subArea = null;
        return;
      }
      if (this.databaseId) {
        this.setDatabase(this.databaseId);
        this.licence = null;
        this.subject = null;
        this.area = null;
        this.subArea = null;
        return;
      }
      if (!this.databaseId && !this.licenceId && !this.subjectId && !this.areaId && !this.subAreaId) {
        this.database = this.licence = this.subject = this.area = this.subArea = null;
        this.removeSearchResults();
      }
    },
    async loadDatabase(id): Promise<void> {
      this.isLoading = true;
      this.removeSearchResults();
      this.licence = this.subject = this.area = this.subArea = null;
      if (this.$route.query.database != id) {
        await this.$router.push({name: 'questions', query: {database: id}});
      }
      await this.$store.dispatch('question/fetchDatabase', id);
      await this.setDatabase(id);
      this.isLoading = false;
    },
    async setDatabase(id) {
      this.database = await Database.query().whereId(id).with('licences').first();
    },
    async loadLicence(id, deep = false) {
      this.isLoading = true;
      this.removeSearchResults();
      this.subject = this.area = this.subArea = null;
      if (this.$route.query.licence != id) {
        await this.$router.push({name: 'questions', query: {licence: id}});
      }
      await this.$store.dispatch('question/fetchLicence', {licence: id, deep});
      await this.setLicence(id);
      this.isLoading = false;
    },
    async setLicence(id) {
      this.licence = await Licence.query().whereId(id).with('subjects').first();
    },
    async loadSubject(id, deep = false) {
      this.isLoading = true;
      this.removeSearchResults();
      this.area = this.subArea = null;
      if (this.$route.query.subject != id) {
        await this.$router.push({name: 'questions', query: {subject: id}});
      }
      await this.$store.dispatch('question/fetchSubject', {subject: id, deep});
      await this.setSubject(id);
      this.isLoading = false;
    },
    async setSubject(id) {
      this.subject = await Subject.query().whereId(id).with(['areas', 'licence']).first();
    },
    async loadArea(id, deep = false) {
      this.isLoading = true;
      this.removeSearchResults();
      this.subArea = null;
      if (this.$route.query.area != id) {
        await this.$router.push({name: 'questions', query: {area: id}});
      }
      await this.$store.dispatch('question/fetchArea', {area: id, deep});
      await this.setArea(id);
      this.isLoading = false;
    },
    async setArea(id) {
      this.area = await Area.query().whereId(id).with(['subAreas', 'subject.licence']).first();
    },
    async loadSubArea(id, deep = false) {
      this.isLoading = true;
      if (this.$route.query.subArea != id) {
        await this.$router.push({name: 'questions', query: {subArea: id}});
      }
      await this.$store.dispatch('question/fetchSubArea', {subArea: id, deep});
      await this.setSubArea(id);
      this.questions = await this.$store.dispatch('question/fetchQuestions', {subArea: this.subArea.id});
      this.isLoading = false;
    },
    async setSubArea(id) {
      this.subArea = await SubArea.query().whereId(id).with('area.subject.licence').first();
    },
    goBack() {
      this.removeSearchResults();
      if (this.subArea) {
        this.$router.push({name: 'questions', query: {area: this.area.id}});
        this.subArea = null;
        return;
      }
      if (this.area) {
        this.$router.push({name: 'questions', query: {subject: this.subject.id}});
        this.area = null;
        return;
      }
      if (this.subject) {
        this.$router.push({name: 'questions', query: {licence: this.licence.id}});
        this.subject = null;
        return;
      }
      if (this.licence) {
        this.$router.push({name: 'questions', query: {database: this.database.id}});
        this.licence = null;
        return;
      }
      if (this.database) {
        this.$router.push({name: 'questions'});
        this.database = null;
      }
    },
    async search() {
      const condition: any = {text: this.searchTerm.trim()};

      if (this.isCustom) {
        condition.isCustom = true;
      }

      if (this.subArea) {
        condition.subArea = this.subArea.id;
      } else if (this.area) {
        condition.area = this.area.id;
      } else if (this.subject) {
        condition.subject = this.subject.id;
      } else if (this.licence) {
        condition.licence = this.licence.id;
      } else if (this.database) {
        condition.database = this.database.id;
      }

      this.isLoading = true;
      this.questions = await this.$store.dispatch('question/fetchQuestions', condition);
      this.isLoading = false;
      this.searchExecuted = true;
    },
    removeSearchResults() {
      this.questions = [];
      this.searchExecuted = false;
    },
    removeQuestion(question) {
      this.confirmDialog = {
        show: true,
        text: this.$t('msg.confirm.removeQuestion', {question: '#' + question.number, section: this.deepestSection.name}) as string,
        action: async () => {
          await this.$store.dispatch('question/removeFromSection', {question, section: this.deepestSection});
          this.questions.splice(this.questions.findIndex(q => q.id === question.id), 1);
        },
      };
    },
    async deleteItem(type: string, id) {
      switch (type) {
        case 'licence':
          await this.$store.dispatch('question/deleteLicence', id);
          break;
        case 'subject':
          await this.$store.dispatch('question/deleteSubject', id);
          break;
        case 'area':
          await this.$store.dispatch('question/deleteArea', id);
          break;
        case 'subArea':
          await this.$store.dispatch('question/deleteSubArea', id);
          break;
      }
      this.goBack();
    },
    async importQuestions(type: string, targetId, data) {
      await this.$store.dispatch('question/importQuestions', {type, targetId, import: data});
    },
    async exportQuestions() {
      const appendix = this.getAppendix();
      const questionIds = this.questions.map(q => q.id);
      const exportQuestions = await this.$store.dispatch('question/export', questionIds);
      const data = this.generateHtml(exportQuestions);
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(data));
      element.setAttribute('download', `export.${appendix}.html`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    getAppendix(): any {
      if (this.subArea) {
        return this.licence.name + '.' + this.subArea.number;
      }
      if (this.area) {
        return this.licence.name + '.' + this.area.number;
      }
      if (this.subject) {
        return this.licence.name + '.' + this.subject.number;
      }
      if (this.licence) {
        return this.licence.name;
      }
      return 'all';
    },
    generateHTMLAnswers(answers): string {
      return `<ul>
    ${answers.reduce((html, a) => html + `<li class="${a.isCorrect ? 'bg-success' : 'bg-danger'}">${a.isCorrect ? 'x' : ''}${a.text}</li>`, '')}
  </ul>`;
    },
    generateHtmlFigures(figures): string {
      return figures.length > 0 ? `<tr><th>Figures</th><td>
${figures.reduce((html, f) => html + `<a href="${location.origin + f.path}" target="_blank">
<img src="${location.origin + f.path}" style="max-width: 200px; max-height: 200px;"/>
</a>`, '')}
</td></tr>` : '';
    },
    generateHTMLQuestion(question, index): string {
      return `<tr><td>
<table class="table w-100 table-sm table-bordered">
<tr><th style="width: 90px">#</th><td>${index + 1}</td></tr>
<tr><th>ID</th><td><a href="${location.origin}/question/${question.id}" target="_blank">${question.id}</a></td></tr>
${this.generateHtmlFigures(question.figures)}
<tr><td colspan="2" class="pl-3 py-3 pt-0">
${question.text}<br><br>
${this.generateHTMLAnswers(question.answers)}
</td></tr>
</table>
</td></tr>`;
    },
    generateHtml(questions): string {
      const date = new Date();
      const subTitle = this.breadcrumbs.map(bc => bc.text).join(' / ');
      const questionsHtml = questions.reduce((html, q, i) => html + this.generateHTMLQuestion(q, i), '');
      return `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
  <title>LTMS Question Export</title>
</head>
<body>
<div class="jumbotron py-4">
  <h1 class="display-5">Question Export</h1>
  <p class="lead">${subTitle + ' '}</p>
  <p class="lead">${questions.length} Questions</p>
  <hr class="my-3">
  <p class="lead my-0">${date}</p>
</div>
<div class="container">
  <table class="table w-100 table-borderless">${questionsHtml}</table>
</div>
</body>
</html>`;
    },
  },
});
