



















import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {CourseDialog, CourseTable} from '~/components/course';
import {Course} from '~/models';

export default Vue.extend({
  components: {CourseTable, CourseDialog, TooltipButton},
  data() {
    return {
      add: {dialog: false},
    };
  },
  computed: {
    courses(): Course[] {
      return Course.all();
    },
    text(): string {
      return this.$tc('page.dashboard.courses', this.courses.length);
    },
  },
});
