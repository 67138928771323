import {Model} from '@vuex-orm/core';
import {AreaQuestion, Question, SubArea, Subject} from '.';

export class Area extends Model {
  static entity = 'areas';

  static fields() {
    return {
      id: this.attr(null),
      subjectId: this.attr(null),
      number: this.attr(''),
      name: this.attr(''),
      subject: this.belongsTo(Subject, 'subjectId'),
      subAreas: this.hasMany(SubArea, 'areaId'),
      questions: this.belongsToMany(Question, AreaQuestion, 'areaId', 'questionId'),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
    };
  }

  id: number;
  subjectId: number;
  number: string;
  name: string;
  subject: Subject;
  subAreas: SubArea[];
  questions: Question[];
  createdAt: string;
  updatedAt: string;

  get fullName() {
    return `${this.number} - ${this.name}`;
  }
}
