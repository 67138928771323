import {Model} from '@vuex-orm/core';
import {Course, Document, School, SchoolClassCourse, SchoolClassDocument, Student} from '../index';

export class SchoolClass extends Model {
  static entity = 'school-classes';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
      school: this.belongsTo(School, 'schoolId'),
      students: this.hasMany(Student, 'schoolClassId'),
      documents: this.belongsToMany(Document, SchoolClassDocument, 'schoolClassId', 'documentId'),
      courses: this.belongsToMany(Course, SchoolClassCourse, 'schoolClassId', 'courseId'),
      // foreignKeys
      schoolId: this.attr(null),
    };
  }

  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  school: School;
  students: Student[];
  documents: Document[];
  courses: Course[];
}
