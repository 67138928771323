import {Model} from '@vuex-orm/core';
import {Licence, Test} from '~/models';

export class TestLicence extends Model {
  static entity = 'test_licences';

  static primaryKey = ['testId', 'licenceId'];

  static fields() {
    return {
      limit: this.attr(null),
      licenceId: this.attr(null),
      testId: this.attr(null),
      licence: this.belongsTo(Licence, 'licenceId'),
      test: this.belongsTo(Test, 'testId'),
    };
  }

  testId: string;
  test: Test;
  licenceId: string;
  licence: Licence;
  limit: number;
}
