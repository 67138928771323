import {Module} from 'vuex';
import {Permission} from '~/models';
import {api} from '~/util/api';

const UPDATE_THRESHOLD = 60000; // 60 sek

export interface PermissionState {
  search: string,
  fetchedAt: number,
  optionsFetchedAt: number,
  permissionFetchedAt: { [id: number]: number },
}

export const permissionModule: Module<PermissionState, any> = {
  namespaced: true,
  state: {
    search: '',
    fetchedAt: 0,
    optionsFetchedAt: 0,
    permissionFetchedAt: {},
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
  },
  actions: {
    updateSearch: async (context, search) => {
      context.commit('SET_SEARCH', search);
    },
    fetchPermissions: async (context) => {
      if (Date.now() - context.state.fetchedAt > UPDATE_THRESHOLD) {
        context.state.fetchedAt = Date.now();
        const response = await api.get('/api/permission');
        await Permission.insert({data: response.data});
      }
    },
    fetchPermissionOptions: async (context) => {
      if (Date.now() - context.state.optionsFetchedAt > UPDATE_THRESHOLD) {
        context.state.optionsFetchedAt = Date.now();
        const response = await api.get('/api/permission/options');
        await Permission.insertOrUpdate({data: response.data});
      }
    },
    fetchPermission: async (context, id) => {
      if (!context.state.permissionFetchedAt[id] || Date.now() - context.state.permissionFetchedAt[id] > UPDATE_THRESHOLD) {
        context.state.permissionFetchedAt[id] = Date.now();
        const response = await api.get(`/api/permission/${id}`);
        await Permission.insert({data: response.data});
      }
    },
    updatePermission: async (context, payload) => {
      const response = await api.put(`/api/permission/${payload.id}`, payload.data);
      await Permission.insert({data: response.data});
    },
    createPermission: async (context, payload) => {
      const response = await api.post(`/api/permission`, payload);
      await Permission.insert({data: response.data});
    },
    deletePermission: async (context, payload) => {
      await api.delete(`/api/permission/${payload.id}`);
      await Permission.delete(payload.id);
    },
  },
  getters: {},
};
