














import Vue from 'vue';
import {WeakpointAnalysis} from '~/components/student';

export default Vue.extend({
  components: {WeakpointAnalysis},
  created() {
    document.title = 'LTMS - Analysis';
  },
});
