




















































































































import Vue from 'vue';
import {DeleteDialog, ImageDialog, TooltipButton} from '~/components/common';
import QuestionEditDialog from '~/components/question/QuestionEditDialog.vue';
import {Question} from '~/models';

export default Vue.extend({
  components: {ImageDialog, TooltipButton, QuestionEditDialog, DeleteDialog},
  data() {
    return {
      img: {dialog: false, src: null},
      showEditDialog: false,
      remove: {dialog: false, item: null},
    };
  },
  computed: {
    question(): Question {
      return Question.query().whereId(Number(this.$route.params.id)).withAll().first();
    },
  },
  async created() {
    await this.$store.dispatch('question/fetchById', this.$route.params.id);
    document.title = `LTMS - Question: ${this.question.number}`;
  },
  methods: {
    showDeleteDialog(item: Question) {
      this.remove.dialog = true;
      this.remove.item = item;
    },
    async deleteQuestion() {
      try {
        await this.$store.dispatch('question/delete', this.remove.item);
        await this.$store.dispatch('showSuccess', this.$t('msg.deleteSuccess', {item: this.$tc('p.question')}));
        await this.$router.push({name: 'questions'});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
