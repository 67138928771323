



































































import Vue from 'vue';
import {TranslateResult} from 'vue-i18n';
import {TooltipButton} from '~/components/common';
import {Student} from '~/models';
import {Randomizer} from '~/util';
import {schoolConstraints, schoolRules} from './school.rules';

export default Vue.extend({
  components: {TooltipButton},
  name: 'school-dialog',
  props: {
    value: {type: Boolean, default: false},
    school: {type: Object, default: null},
  },
  data() {
    return {
      isValid: true,
      showPassword: true,
      changePw: !this.school,
      formDefaults: null,
      form: {
        name: '',
        login: '',
        password: '',
        passRate: null,
        state: 'active',
        students: [],
        schoolClasses: [],
      },
      logo: null,
      options: {
        state: [
          {val: 'active', name: this.$t('label.active')},
          {val: 'inactive', name: this.$t('label.inactive')},
        ],
        schoolClasses: [],
      },
      rules: schoolRules.call(this),
      constraint: schoolConstraints,
    };
  },
  created() {
    this.setFormDefaults();
  },
  watch: {
    school() {
      this.setFormDefaults();
      this.changePw = !this.school;
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    students(): Student[] {
      return Student.all();
    },
    headline(): TranslateResult {
      return this.school ? this.$t('action.editItem', {item: this.$tc('p.school')}) : this.$t('action.createItem', {item: this.$tc('p.school')});
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      try {
        let msg;
        if (this.school) {
          const form = Object.assign({}, this.form, {initialSchoolClasses: this.school.classes.map(sc => sc.id)});
          if (!form.passRate) {
            delete form.passRate;
          }
          if (!this.changePw) {
            delete form.password;
          }
          await this.$store.dispatch('school/updateSchool', {form, img: this.logo, id: this.school.id});
          msg = this.$t('msg.updateSuccess', {item: this.$tc('p.school')});
        } else {
          const form = Object.assign({}, this.form);
          form.login = form.login || null;
          form.password = form.password || null;
          if (!form.passRate) {
            delete form.passRate;
          }
          await this.$store.dispatch('school/createSchool', {form, img: this.logo});
          msg = this.$t('msg.addSuccess', {item: this.$tc('p.school')});
        }
        await this.$store.dispatch('showSuccess', msg);
        this.dialog = false;
        this.reset();
      } catch (err) {
        await this.$store.dispatch('showError', this.getError(err));
      }
    },
    onSchoolClassInput(schoolClasses) {
      if (schoolClasses.length === 0) {
        return;
      }
      const newClass = schoolClasses.pop();
      const classNameIsNew = schoolClasses.findIndex(sc => typeof (sc) === 'object' && sc.name === newClass) === -1;
      if (classNameIsNew) {
        schoolClasses.push(newClass);
      }
    },
    getError(err) {
      if (err.response?.status === 409) {
        return this.$t('error.alreadyInUse', {item: this.$t('label.name'), value: this.form.name});
      }
      return err;
    },
    setFormDefaults() {
      if (this.school) {
        this.formDefaults = {
          name: this.school.name,
          state: this.school.state,
          login: this.school.login,
          password: this.school.password,
          passRate: this.school.passRate,
          students: this.school.students.map(student => student.id),
          schoolClasses: this.school.classes.map(sc => ({id: sc.id, name: sc.name})),
        };
      } else {
        this.formDefaults = Object.assign({}, this.form);
      }

      this.reset();
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      this.logo = null;
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
    generatePassword() {
      this.form.password = Randomizer.generateRandomStringOfLength(12);
    },
  },
});
