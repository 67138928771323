










import Vue from 'vue';
import {QuestionBrowser} from '~/components/question';

export default Vue.extend({
  components: {QuestionBrowser},
  created() {
    document.title = 'LTMS - Questions';
  },
  computed: {
    databaseId() {
      return Number(this.$route.query.database);
    },
    licenceId() {
      return Number(this.$route.query.licence);
    },
    subjectId() {
      return Number(this.$route.query.subject);
    },
    areaId() {
      return Number(this.$route.query.area);
    },
    subAreaId() {
      return Number(this.$route.query.subArea);
    },
  },
});
