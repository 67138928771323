






















import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {CsvImportDialog, StudentAddDialog, StudentPaginationTable} from '~/components/student';
import {Student} from '~/models';

export default Vue.extend({
  components: {StudentPaginationTable, StudentAddDialog, CsvImportDialog, TooltipButton},
  data() {
    return {
      add: {dialog: false},
      csv: {dialog: false},
    };
  },
  computed: {
    students(): Student[] {
      return Student.all();
    },
    text(): string {
      return this.$tc('page.dashboard.students', this.$store.state.student.total);
    },
  },
});
