import {Model} from '@vuex-orm/core';

export class CourseDocument extends Model {
  static entity = 'course_documents';

  static primaryKey = ['courseId', 'documentId'];

  static fields() {
    return {
      courseId: this.attr(null),
      documentId: this.attr(null),
    };
  }

  courseId: string;
  documentId: string;
}
