import {Model} from '@vuex-orm/core';
import {Test, TestGroup} from '~/models';

export class TestGroupTest extends Model {
  static entity = 'test-group_tests';

  static primaryKey = ['testGroupId', 'testId'];

  static fields() {
    return {
      testGroupId: this.attr(null),
      testId: this.attr(null),
      testGroup: this.belongsTo(TestGroup, 'testGroupId'),
      test: this.belongsTo(Test, 'testId'),
    };
  }

  testGroupId: number;
  testGroup: TestGroup;
  testId: number;
  test: Test;
}
