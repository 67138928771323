





























































































import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import QuestionSelector from '~/components/question/QuestionSelector.vue';
import QuestionTable from '~/components/question/QuestionTable.vue';
import {Area, Licence, SubArea, Subject} from '~/models';

export default Vue.extend({
  name: 'import-questions-dialog',
  components: {QuestionSelector, QuestionTable, TooltipButton},
  props: {
    value: {type: Boolean, default: false},
    licence: {type: Object, default: null},
  },
  data() {
    return {
      isValid: true,
      form: {
        licences: [],
        subjects: [],
        areas: [],
        subAreas: [],
        questions: [],
      },
    };
  },
  created() {
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    sectionHeaders(): any[] {
      return [
        {text: 'name', value: 'fullName'},
        {text: 'actions', value: 'actions', width: '40px', align: 'right'},
      ];
    },
    hasSelection(): boolean {
      return this.form.licences.length > 0 || this.form.subjects.length > 0 || this.form.areas.length > 0 || this.form.subAreas.length > 0 || this.form.questions.length > 0;
    },
  },
  methods: {
    removeLicence(licence) {
      const i = this.form.licences.findIndex(s => s.id === licence.id);
      this.form.licences.splice(i, 1);
    },
    removeSubject(subject) {
      const i = this.form.subjects.findIndex(s => s.id === subject.id);
      this.form.subjects.splice(i, 1);
    },
    removeArea(area) {
      const i = this.form.areas.findIndex(a => a.id === area.id);
      this.form.areas.splice(i, 1);
    },
    removeSubArea(subArea) {
      const i = this.form.subAreas.findIndex(sa => sa.id === subArea.id);
      this.form.subAreas.splice(i, 1);
    },
    removeQuestion(question) {
      const i = this.form.questions.findIndex(q => q.id === question.id);
      this.form.questions.splice(i, 1);
    },
    async addLicences(licencesIds) {
      const newLicenceIds = licencesIds.filter(id => !this.form.licences.find(s => s.id === id));
      const licences = Licence.findIn(newLicenceIds);
      this.form.licences = this.form.licences.concat(licences);
    },
    async addSubjects(subjectsIds) {
      const newSubjectIds = subjectsIds.filter(id => !this.form.subjects.find(s => s.id === id));
      const subjects = Subject.query().whereIdIn(newSubjectIds).with('licence').all();
      this.form.subjects = this.form.subjects.concat(subjects);
    },
    async addAreas(areaIds) {
      const newAreaIds = areaIds.filter(id => !this.form.areas.find(s => s.id === id));
      const areas = Area.query().whereIdIn(newAreaIds).with('subject.licence').all();
      this.form.areas = this.form.areas.concat(areas);
    },
    async addSubAreas(subAreaIds) {
      const newSubAreaIds = subAreaIds.filter(id => !this.form.subAreas.find(s => s.id === id));
      const subAreas = SubArea.query().whereIdIn(newSubAreaIds).with('area.subject.licence').all();
      this.form.subAreas = this.form.subAreas.concat(subAreas);
    },
    addQuestions(questions) {
      questions.forEach(question => {
        const storedQuestion = this.form.questions.find(q => q.id === question.id);
        if (!storedQuestion) {
          this.form.questions.push(question);
        }
      });
    },
    async submit() {
      if (!this.isValid) {
        return;
      }

      const payload = {
        licences: this.form.licences.map(item => item.id),
        subjects: this.form.subjects.map(item => item.id),
        areas: this.form.areas.map(item => item.id),
        subAreas: this.form.subAreas.map(item => item.id),
        questions: this.form.questions.map(item => item.id),
      };

      try {
        await this.$emit('import', payload);
        await this.$store.dispatch('showSuccess', this.$t('msg.importSuccess', {item: this.$tc('p.question', 2)}));
        this.dialog = false;
        this.reset();
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    reset() {
      this.form.licences = [];
      this.form.subjects = [];
      this.form.areas = [];
      this.form.subAreas = [];
      this.form.questions = [];
    },
  },
});
