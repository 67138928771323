






























import Vue from 'vue';
import {TranslateResult} from 'vue-i18n';
import {permissionConstraints, permissionRules} from './permission.rules';

export default Vue.extend({
  name: 'PermissionDialog',
  props: {
    value: {type: Boolean, default: false},
    permission: {type: Object, default: null},
  },
  data() {
    return {
      isLoading: false,
      isValid: true,
      formDefaults: null,
      form: {
        name: '',
      },
      rules: permissionRules.call(this),
      constraint: permissionConstraints,
    };
  },
  watch: {
    async value(value) {
      if (value) {
        this.isLoading = true;
        // await this.$store.dispatch('permission/fetchDocumentsOptions');
        this.setFormDefaults();
        this.isLoading = false;
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    headline(): TranslateResult {
      return this.permission ? this.$t('action.editItem', {item: this.$tc('p.permission')}) : this.$t('action.createItem', {item: this.$tc('p.permission')});
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      try {
        let msg;
        if (this.permission) {
          const data = this.form;
          await this.$store.dispatch('permission/updatePermission', {id: this.permission.id, data});
          msg = this.$t('msg.updateSuccess', {item: this.$tc('p.permission')});
        } else {
          await this.$store.dispatch('permission/createPermission', this.form);
          msg = this.$t('msg.addSuccess', {item: this.$tc('p.permission')});
        }
        await this.$store.dispatch('showSuccess', msg);
        this.reset();
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', this.getError(err));
      }
    },
    getError(err) {
      return err;
    },
    setFormDefaults() {
      if (this.permission) {
        this.formDefaults = {
          name: this.permission.name,
        };
      } else {
        this.formDefaults = Object.assign({}, this.form);
      }
      this.reset();
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
