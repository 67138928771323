









































































































































































































































import Vue from 'vue';
import {ConfirmDialog, DeleteDialog, TooltipButton, TooltipDate} from '~/components/common';
import ImageViewer from '~/components/common/ImageViewer.vue';
import AnswerDialog from '~/components/test/AnswerDialog.vue';
import {Question, TestInstance} from '~/models';

export default Vue.extend({
  components: {TooltipDate, TooltipButton, DeleteDialog, AnswerDialog, ConfirmDialog, ImageViewer},
  data() {
    return {
      dialog: {show: false, question: null},
      img: {show: false, path: null},
      imgDialog: {show: false, figures: null},
      info: null,
      deleteDialog: false,
      confirmDialog: false,
      answerDialog: {show: false, id: null},
      testInstanceResponse: null,
    };
  },
  async created() {
    const result = await Promise.all([
      this.$store.dispatch('test/fetchInstanceById', this.$route.params.id),
      this.$store.dispatch('test/getTestInstanceAdditionalInfo', this.$route.params.id),
    ]);
    this.testInstanceResponse = result[0];
    this.info = result[1];
    document.title = `LTMS - TestInstance: ${this.$route.params.id}`;
  },
  watch: {
    async '$route.params.id'(newInstanceId) {
      const results = await Promise.all([
        this.$store.dispatch('test/fetchInstanceById', newInstanceId),
        this.$store.dispatch('test/getTestInstanceAdditionalInfo', newInstanceId),
      ]);
      this.testInstanceResponse = results[0];
      this.info = results[1];
    },
  },
  computed: {
    instance(): TestInstance {
      const id = Number(this.$route.params.id);
      return TestInstance.query().whereId(id).withAll()
          .with('questions.answers')
          .with('questions.licences|subjects|areas|subAreas|figures|explanationFigures')
          .first();
    },
    headers(): any[] {
      return [
        {text: this.$tc('p.question'), value: 'text'},
      ];
    },
    studentHeaders(): any[] {
      return [
        {text: this.$t('label.name'), value: 'name'},
      ];
    },
    instanceHeaders(): any[] {
      return [
        {text: this.$tc('p.student'), value: 'student.fullName'},
        {text: this.$tc('p.correctAnswer', 2), value: 'correctAnswers'},
        {text: this.$t('label.finishedAt'), value: 'finishedAt'},
        {text: this.$t('label.passed'), value: 'passed'},
      ];
    },
    percentage(): number {
      const percentage = this.instance.correctAnswers / this.instance.questions.length * 100;
      return Math.round(percentage * 100) / 100;
    },
    isLatestInstance(): boolean {
      return this.instanceIndex === this.info.instances.length - 1;
    },
    isFirstInstance(): boolean {
      return this.instanceIndex === 0;
    },
    instanceIndex(): number {
      return this.info ? this.info.instances.findIndex((instance: any) => instance.id == this.instance.id) : -1;
    },
    hasTriesLeft(): boolean {
      return this.instance.try < this.instance.test.tries;
    },
  },
  methods: {
    getBreadcrumbs(question: Question) {
      if (!this.testInstanceResponse) {
        return null;
      }
      const responseQuestion = this.testInstanceResponse.questions.find((q: any) => q.id === question.id);
      return responseQuestion?.breadCrumbs;
    },
    async deleteInstance() {
      await this.$store.dispatch('test/deleteInstance', this.instance);
      await this.$router.back();
    },
    async onChangeInsight(insightGranted: boolean) {
      if (insightGranted) {
        await this.$store.dispatch('test/grantInsight', this.instance);
      } else {
        await this.$store.dispatch('test/denyInsight', this.instance);
      }
    },
    getCorrectAnswer(question: Question) {
      return question.answers.find(a => a.isCorrect);
    },
    getProvidedAnswer(question: Question) {
      return this.instance.answers.find(a => a.questionId === question.id);
    },
    isCorrect(question: Question): boolean {
      const correctAnswer = this.getCorrectAnswer(question);
      const providedAnswer = this.getProvidedAnswer(question);
      return correctAnswer?.id === providedAnswer?.id;
    },
    async giveAdditionalTry(): Promise<void> {
      await this.$store.dispatch('test/giveAdditionalTry', {
        testId: this.instance.test.id,
        studentId: this.instance.student.id,
      });
      this.info.extraTry = true;
    },
    goToPrevInstance() {
      if (this.instanceIndex > 0) {
        const target = this.info.instances[this.instanceIndex - 1];
        this.$router.push({name: 'test-instance-details', params: {id: target.id}});
      }
    },
    goToNextInstance() {
      if (this.instanceIndex < this.info.instances.length - 1) {
        const target = this.info.instances[this.instanceIndex + 1];
        this.$router.push({name: 'test-instance-details', params: {id: target.id}});
      }
    },
    async recalculateResults() {
      await this.$store.dispatch('test/recalculateResults', this.instance.id);
    },
  },
});
