

















































import Vue from 'vue';
import {DataTable, DeleteDialog, StateChip, TooltipButton, TooltipDate} from '~/components/common';
import AssignStudentsDialog from '~/components/test/AssignStudentsDialog.vue';
import TestGroupDialog from '~/components/test/TestGroupDialog.vue';
import {TestGroup} from '~/models';

export default Vue.extend({
  name: 'TestGroupTable',
  components: {DataTable, TooltipButton, TooltipDate, DeleteDialog, StateChip, TestGroupDialog, AssignStudentsDialog},
  props: {
    dense: {type: Boolean, default: false},
    tests: {type: Array, default: () => []},
    actionsWidth: {type: String, default: null},
    hideFields: {type: Array, default: () => []},
    actions: {type: Array, default: () => ['assign', 'edit', 'archive']},
    itemsPerPage: {type: Number, default: 10},
  },
  data() {
    return {
      isLoading: false,
      search: '',
      assign: {dialog: false, item: {students: []} as any},
      edit: {dialog: false, item: null},
      del: {dialog: false, item: null},
      filterState: 'active',
    };
  },
  computed: {
    items(): TestGroup[] {
      return TestGroup.query().withAll().all();
    },
    filteredItems(): TestGroup[] {
      return this.filterState === 'all' ? this.items : this.items.filter(t => {
        switch (this.filterState) {
          case 'active':
            return t.state === '' || t.state === 'active';
          case 'archived':
            return t.state === 'archived';
        }
      });
    },
    filterOptions(): any[] {
      return [
        {text: this.$t('label.all'), value: 'all'},
        {text: this.$t('label.active'), value: 'active'},
        {text: this.$t('label.archived'), value: 'archived'},
      ];
    },
    additionalActions(): any[] {
      return this.actions.filter((action: any) => action && action.icon && action.tooltip && action.emit);
    },
    headers(): any[] {
      const fields = [
        {text: this.$t('label.title'), value: 'title'},
        {text: this.$t('label.state'), value: 'state'},
        {text: this.$tc('p.test', 2), value: 'tests.length'},
        {text: this.$tc('p.student', 2), value: 'students.length'},
        {text: this.$t('label.createdAt'), value: 'createdAt'},
        {text: this.$t('label.actions'), value: 'actions', align: 'center', sortable: false, width: this.actionsWidth || this.actions.length * 52 + 'px'},
      ];
      return fields.filter(field => !this.hideFields.includes(field.value));
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.$store.dispatch('testGroup/fetchAll');
    } catch (err) {
      await this.$store.dispatch('showError', err);
    }
    this.isLoading = false;
  },
  methods: {
    showField(field: string) {
      return !this.hideFields.includes(field);
    },
    searchFilter(title: string, search: string, group: TestGroup) {
      const searchTerms = search.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && group.title.toLowerCase().includes(term), true);
    },
    async deleteTestGroup() {
      await this.$store.dispatch('testGroup/delete', this.del.item);
      await this.$store.dispatch('showSuccess', this.$root.$t('msg.deleteSuccess', {item: this.$root.$tc('p.testGroup')}));
    },
    async assignStudentsToGroup(students: any[]) {
      const payload = {testGroupId: this.assign.item.id, students};
      await this.$store.dispatch('testGroup/assignStudents', payload);
    },
    async archive(testGroup: TestGroup) {
      await this.$store.dispatch('testGroup/archive', testGroup);
    },
    async unarchive(testGroup: TestGroup) {
      await this.$store.dispatch('testGroup/activate', testGroup);
    },
  },
});
