<template>
  <v-app>
    <loading-indicator v-if="auth.isAuthPending"/>
    <v-container v-else-if="auth.unavailable">
      <v-alert color="error" class="text-center">Backend currently not available.</v-alert>
    </v-container>
    <app-layout v-else-if="auth.isAuthenticated" class="app-layout"/>
    <sign-in-page v-else/>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" top>
      <span v-html="snackbar.msg"/>
      <v-btn text @click="$store.dispatch('hideSnack')">{{ $t('action.close') }}</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import './styles/index.scss';

import LoadingIndicator from './components/common/LoadingIndicator';
import SignInPage from './pages/auth/SignInPage';
import AppLayout from './components/layout/AppLayout';
import moment from 'moment';
import {StorageManager} from './util';

export default {
  name: 'app',
  components: {
    SignInPage,
    AppLayout,
    LoadingIndicator,
  },
  async created() {
    this.$vuetify.theme.dark = StorageManager.isDarkTheme();
    this.setLocale(StorageManager.getLocale());

    try {
      await this.$store.dispatch('auth/signInByToken');
    } catch (err) {
      // ignore failed token sign-up
      console.log(err);
    }
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    snackbar() {
      return this.$store.state.snackbar;
    },
  },
  methods: {
    setLocale(locale) {
      moment.locale(locale);
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .question-text {
  table {
    border-spacing: 0;
    border-collapse: collapse;

    td {
      padding: 2px 6px;
      border: 1px solid rgba(#000, 0.4);
    }
  }
}

// change data-table row hover color
::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #ddd;
}
</style>
