


















































































































import Vue from 'vue';
import {TranslateResult} from 'vue-i18n';
import {TooltipButton, VueEditor} from '~/components/common';
import {questionRules} from '~/components/question/question.rules';
import {Area, Database, Licence, SubArea, Subject} from '~/models';

export default Vue.extend({
  name: 'question-dialog',
  components: {TooltipButton, VueEditor},
  props: {
    value: {type: Boolean, default: false},
    question: {type: Object, default: null},
    database: {type: Object, default: null},
    licence: {type: Object, default: null},
    subject: {type: Object, default: null},
    area: {type: Object, default: null},
    subArea: {type: Object, default: null},
  },
  data() {
    return {
      rule: questionRules.call(this),
      isValid: false,
      formDefaults: null,
      files: {
        figures: [],
        explanationFigures: [],
      },
      form: {
        language: 'de',
        text: '',
        points: 1,
        explanation: '',
        answers: [
          {text: '', isCorrect: false, index: 0},
          {text: '', isCorrect: false, index: 1},
          {text: '', isCorrect: false, index: 2},
          {text: '', isCorrect: false, index: 3},
        ],
        database: null,
        licence: null,
        subject: null,
        area: null,
        subArea: null,
      },
    };
  },
  created() {
    this.formDefaults = Object.assign({}, this.form);
  },
  watch: {
    async question() {
      if (this.question) {
        await this.$store.dispatch('question/fetchById', this.question.id);
      }
    },
    value(newValue) {
      if (newValue) {
        this.form.database = this.database?.id || null;
        this.form.licence = this.licence?.id || null;
        this.form.subject = this.subject?.id || null;
        this.form.area = this.area?.id || null;
        this.form.subArea = this.subArea?.id || null;
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    databases(): Database[] {
      return Database.all().sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0);
    },
    licences(): Licence[] {
      return Licence.query().where('databaseId', this.form.database).all()
          .sort((a, b) => a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : 0);
    },
    subjects(): Subject[] {
      return Subject.query().withAll().all().filter(s => s.licenceId === this.form.licence)
          .sort((a, b) => a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : 0);
    },
    areas(): Area[] {
      return Area.query().withAll().all().filter(a => a.subjectId === this.form.subject)
          .sort((a, b) => a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : 0);
    },
    subAreas(): SubArea[] {
      return SubArea.query().withAll().all().filter(sa => sa.areaId === this.form.area)
          .sort((a, b) => a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : 0);
    },
    headline(): TranslateResult {
      return this.question ? this.$t('action.editItem', {item: this.$tc('p.question')}) : this.$t('action.createItem', {item: this.$tc('p.question')});
    },
    languages(): any[] {
      return [
        {value: 'de', text: this.$t('language.german')},
        {value: 'en', text: this.$t('language.english')},
      ];
    },
    headers(): any[] {
      return [
        {value: 'text', text: this.$t('label.text'), sortable: false},
        {value: 'isCorrect', text: this.$t('label.isCorrect'), sortable: false, width: '150px'},
        {value: 'action', text: '', sortable: false, width: '50px'},
      ];
    },
  },
  methods: {
    async onDatabaseChange(id) {
      if (id) {
        await this.$store.dispatch('question/fetchDatabase', id);
      }
      this.form.licence = null;
      this.form.subject = null;
      this.form.area = null;
      this.form.subArea = null;
    },
    async onLicenceChange(id) {
      if (id) {
        await this.$store.dispatch('question/fetchLicence', {licence: id});
      }
      this.form.subject = null;
      this.form.area = null;
      this.form.subArea = null;
    },
    async onSubjectChange(id) {
      if (id) {
        await this.$store.dispatch('question/fetchSubject', {subject: id});
      }
      this.form.area = null;
      this.form.subArea = null;
    },
    async onAreaChange(id) {
      if (id) {
        await this.$store.dispatch('question/fetchArea', {area: id});
      }
      this.form.subArea = null;
    },
    async submit() {
      if (!this.isValid) {
        return;
      }

      try {
        let msg;
        const payload = {
          form: this.form,
          figures: this.files.figures,
          explanationFigures: this.files.explanationFigures,
        };

        const createdQuestion = await this.$store.dispatch('question/createQuestion', payload);
        msg = this.$t('msg.addSuccess', {item: this.$tc('p.question')});

        await this.$store.dispatch('showSuccess', msg);
        this.dialog = false;
        await this.$router.push({name: 'question-details', params: {id: createdQuestion.id}});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    addAnswer() {
      this.form.answers.push({text: '', isCorrect: false, index: this.form.answers.length});
    },
    removeAnswer(item) {
      const index = this.form.answers.findIndex(a => a.index === item.index);
      this.form.answers.splice(index, 1);
    },
    onMarkAsCorrect(item) {
      this.form.answers.forEach((a: any) => a.isCorrect = item.id ? a.id === item.id : a.index === item.index);
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      this.form.answers.forEach(a => {
        a.text = '';
        a.isCorrect = false;
      });
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
