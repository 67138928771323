





















import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {DocumentDialog, DocumentTable} from '~/components/document';

export default Vue.extend({
  components: {
    TooltipButton, DocumentDialog, DocumentTable,
  },
  data() {
    return {
      addDialog: false,
    };
  },
  created() {
    document.title = 'LTMS - Documents';
  },
  computed: {
    search: {
      get(): string {
        return this.$store.state.document.search;
      },
      set(value: string) {
        this.$store.dispatch('document/updateSearch', value);
      },
    },
  },
});
