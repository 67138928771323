import {Model} from '@vuex-orm/core';

export class QuestionFigure extends Model {
  static entity = 'question_figures';

  static primaryKey = ['questionId', 'figureId'];

  static fields() {
    return {
      questionId: this.attr(null),
      figureId: this.attr(null),
    };
  }

  questionId: string;
  figureId: string;
}
