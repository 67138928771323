



































import Vue from 'vue';
import TooltipButton from '~/components/common/TooltipButton.vue';
import {Randomizer} from '~/util';

export default Vue.extend({
  name: 'ChangeTestStateDialog',
  components: {TooltipButton},
  props: {
    value: {type: Boolean, default: false},
    testState: {type: Object, default: () => ({})},
  },
  data() {
    return {
      form: {
        state: null,
        autoInsight: false,
        password: null,
      },
    };
  },
  watch: {
    testState() {
      if (this.testState) {
        this.form.autoInsight = this.testState.autoInsight;
        this.form.state = this.testState.state;
        this.form.password = this.testState.password;
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async submit() {
      try {
        const payload = {studentId: this.testState.studentId, testId: this.testState.testId, form: this.form};
        await this.$store.dispatch('student/authorizeTest', payload);
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('snackbar/showError', err);
      }
    },
    close() {
      this.dialog = false;
    },
    generatePassword() {
      this.form.password = Randomizer.generateRandomStringOfLength(10);
    },
  },
});
