var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('action.importItem', {item: _vm.$tc('p.question', 2)})))])],1),_c('v-card-text',{staticClass:"pb-0 pt-2"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('QuestionSelector',{on:{"select-subjects":_vm.addSubjects,"select-areas":_vm.addAreas,"select-sub-areas":_vm.addSubAreas,"select-questions":_vm.addQuestions,"select-licences":_vm.addLicences}})],1),(_vm.form.licences.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$tc('p.licence', 2))+" ("+_vm._s(_vm.form.licences.length)+")")]),_c('v-data-table',{staticClass:"section-table",attrs:{"items":_vm.form.licences,"headers":_vm.sectionHeaders,"hide-default-header":"","hide-default-footer":_vm.form.licences.length <= 10,"dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip-button',{attrs:{"small":"","icon":"delete","tooltip":_vm.$t('action.removeItem', {item: _vm.$tc('p.licence')})},on:{"click":function($event){return _vm.removeLicence(item)}}})]}}],null,false,4007048912)})],1):_vm._e(),(_vm.form.subjects.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$tc('p.subject', 2))+" ("+_vm._s(_vm.form.subjects.length)+")")]),_c('v-data-table',{staticClass:"section-table",attrs:{"items":_vm.form.subjects,"headers":_vm.sectionHeaders,"hide-default-header":"","hide-default-footer":_vm.form.subjects.length <= 10,"dense":""},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.fullName))])]}}],null,true)},[(item.licence)?_c('span',[_vm._v(_vm._s(item.licence.fullName))]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip-button',{attrs:{"small":"","icon":"delete","tooltip":_vm.$t('action.removeItem', {item: _vm.$tc('p.subject')})},on:{"click":function($event){return _vm.removeSubject(item)}}})]}}],null,false,2540318339)})],1):_vm._e(),(_vm.form.areas.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$tc('p.area', 2))+" ("+_vm._s(_vm.form.areas.length)+")")]),_c('v-data-table',{staticClass:"section-table",attrs:{"items":_vm.form.areas,"headers":_vm.sectionHeaders,"hide-default-header":"","hide-default-footer":_vm.form.subjects.length <= 10,"dense":""},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.fullName))])]}}],null,true)},[(item.subject)?_c('span',[_vm._v(_vm._s(item.subject.licence.fullName)+" / "+_vm._s(item.subject.fullName))]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip-button',{attrs:{"small":"","icon":"delete","tooltip":_vm.$t('action.removeItem', {item: _vm.$tc('p.area')})},on:{"click":function($event){return _vm.removeArea(item)}}})]}}],null,false,2863246137)})],1):_vm._e(),(_vm.form.subAreas.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$tc('p.subArea', 2))+" ("+_vm._s(_vm.form.subAreas.length)+")")]),_c('v-data-table',{staticClass:"section-table",attrs:{"items":_vm.form.subAreas,"headers":_vm.sectionHeaders,"hide-default-header":"","hide-default-footer":_vm.form.subjects.length <= 10,"dense":""},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.fullName))])]}}],null,true)},[(item.area)?_c('span',[_vm._v(_vm._s(item.area.subject.licence.fullName)+" / "+_vm._s(item.area.subject.fullName)+" / "+_vm._s(item.area.fullName))]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip-button',{attrs:{"small":"","icon":"delete","tooltip":_vm.$t('action.removeItem', {item: _vm.$tc('p.subArea')})},on:{"click":function($event){return _vm.removeSubArea(item)}}})]}}],null,false,1759276486)})],1):_vm._e(),(_vm.form.questions.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$tc('p.question', 2))+" ("+_vm._s(_vm.form.questions.length)+")")]),_c('QuestionTable',{attrs:{"dense":"","questions":_vm.form.questions,"actions":[{icon: 'mdi-delete', tooltip: 'remove', emit: 'remove'}]},on:{"remove":_vm.removeQuestion}})],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.reset}},[_vm._v(_vm._s(_vm.$t('action.reset')))]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog=false}}},[_vm._v(_vm._s(_vm.$t('action.cancel')))]),_c('v-btn',{attrs:{"disabled":!_vm.hasSelection,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('action.submit')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }