
























import Vue from 'vue';
import {Licence} from '~/models';

export default Vue.extend({
  name: 'question-filter',
  data() {
    return {
      waitForResponse: false,
      licence: null,
      subject: null,
      area: null,
      subArea: null,
      text: null,
    };
  },
  async created() {
    try {
      await this.$store.dispatch('question/fetchLicences');
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    licences(): Licence[] {
      return Licence.query().with('subjects.areas.subAreas').all();
    },
  },
  methods: {
    async submit() {
      const filter = {
        licence: this.licence?.id,
        subject: this.subject?.id,
        area: this.area?.id,
        subArea: this.subArea?.id,
        text: this.text,
      };
      this.waitForResponse = true;
      const questions = await this.$store.dispatch('question/fetchQuestions', filter);
      this.$emit('success', questions);
      this.waitForResponse = false;
    },
  },
});
