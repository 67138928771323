




































import Vue from 'vue';
import {versionConstraints, versionRules} from './document.rules';

export default Vue.extend({
  name: 'version-dialog',
  props: {
    value: {type: Boolean, default: false},
    version: {type: Object, default: null},
  },
  data() {
    return {
      isValid: true,
      formDefaults: null,
      form: {
        name: '',
        author: '',
        hasLogo: false,
        isDuplex: false,
      },
      rules: versionRules.call(this),
      constraint: versionConstraints,
    };
  },
  created() {
    this.setFormDefaults();
  },
  watch: {
    version() {
      this.setFormDefaults();
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    formRef(): HTMLFormElement {
      return this.$refs.form as HTMLFormElement;
    },
  },
  methods: {
    async submit() {
      if (!this.formRef.validate()) {
        return;
      }

      try {
        await this.$store.dispatch('document/updateVersion', {id: this.version.id, data: this.form});
        const msg = this.$t('msg.updateSuccess', {item: this.$tc('p.version')});
        await this.$store.dispatch('showSuccess', msg);
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', this.getError(err));
      }
    },
    getError(err) {
      if (err.response?.status === 409) {
        return this.$t('error.alreadyInUse', {item: this.$t('label.name'), value: this.form.name});
      }
      return err;
    },
    setFormDefaults() {
      if (this.version) {
        this.formDefaults = {
          author: this.version.author,
          name: this.version.name,
          hasLogo: this.version.hasLogo,
          isDuplex: this.version.isDuplex,
        };
      } else {
        this.formDefaults = Object.assign({}, this.form);
      }

      this.reset();
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
