var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"responsiveContainer",staticClass:"elevation-1",class:_vm.responsiveClass,attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"custom-filter":_vm.searchFilter,"footer-props":{'items-per-page-text': _vm.$t('pagination.rowsPerPage')},"item-key":"id","hide-default-footer":_vm.itemsPerPage === -1 || _vm.items.length <= _vm.itemsPerPage,"loading":_vm.loading,"dense":_vm.dense,"no-data-text":_vm.$t('table.noData'),"must-sort":""},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"warning","icon":"warning"}},[_vm._v(_vm._s(_vm.$t('search.noResults', {search: _vm.search})))])]},proxy:true},{key:"pageText",fn:function(props){return [_vm._v(_vm._s(_vm.$t('pagination.pageText', {start: props.pageStart, stop: props.pageStop, length: props.itemsLength})))]}},{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_vm._t("item",null,{"item":item,"isExpanded":isExpanded,"expand":expand})]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{staticClass:"expansion",attrs:{"colspan":headers.length}},[_vm._t("expanded-item",null,{"item":item})],2)]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }