import {RuleGenerator} from '~/util/RuleGenerator';

export const options = {
  deviceSlots: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  generatedPasswordLength: 12,
};

export const studentConstraints = {
  login: {isRequired: true, maxLength: 60},
  forename: {isRequired: true, maxLength: 30},
  surname: {isRequired: true, maxLength: 30},
  email: {isRequired: true, maxLength: 60, isEmail: true},
  password: {isRequired: true, minLength: 6},
  phoneNr: {minLength: 6, maxLength: 30, isPhone: true},
};

export function studentRules(this: any) {
  return RuleGenerator.generateRules.call(this, studentConstraints);
}
