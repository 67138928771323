























































































































import {isNumeric} from 'rxjs/internal-compatibility';
import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {QuestionTable} from '~/components/question';
import QuestionSelector from '~/components/question/QuestionSelector.vue';
import {testConstraints, testRules} from '~/components/test/test.rules';
import {Area, Licence, SubArea, Subject, Test} from '~/models';
import {FormHelper} from '~/util';

export default Vue.extend({
  components: {QuestionSelector, QuestionTable, TooltipButton},
  data() {
    return {
      search: '',
      isValid: false,
      rule: testRules.call(this),
      constraint: testConstraints,
      formDefaults: null,
      count: {
        licence: {},
        subject: {},
        area: {},
        subArea: {},
      },
      options: {
        tests: [],
      },
      form: {
        title: '',
        displayName: '',
        tries: 3,
        type: null,
        coolDown: 0,
        passRate: 75,
        language: '',
        isPreExam: false,
        timeLimit: null,
        dependentOn: null,
        licences: [],
        subjects: [],
        areas: [],
        subAreas: [],
        questions: [],
        eval: {
          licence: null,
        },
        limit: {
          licence: {},
          subject: {},
          area: {},
          subArea: {},
        },
      },
    };
  },
  async created() {
    await this.$store.dispatch('test/fetchById', this.$route.params.id);
    document.title = `LTMS - Test Edit: ${this.test.title}`;

    const result = await Promise.all([
      this.$store.dispatch('question/getCount', {type: 'licence', ids: this.test.licences.map(l => l.licenceId)}),
      this.$store.dispatch('question/getCount', {type: 'subject', ids: this.test.subjects.map(s => s.subjectId)}),
      this.$store.dispatch('question/getCount', {type: 'area', ids: this.test.areas.map(a => a.areaId)}),
      this.$store.dispatch('question/getCount', {type: 'subArea', ids: this.test.subAreas.map(sa => sa.subAreaId)}),
      this.$store.dispatch('test/fetchOptions', {insert: false}),
    ]);

    Object.keys(result[0]).forEach(licenceId => this.count.licence[licenceId] = String(result[0][licenceId]));
    Object.keys(result[1]).forEach(subjectId => this.count.subject[subjectId] = String(result[1][subjectId]));
    Object.keys(result[2]).forEach(areaId => this.count.area[areaId] = String(result[2][areaId]));
    Object.keys(result[3]).forEach(subAreaId => this.count.subArea[subAreaId] = String(result[3][subAreaId]));
    this.options.tests = result[4].filter(test => test.state === 'active');

    this.formDefaults = {
      title: this.test.title,
      displayName: this.test.displayName,
      tries: String(this.test.tries),
      language: this.test.language,
      passRate: String(this.test.passRate),
      type: this.test.type,
      coolDown: this.test.coolDown,
      timeLimit: this.test.timeLimit,
      feedback: this.test.feedback,
      dependentOn: this.test.dependentOn?.id || null,
      licences: this.test.licences.map(l => l.licence),
      subjects: this.test.subjects.map(s => s.subject),
      areas: this.test.areas.map(a => a.area),
      subAreas: this.test.subAreas.map(sa => sa.subArea),
      questions: this.test.questions,
      eval: {
        licence: this.test.evalLicenceId,
      },
      limit: {
        licence: this.test.licences.reduce((prev, l) => {
          prev[l.licenceId] = String(l.limit);
          return prev;
        }, {}),
        subject: this.test.subjects.reduce((prev, s) => {
          prev[s.subjectId] = String(s.limit);
          return prev;
        }, {}),
        area: this.test.areas.reduce((prev, a) => {
          prev[a.areaId] = String(a.limit);
          return prev;
        }, {}),
        subArea: this.test.subAreas.reduce((prev, sa) => {
          prev[sa.subAreaId] = String(sa.limit);
          return prev;
        }, {}),
      },
    };
    this.reset();
  },
  watch: {
    'form.title'(newValue, oldValue) {
      if (oldValue === this.form.displayName) {
        this.form.displayName = newValue;
      }
    },
  },
  computed: {
    isPreExam(): boolean {
      return this.form.type === 'preExam';
    },
    licences(): Licence[] {
      return Licence.all().sort((a, b) => a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : 0);
    },
    hasChanged(): boolean {
      return !FormHelper.isEqualTo(this.form, this.formDefaults);
    },
    languages(): any[] {
      return [
        {value: 'de', text: this.$t('language.german')},
        {value: 'en', text: this.$t('language.english')},
      ];
    },
    feedbackItems(): any[] {
      return [
        {value: 'optional', text: this.$t('label.optional')},
        {value: 'mandatory', text: this.$t('label.mandatory')},
      ];
    },
    testTypes() {
      return [
        {text: this.$t('label.default'), value: 'default'},
        {text: this.$t('label.progressTest'), value: 'progress'},
        {text: this.$t('label.finalTest'), value: 'final'},
        {text: this.$t('label.preExam'), value: 'preExam'},
      ];
    },
    sectionHeaders(): any[] {
      return [
        {text: 'name', value: 'fullName'},
        {text: 'limit', value: 'limit', width: '120px'},
        {text: 'actions', value: 'actions', width: '40px'},
      ];
    },
    preExamTimeLimitRule(): any[] {
      return [
        (v: string) => !!v || this.$t('form.isRequired', {field: this.$t('label.timeLimit')}),
        (v: number) => v >= 1 || this.$t('form.minValue', {field: this.$t('label.timeLimit'), val: 1}),
      ];
    },
    test(): Test {
      return Test.query().whereId(Number(this.$route.params.id)).withAll()
          .with('licences.licence')
          .with('subjects.subject.licence')
          .with('areas.area.subject.licence')
          .with('subAreas.subArea.area.subject.licence')
          .first();
    },
  },
  methods: {
    customTestFilter(item, search) {
      const searchTerms = search.trim().toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && item.title.toLowerCase().includes(term), true);
    },
    customLicenceFilter(item, search) {
      const searchTerms = search.trim().toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && item.fullName.toLowerCase().includes(term), true);
    },
    onTypeChange(type) {
      switch (type) {
        case 'preExam':
          this.form.tries = 1;
          this.form.passRate = 85;
          this.form.coolDown = 0;
          break;
        case 'final':
          this.form.coolDown = 72;
          this.form.passRate = 75;
          break;
        case 'progress':
          this.form.coolDown = 24;
          this.form.passRate = 75;
          this.form.tries = 3;
          break;
      }
      this.$nextTick(() => (this.$refs.form as HTMLFormElement).validate());
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      this.form.eval = Object.assign({}, this.formDefaults.eval);
      this.form.limit = Object.assign({}, this.formDefaults.limit);
      this.form.limit.licence = Object.assign({}, this.formDefaults.limit.licence);
      this.form.limit.subject = Object.assign({}, this.formDefaults.limit.subject);
      this.form.limit.area = Object.assign({}, this.formDefaults.limit.area);
      this.form.limit.subArea = Object.assign({}, this.formDefaults.limit.subArea);
    },
    createBody() {
      const body = Object.assign({}, this.form);
      if (!isNumeric(body.timeLimit)) {
        delete body.timeLimit;
      }
      if (!isNumeric(body.dependentOn)) {
        delete body.dependentOn;
      }
      body.licences = body.licences.map(item => item.id);
      body.subjects = body.subjects.map(item => item.id);
      body.areas = body.areas.map(item => item.id);
      body.subAreas = body.subAreas.map(item => item.id);
      body.questions = body.questions.map(item => item.id);
      return body;
    },
    removeLicence(licence) {
      const i = this.form.licences.findIndex(s => s.id === licence.id);
      Vue.delete(this.form.licences, i);
    },
    removeSubject(subject) {
      this.form.subjects = this.form.subjects.filter(s => s.id !== subject.id);
    },
    removeArea(area) {
      this.form.areas = this.form.areas.filter(a => a.id !== area.id);
    },
    removeSubArea(subArea) {
      this.form.subAreas = this.form.subAreas.filter(sa => sa.id !== subArea.id);
    },
    removeQuestion(question) {
      this.form.questions = this.form.questions.filter(q => q.id !== question.id);
    },
    async addLicences(licencesIds) {
      const newLicenceIds = licencesIds.filter(id => !this.form.licences.find(s => s.id === id));
      const licences = Licence.findIn(newLicenceIds);
      const counts = await this.$store.dispatch('question/getCount', {type: 'licence', ids: licences.map(l => l.id)});
      Object.keys(counts).forEach(key => this.count.licence[key] = String(counts[key]));
      this.form.licences = this.form.licences.concat(licences);
    },
    async addSubjects(subjectsIds) {
      const newSubjectIds = subjectsIds.filter(id => !this.form.subjects.find(s => s.id === id));
      const subjects = Subject.query().whereIdIn(newSubjectIds).with('licence').all();
      const counts = await this.$store.dispatch('question/getCount', {type: 'subject', ids: subjects.map(l => l.id)});
      Object.keys(counts).forEach(key => this.count.subject[key] = String(counts[key]));
      this.form.subjects = this.form.subjects.concat(subjects);
    },
    async addAreas(areaIds) {
      const newAreaIds = areaIds.filter(id => !this.form.areas.find(s => s.id === id));
      const areas = Area.query().whereIdIn(newAreaIds).with('subject.licence').all();
      const counts = await this.$store.dispatch('question/getCount', {type: 'area', ids: areas.map(l => l.id)});
      Object.keys(counts).forEach(key => this.count.area[key] = String(counts[key]));
      this.form.areas = this.form.areas.concat(areas);
    },
    async addSubAreas(subAreaIds) {
      const newSubAreaIds = subAreaIds.filter(id => !this.form.subAreas.find(s => s.id === id));
      const subAreas = SubArea.query().whereIdIn(newSubAreaIds).with('area.subject.licence').all();
      const counts = await this.$store.dispatch('question/getCount', {type: 'subArea', ids: subAreas.map(l => l.id)});
      Object.keys(counts).forEach(key => this.count.subArea[key] = String(counts[key]));
      this.form.subAreas = this.form.subAreas.concat(subAreas);
    },
    addQuestions(questions) {
      const newQuestions = questions.filter(newQuestion => !this.form.questions.find(q => q.id === newQuestion.id));
      this.form.questions = this.form.questions.concat(newQuestions);
    },
    async saveTest() {
      try {
        const body = this.createBody();
        const payload = {id: this.$route.params.id, body};
        const test = await this.$store.dispatch('test/update', payload);
        await this.$store.dispatch('showSuccess', this.$t('msg.updateSuccess', {item: 'Test'}));
        await this.$router.push({name: 'test-details', params: {id: test.id}});
        this.formDefaults = Object.assign({}, this.form);
        this.reset();
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    async saveTestAsCopy() {
      try {
        const body = this.createBody();
        const test = await this.$store.dispatch('test/create', body);
        const msg = this.$t('msg.addSuccess', {item: this.$tc('p.test')});
        await this.$store.dispatch('showSuccess', msg);
        await this.$router.push({name: 'test-details', params: {id: test.id}});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
