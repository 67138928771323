import {Model} from '@vuex-orm/core';

export class TestQuestion extends Model {
  static entity = 'test_questions';

  static primaryKey = ['testId', 'questionId'];

  static fields() {
    return {
      testId: this.attr(null),
      questionId: this.attr(null),
    };
  }

  testId: string;
  questionId: string;
}
