

























import moment from 'moment';
import Vue from 'vue';
import {StorageManager} from '~/util';
import FlagIcon from './FlagIcon.vue';

export default Vue.extend({
  name: 'language-changer',
  components: {
    FlagIcon,
  },
  data() {
    return {
      languages: [
        {locale: 'de', name: 'Deutsch'},
        {locale: 'en', name: 'English'},
      ],
    };
  },
  methods: {
    changeLanguage(locale: string) {
      this.$i18n.locale = locale;
      moment.locale(locale);
      StorageManager.setLocale(locale);
    },
  },
});
