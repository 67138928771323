






















import Vue from 'vue';
import {getResponsiveClass} from '~/util';

export default Vue.extend({
  name: 'data-table',
  props: {
    headers: {type: Array, default: () => []},
    items: {type: Array, default: () => []},
    dense: {type: Boolean, default: false},
    loading: {type: Boolean, default: false},
    search: {type: String, default: ''},
    searchFilter: {type: Function, default: () => true},
    itemsPerPage: {type: Number, default: 10},
    sortBy: {type: String, default: 'updatedAt'},
    sortDesc: {type: Boolean, default: true},
  },
  data() {
    return {
      responsiveClass: '',
    };
  },
  mounted(): void {
    setTimeout(() => this.responsiveClass = getResponsiveClass(this.responsiveContainer.$el.clientWidth), 1);
  },
  computed: {
    responsiveContainer(): Vue {
      return this.$refs.responsiveContainer as Vue;
    },
  },
  methods: {
    onResize(): void {
      if (this.responsiveContainer) {
        this.responsiveClass = getResponsiveClass(this.responsiveContainer.$el.clientWidth);
      }
    },
  },
});
