













































































import Vue from 'vue';
import {HighChart} from '~/components/common';
import {Area, Licence, School, Student, Subject} from '~/models';

export default Vue.extend({
  name: 'weakpoint-analysis',
  components: {HighChart},
  data() {
    return {
      titleText: this.$t('label.weakpointAnalysis'),
      subTitleText: this.$tc('p.pool', 2),
      sort: {
        enabled: false,
        ascending: false,
        default: null,
      },
      ui: {
        percentage: false,
        stacking: false,
        advancedFilters: false,
      },
      menu: {
        startDate: false,
        endDate: false,
      },
      filter: {
        startDate: null,
        endDate: null,
        school: null,
        student: null,
        percentage: null,
        types: null,
        tries: null,
      },
      response: null,
      licence: null,
      subject: null,
      area: null,
      licenceIds: [],
      subjectIds: [],
      areaIds: [],
    };
  },
  async created() {
    try {
      await Promise.all([
        this.$store.dispatch('question/fetchLicences'),
        this.$store.dispatch('school/fetchOptions'),
        this.$store.dispatch('student/fetchStudentOptions'),
      ]);

    } catch (err) {
      await this.$store.dispatch('showError', err);
    }
  },
  computed: {
    typeItems(): any[] {
      return [
        {text: this.$t('label.progressTest'), value: 'progress'},
        {text: this.$t('label.finalTest'), value: 'final'},
        {text: this.$t('label.preExam'), value: 'preExam'},
        {text: this.$t('label.default'), value: 'default'},
      ];
    },
    tryItems(): any[] {
      return [
        {text: '1', value: 1},
        {text: '2', value: 2},
        {text: '3', value: 3},
        {text: '3+', value: 4},
      ];
    },
    options(): any {
      return {
        chart: {
          type: 'column',
        },
        title: {
          text: this.titleText,
        },
        subtitle: {
          text: this.subTitleText,
          html: true,
        },
        xAxis: {
          type: 'category',
          categories: this.response?.columns,
          labels: {
            formatter() {
              // eslint-disable-next-line
              // @ts-ignore
              return this.value.name;
            },
          },
        },
        yAxis: {
          title: {
            text: `${this.$tc('p.answer', 2)} (${this.answerCount})`,
          },
          reversedStacks: false,
        },
        plotOptions: {
          column: {
            stacking: this.ui.stacking ? this.ui.percentage ? 'percent' : 'normal' : undefined,
            events: {
              click: (event) => {
                switch (event.point.category.type) {
                  case 'licence':
                    this.licence = this.licences.find(l => l.id === event.point.category.id);
                    this.onLicenceChange();
                    break;
                  case 'subject':
                    this.subject = this.subjects.find(s => s.id === event.point.category.id);
                    this.onSubjectChange();
                    break;
                  case 'area':
                    this.area = this.areas.find(a => a.id === event.point.category.id);
                    this.onAreaChange();
                    break;
                }
              },
            },
          },
        },
        legend: {
          enabled: true,
        },
        series: [
          {
            name: `${this.$t('label.correct')} (${this.correctAnswerCount})`,
            color: 'green',
            data: this.response?.correctData || [],
          },
          {
            name: `${this.$t('label.incorrect')} (${this.incorrectAnswerCount})`,
            color: 'red',
            data: this.response?.incorrectData || [],
          },
        ],
        credits: {
          enabled: false,
        },
      };
    },
    schools(): any[] {
      return School.all();
    },
    students(): any[] {
      return this.filter.school ? Student.all().filter(s => s.schoolId === this.filter.school.id) : Student.all();
    },
    licences(): any[] {
      return Licence.all().filter(l => this.licenceIds.includes(l.id));
    },
    subjects(): any[] {
      return Subject.all().filter(s => this.subjectIds.includes(s.id) && s.licenceId === this.licence?.id);
    },
    areas(): any[] {
      return Area.all().filter(a => this.areaIds.includes(a.id) && a.subjectId === this.subject?.id);
    },
    answerCount(): number {
      return this.correctAnswerCount + this.incorrectAnswerCount;
    },
    correctAnswerCount(): number {
      return this.response?.correctData?.reduce((count, d) => count + d.y, 0) || 0;
    },
    incorrectAnswerCount(): number {
      return this.response?.incorrectData?.reduce((count, d) => count + d.y, 0) || 0;
    },
    instanceFilter(): any {
      const filter: any = {};
      if (this.filter.student) {
        filter.type = 'student';
        filter.id = this.filter.student.id;
      } else if (this.filter.school) {
        filter.type = 'school';
        filter.id = this.filter.school.id;
      }

      if (this.filter.startDate) {
        filter.startDate = this.filter.startDate;
      }
      if (this.filter.endDate) {
        filter.endDate = this.filter.endDate;
      }
      if (this.filter.percentage) {
        filter.percentage = this.filter.percentage;
      }
      if (this.filter.tries?.length > 0) {
        filter.tries = this.filter.tries;
      }
      if (this.filter.types?.length > 0) {
        filter.types = this.filter.types;
      }

      return filter;
    },
    section(): any {
      if (this.area) {
        return {type: 'area', id: this.area.id, subtitle: this.area.fullName, filter: this.instanceFilter};
      }
      if (this.subject) {
        return {type: 'subject', id: this.subject.id, subtitle: this.subject.fullName, filter: this.instanceFilter};
      }
      if (this.licence) {
        return {type: 'licence', id: this.licence.id, subtitle: this.licence.fullName, filter: this.instanceFilter};
      }
      return {type: 'all', subtitle: this.$tc('p.pool', 2), filter: this.instanceFilter};
    },
  },
  methods: {
    async onSchoolChange() {
      if (this.filter.school) {
        await this.$store.dispatch('school/fetchSchool', this.filter.school.id);
      }
      this.filter.student = null;
      await this.fetchData();
    },
    async onLicenceChange() {
      if (this.licence) {
        await this.$store.dispatch('question/fetchLicence', {licence: this.licence.id});
      }
      this.subject = null;
      this.area = null;
      await this.fetchData();
    },
    async onSubjectChange() {
      if (this.subject) {
        await this.$store.dispatch('question/fetchSubject', {subject: this.subject.id});
      }
      this.area = null;
      await this.fetchData();
    },
    async onAreaChange() {
      if (this.area) {
        await this.$store.dispatch('question/fetchArea', {area: this.area.id});
      }
      await this.fetchData();
    },
    getSubtitleText(): string {
      let subTitle = `<b>${this.section.subtitle}</b><br>`;
      if (this.section.filter.startDate) {
        subTitle += ` ${this.$t('label.startDate')}: ${this.section.filter.startDate};`;
      }
      if (this.section.filter.endDate) {
        subTitle += ` ${this.$t('label.endDate')}: ${this.section.filter.endDate};`;
      }
      if (this.section.filter.percentage) {
        subTitle += ` ${this.$t('label.minPercentage')}: ${this.section.filter.percentage}%;`;
      }
      if (this.section.filter.types?.length > 0) {
        subTitle += ` ${this.$t('label.type')}: ${this.section.filter.types.join(', ')};`;
      }
      if (this.section.filter.tries?.length > 0) {
        subTitle += ` ${this.$tc('p.try', 2)}: ${this.section.filter.tries.join(', ')};`;
      }
      return subTitle;
    },
    async fetchData() {
      this.response = await this.$store.dispatch('question/getWeakpointData', {
        id: this.section.id,
        type: this.section.type,
        filter: this.section.filter,
      });

      this.sort.default = {
        correctData: [].concat(this.response.correctData),
        incorrectData: [].concat(this.response.incorrectData),
        columns: [].concat(this.response.columns),
      };

      if (this.section.filter?.type) {
        const target = this.section.filter.type === 'school' ? this.filter.school?.name : this.filter.student?.fullName;
        this.titleText = this.$t('label.weakpointAnalysisFor', {target});
      } else {
        this.titleText = this.$t('label.weakpointAnalysis');
      }

      this.subTitleText = this.getSubtitleText();

      switch (this.section.type) {
        case 'all':
          this.licenceIds = this.response.sections?.map(s => s.id) || [];
          break;
        case 'licence':
          this.subjectIds = this.response.sections?.map(s => s.id) || [];
          break;
        case 'subject':
          this.areaIds = this.response.sections?.map(s => s.id) || [];
          break;
      }

      this.sortData();
    },
    sortData() {
      if (!this.response.correctData) {
        return;
      }

      if (!this.sort.enabled) {
        this.response.correctData = this.sort.default.correctData;
        this.response.incorrectData = this.sort.default.incorrectData;
        this.response.columns = this.sort.default.columns;
        return;
      }
      const ascendingSort = (a, b) => a.y - b.y;
      const descendingSort = (a, b) => b.y - a.y;
      let dataSet = this.response.correctData;

      if (this.ui.percentage) {
        dataSet = dataSet.map(p => {
          const idx = this.response.incorrectData.findIndex(p2 => p2.x === p.x);
          const totalAnswers = p.y + this.response.incorrectData[idx].y;
          return {...p, y: totalAnswers > 0 ? p.y / totalAnswers : 0};
        });
      }
      const sortedData = dataSet.sort(this.sort.ascending ? ascendingSort : descendingSort);

      const sortedColumns = [];
      sortedData.forEach((point, idx) => {
        this.response.correctData.find(p => p.x === point.x).newX = idx;
        this.response.incorrectData.find(p => p.x === point.x).newX = idx;
        sortedColumns.push(this.response.columns[point.x]);
      });
      this.response.columns = sortedColumns;

      this.response.correctData = this.response.correctData.map(p => ({...p, x: p.newX}));
      this.response.incorrectData = this.response.incorrectData.map(p => ({...p, x: p.newX}));
    },
  },
});
