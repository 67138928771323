import {Model} from '@vuex-orm/core';
import {Admin, AdminRole, Permission, RolePermission} from '~/models';

export class Role extends Model {
  static entity = 'roles';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
      admins: this.belongsToMany(Admin, AdminRole, 'roleId', 'adminId'),
      permissions: this.belongsToMany(Permission, RolePermission, 'roleId', 'permissionId'),
    };
  }

  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  admins: Admin[];
  permissions: Permission[];
}
