import {Module} from 'vuex';
import {breakpoint} from '~/util/constants';


export interface ScreenState {
  width: number,
  height: number,
  isXS: boolean,
  isSM: boolean,
  isMD: boolean,
  isLG: boolean,
  isXL: boolean,
}

export const screenModule: Module<ScreenState, any> = {
  namespaced: true,
  state: {
    width: 0,
    height: 0,
    isXS: false,
    isSM: false,
    isMD: false,
    isLG: false,
    isXL: false,
  },
  mutations: {
    updateScreenSize(state) {
      state.width = window.document.body.clientWidth;
      state.height = window.document.body.clientHeight;
      state.isXS = state.width < breakpoint.xs;
      state.isSM = state.width >= breakpoint.xs && state.width < breakpoint.sm;
      state.isMD = state.width >= breakpoint.sm && state.width < breakpoint.md;
      state.isLG = state.width >= breakpoint.md && state.width < breakpoint.lg;
      state.isXL = state.width >= breakpoint.lg;
    },
  },
  actions: {
    updateScreenSize: (context) => context.commit('updateScreenSize'),
  },
  getters: {
    screen: state => state,
  },
};
