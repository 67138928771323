




























import Vue from 'vue';
import { StorageManager } from '~/util';

export default Vue.extend({
  name: 'UserMenu',
  data() {
    return {};
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },
  methods: {
    async signOut() {
      await this.$store.dispatch('auth/signOut');
      window.location.replace('/');
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      StorageManager.setDarkTheme(this.$vuetify.theme.dark);
    },
  },
});
