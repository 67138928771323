




















import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {CourseDialog, CourseTable} from '~/components/course';

export default Vue.extend({
  components: {TooltipButton, CourseDialog, CourseTable},
  data() {
    return {
      addDialog: false,
    };
  },
  created() {
    document.title = `LTMS - Courses`;
  },
  computed: {
    search: {
      get(): string {
        return this.$store.state.course.search;
      },
      set(value: string) {
        this.$store.dispatch('course/updateSearch', value);
      },
    },
  },
});
