import * as coreModels from './core';
import * as questionModels from './question';
import * as relationalModels from './relational';
import * as progressTestModels from './test';

export * from './question';
export * from './test';
export * from './relational';
export * from './core';

export const models = [
  ...Object.values(questionModels),
  ...Object.values(progressTestModels),
  ...Object.values(relationalModels),
  ...Object.values(coreModels),
];
