import {Model} from '@vuex-orm/core';
import {Answer, TestInstance} from '~/models';

export class TestInstanceAnswer extends Model {
  static entity = 'test-instance_answers';

  static primaryKey = ['answerId', 'testInstanceId'];

  static fields() {
    return {
      testInstance: this.belongsTo(TestInstance, 'testInstanceId'),
      testInstanceId: this.attr(null),
      answer: this.belongsTo(Answer, 'answerId'),
      answerId: this.attr(null),
    };
  }

  testInstance: TestInstance;
  testInstanceId: number;
  answer: Answer;
  answerId: number;
}
