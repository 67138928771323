import {RuleGenerator} from '~/util/RuleGenerator';

export const adminConstraints = {
  firstName: {isRequired: true, maxLength: 60},
  lastName: {isRequired: true, maxLength: 60},
  email: {isRequired: true, maxLength: 60, isEmail: true},
  password: {isRequired: true, minLength: 6},
};

export function adminRules(this: any) {
  return RuleGenerator.generateRules.call(this, adminConstraints);
}
