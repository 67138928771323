import {Model} from '@vuex-orm/core';

export class StudentDocument extends Model {
  static entity = 'student_documents';

  static primaryKey = ['studentId', 'documentId'];

  static fields() {
    return {
      studentId: this.attr(null),
      documentId: this.attr(null),
    };
  }

  studentId: string;
  documentId: string;
}
