


































import Vue from 'vue';
import {DashboardDocuments, DashboardStudents} from '~/components/dashboard';

export default Vue.extend({
  components: {DashboardDocuments, DashboardStudents},
  created() {
    document.title = 'LTMS - Dashboard';
  },
});
