


























































import Vue from 'vue';
import {DataTable, DeleteDialog, StateChip, TooltipButton, TooltipDate} from '~/components/common';
import DocumentDialog from '~/components/document/DocumentDialog.vue';
import PreviewDialog from '~/components/document/PreviewDialog.vue';
import VersionUploadDialog from '~/components/document/VersionUploadDialog.vue';
import {Document} from '~/models';
import {ScreenState} from '~/store/screen.module';

export default Vue.extend({
  name: 'document-table',
  components: {TooltipDate, TooltipButton, DeleteDialog, PreviewDialog, DocumentDialog, DataTable, StateChip, VersionUploadDialog},
  props: {
    search: {type: String, default: ''},
    dense: {type: Boolean, default: false},
    documents: {type: Array, default: null},
    actionsWidth: {type: String, default: null},
    hideFields: {type: Array, default: () => []},
    actions: {type: Array, default: () => ['preview', 'upload', 'edit', 'delete']},
    itemsPerPage: {type: Number, default: 10},
  },
  data() {
    return {
      isLoading: false,
      edit: {dialog: false, document: null},
      remove: {dialog: false, document: null},
      upload: {dialog: false, document: null},
      preview: {dialog: false, url: null},
    };
  },
  async created() {
    if (!this.documents) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('document/fetchDocuments');
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
      this.isLoading = false;
    }
  },
  computed: {
    items(): any[] {
      return this.documents || Document.query().withAll().all();
    },
    additionalActions(): any[] {
      return this.actions.filter((action: any) => action && action.icon && action.tooltip && action.emit);
    },
    headers(): any[] {
      const fields = [
        {text: this.$t('label.title'), value: 'title'},
        {text: this.$t('label.latestVersion'), value: 'latest-version', sortable: false, class: 'sm-and-up'},
        {text: this.$t('label.state'), value: 'status', class: 'sm-and-up'},
        {text: this.$t('label.downloads'), value: 'downloads', align: 'end', class: 'md-and-up'},
        {text: this.$tc('p.version', 2), value: 'versions', align: 'end', class: 'lg-and-up'},
        {text: this.$tc('p.course', 2), value: 'courses', align: 'end', class: 'lg-and-up'},
        {text: this.$t('label.updatedAt'), value: 'updatedAt', align: 'end', class: 'md-and-up'},
        {text: this.$t('label.createdAt'), value: 'createdAt', align: 'end', class: 'lg-and-up'},
        {text: this.$t('label.actions'), value: 'actions', sortable: false, align: 'center', width: this.actionsWidth || this.actions.length * 52 + 'px'},
      ];
      return fields.filter(field => !this.hideFields.includes(field.value));
    },
    screen(): ScreenState {
      return this.$store.state.screen;
    },
  },
  methods: {
    searchFilter(documents, search, document) {
      const searchTerms = search.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result &&
          (document.title.toLowerCase().includes(term) || document.versions.reduce((result, version) => result || version.filename.toLowerCase().includes(term), false)), true);
    },
    showUploadDialog(document) {
      this.upload.document = document;
      this.upload.dialog = true;
    },
    showPreviewDialog(version) {
      this.preview.url = version.url;
      this.preview.dialog = true;
    },
    showEditDialog(document) {
      this.edit.dialog = true;
      this.edit.document = document;
    },
    showDeleteDialog(document) {
      this.remove.dialog = true;
      this.remove.document = document;
    },
    showField(field: string) {
      return !this.hideFields.includes(field);
    },
    async deleteDocument() {
      try {
        await this.$store.dispatch('document/deleteDocument', this.remove.document);
        this.remove.document = null;
        const msg = this.$t('msg.deleteSuccess', {item: this.$tc('p.document')});
        await this.$store.dispatch('showSuccess', msg);
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
