

































import Vue from 'vue';
import {TranslateResult} from 'vue-i18n';

export default Vue.extend({
  name: 'area-dialog',
  props: {
    value: {type: Boolean, default: false},
    area: {type: Object, default: null},
    subjectId: {type: Number, default: null},
  },
  data() {
    return {
      isValid: true,
      formDefaults: null,
      form: {
        name: '',
        number: '',
      },
    };
  },
  watch: {
    area: {
      immediate: true,
      handler() {
        this.formDefaults = this.area ? {name: this.area.name, number: this.area.number} : {name: '', number: ''};
        this.reset();
      },
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    headline(): TranslateResult {
      return this.area ? this.$t('action.editItem', {item: this.$tc('p.area')}) : this.$t('action.createItem', {item: this.$tc('p.area')});
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      try {
        if (this.area) {
          const payload = {id: this.area.id, data: this.form};
          await this.$store.dispatch('question/updateArea', payload);
          await this.$store.dispatch('showSuccess', this.$t('msg.updateSuccess', {item: this.$tc('p.area')}));
          this.$emit('update');
        } else {
          const payload = {subjectId: this.subjectId, data: this.form};
          await this.$store.dispatch('question/createArea', payload);
          await this.$store.dispatch('showSuccess', this.$t('msg.addSuccess', {item: this.$tc('p.area')}));
        }
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
