import {Model} from '@vuex-orm/core';
import {Student, Test} from '~/models';

export class ExtraTryTests extends Model {
  static entity = 'extra-try-test_students';

  static primaryKey = ['testId', 'studentId'];

  static fields() {
    return {
      studentId: this.attr(null),
      testId: this.attr(null),
      student: this.belongsTo(Student, 'studentId'),
      test: this.belongsTo(Test, 'testId'),
    };
  }

  testId: number;
  test: Test;
  studentId: string;
  student: Student;
}
