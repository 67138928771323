


















































import Vue from 'vue';
import {TranslateResult} from 'vue-i18n';
import {Permission, Role} from '~/models';
import {adminConstraints, adminRules} from './admin.rules';

export default Vue.extend({
  name: 'AdminDialog',
  props: {
    value: {type: Boolean, default: false},
    admin: {type: Object, default: null},
  },
  data() {
    return {
      isLoading: false,
      isValid: true,
      formDefaults: null,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        roles: [],
        permissions: [],
      },
      rules: adminRules.call(this),
      constraint: adminConstraints,
    };
  },
  created() {
    this.setFormDefaults();
  },
  watch: {
    async value(value) {
      if (value) {
        this.isLoading = true;
        await Promise.all([
          this.$store.dispatch('role/fetchRoleOptions'),
          this.$store.dispatch('permission/fetchPermissions'),
        ]);
        if (this.admin) {
          this.setFormDefaults();
        } else {
          this.reset();
        }
        this.isLoading = false;
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    headline(): TranslateResult {
      return this.admin ? this.$t('action.editItem', {item: this.$tc('p.admin')}) : this.$t('action.createItem', {item: this.$tc('p.admin')});
    },
    roles(): Role[] {
      return Role.all();
    },
    permissions(): Permission[] {
      return Permission.all();
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      try {
        let msg;
        if (this.admin) {
          const data = this.form;
          delete data.password;
          await this.$store.dispatch('admin/updateAdmin', {id: this.admin.id, data});
          msg = this.$t('msg.updateSuccess', {item: this.$tc('p.admin')});
        } else {
          await this.$store.dispatch('admin/createAdmin', this.form);
          msg = this.$t('msg.addSuccess', {item: this.$tc('p.admin')});
        }
        await this.$store.dispatch('showSuccess', msg);
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', this.getError(err));
      }
    },
    getError(err) {
      return err;
    },
    setFormDefaults() {
      if (this.admin) {
        this.formDefaults = {
          firstName: this.admin.firstName,
          lastName: this.admin.lastName,
          email: this.admin.email,
          roles: this.admin.roles.map(role => role.id),
          permissions: this.admin.permissions.map(permission => permission.id),
        };
      } else {
        this.formDefaults = Object.assign({}, this.form);
      }
      this.reset();
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
