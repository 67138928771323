




















import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {PermissionDialog, PermissionTable} from '~/components/permission';

export default Vue.extend({
  components: {TooltipButton, PermissionTable, PermissionDialog},
  data() {
    return {
      addDialog: false,
    };
  },
  created() {
    document.title = `LTMS - Admins`;
  },
  computed: {
    search: {
      get(): string {
        return this.$store.state.permission.search;
      },
      set(value: string) {
        this.$store.dispatch('permission/updateSearch', value);
      },
    },
  },
});
