








import Vue from 'vue';

export default Vue.extend({
  created() {
    document.title = 'LTMS - 404 Not Found';
  },
});
