























import Vue from 'vue';
import {getResponsiveClass} from '~/util';

export default Vue.extend({
  name: 'data-table',
  props: {
    action: {type: String},
    headers: {type: Array, default: () => []},
    dense: {type: Boolean, default: false},
    search: {type: String, default: ''},
    searchFilter: {type: Function, default: () => true},
    itemsPerPage: {type: Number, default: 10},
    sortBy: {type: String, default: 'updatedAt'},
    sortDesc: {type: Boolean, default: true},
    model: {default: null},
  },
  data() {
    return {
      isLoading: false,
      results: [],
      responsiveClass: '',
      serverItemsLength: -1,
      options: {
        itemsPerPage: this.itemsPerPage,
        page: 1,
      },
    };
  },
  mounted(): void {
    setTimeout(() => this.responsiveClass = getResponsiveClass(this.responsiveContainer.$el.clientWidth), 1);
  },
  computed: {
    responsiveContainer(): Vue {
      return this.$refs.responsiveContainer as Vue;
    },
    items(): any[] {
      // @ts-ignore TODO
      return this.model.query().whereIdIn(this.results).withAll().all();
    },
  },
  methods: {
    onResize(): void {
      if (this.responsiveContainer) {
        this.responsiveClass = getResponsiveClass(this.responsiveContainer.$el.clientWidth);
      }
    },
    async fetchData() {
      const options = {
        ...this.options,
        search: this.search,
      };
      this.isLoading = true;
      let response;
      try {
        response = await this.$store.dispatch(this.action, options);
        // @ts-ignore TODO
        this.model.insertOrUpdate({data: response.data});
        this.serverItemsLength = response.count;
        this.results = response.data.map(item => item.id);
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
      this.isLoading = false;
    },
  },
  watch: {
    async search() {
      this.options.page = 1;
      await this.fetchData();
    },
    options: {
      async handler() {
        try {
          await this.fetchData();
        } catch (error) {
          console.log('fetchData()', error);
        }
      },
      deep: true,
    },
  },
});
