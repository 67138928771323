export const en = {
  nav: {
    dashboard: 'Dashboard',
    documents: 'Documents',
    students: 'Users',
    courses: 'Courses',
    schools: 'Flightschools',
    questions: 'Questions',
    tests: 'Tests',
    testGroups: 'Test Groups',
    analysis: 'Analysis',
    progressTracker: 'Progress Tracker',
    administration: 'Administration',
    admins: 'Admins',
    roles: 'Roles',
    permissions: 'Permissions',
    testInstances: 'Test Instances',
  },
  label: {
    noEndDate: 'No Enddate',
    firstName: 'Vorname',
    lastName: 'Nachname',
    optional: 'Optional',
    mandatory: 'Mandatory',
    feedback: 'Feedback',
    endsAt: 'Ends at',
    lastLogin: 'Last Login',
    noFlightSchoolAssigned: 'No Flight School Assigned',
    locked: 'Locked',
    available: 'Available',
    attempted: 'Open',
    submittedAt: 'Submitted at',
    lastResult: 'Last Result',
    status: 'Status',
    testStartFrom: 'Training Start Date',
    testEndDate: 'Training End Date',
    points: 'Points',
    tries: 'Tries',
    userType: 'User Type',
    isCustomQuestion: 'CAT Question',
    insight: 'Insight',
    granted: 'Granted',
    denied: 'Denied',
    archived: 'Archived',
    limit: 'Limit',
    isCorrect: 'Is Correct',
    unlimited: 'Unlimited',
    result: 'Result',
    percentage: 'Percentage',
    duration: 'Duration',
    all: 'All',
    language: 'Language',
    text: 'Text',
    test: 'Test',
    number: 'Number',
    author: 'Author',
    id: 'ID',
    name: 'Name',
    icon: 'Icon',
    displayName: 'Display Name',
    login: 'Login Name',
    surname: 'Last Name',
    forename: 'First Name',
    password: 'Password',
    username: 'Username',
    email: 'Email',
    subject: 'Subject',
    message: 'Message',
    classes: 'Classes',
    phoneNr: 'Telephone',
    versionName: 'Version Name',
    search: 'Search',
    state: 'Status',
    title: 'Title',
    logo: 'Logo',
    filename: 'File Name',
    file: 'File',
    downloads: 'Downloads',
    description: 'Description',
    active: 'Active',
    inactive: 'Inactive',
    actions: 'Actions',
    createdAt: 'Created',
    finishedAt: 'Finished',
    updatedAt: 'Modified',
    uploadedAt: 'Uploaded',
    none: 'None',
    notify: 'notify via Email',
    displayLogo: 'Display logo',
    isDuplex: 'Is duplex',
    latestVersion: 'Latest Version',
    schoolLogo: 'Flight School Logo',
    validFrom: 'Valid from',
    validUntil: 'Valid until',
    initKey: 'Initialization key',
    dependentOn: 'Dependent On',
    dependants: 'Dependants',
    passRate: 'Pass Rate',
    preExamPassRate: 'Pre-Exam Pass Rate',
    timeLimit: 'Time Limit',
    passed: 'Passed',
    notPassed: 'Not yet Passed',
    deliveredTests: 'Delivered Tests',
    givenAnswer: 'Given Answer',
    assignedItems: 'Assigned {items}',
    directlyAssignedTests: 'Directly assigned tests',
    evaluation: 'Evaluation',
    coolDown: 'Cooldown Timer',
    type: 'Type',
    hours: 'hrs',
    attention: 'Attention',
    default: 'Default',
    preExam: 'Pre-Exam',
    finalTest: 'Final Test',
    progressTest: 'Progress Test',
    correct: 'Correct',
    incorrect: 'Incorrect',
    weakpointAnalysis: 'Weakpoint Analysis',
    weakpointAnalysisFor: 'Weakpoint Analysis for {target}',
    startDate: 'Start Date',
    endDate: 'End Date',
    minPercentage: 'Min. Percentage',
    advancedFilters: 'Advanced Filters',
    stacking: 'Stacking',
    percentaged: 'Percentaged',
    sort: 'Sort',
    ascending: 'Ascending',
    authorized: 'Authorized',
    unauthorized: 'Unauthorized',
    protected: 'Protected',
    activationCode: 'Activation-Code',
    explanation: 'Explanation',
  },
  p: {
    none: 'none | none',
    permission: 'Permission | Permissions',
    role: 'Role | Roles',
    admin: 'Admin | Admins',
    point: 'Point | Points',
    database: 'Database | Databases',
    evalPool: 'Evaluation Pool | Evaluation Pools',
    pool: 'Pool | Pools',
    testInstance: 'Test Instance | Test Instances',
    extraTry: 'Extra Try | Extra Tries',
    student: 'User | Users',
    school: 'Flight School | Flight Schools',
    schoolClass: 'School Class | School Classes',
    course: 'Course | Courses',
    document: 'Document | Documents',
    version: 'Version | Versions',
    device: 'Device | Devices',
    slot: 'Slot | Slots',
    try: 'Try | Tries',
    licence: 'Licence | Licences',
    subject: 'Subject | Subjects',
    area: 'Area | Areas',
    subArea: 'Sub Area | Sub Areas',
    answer: 'Answer | Answers',
    question: 'Question | Questions',
    explanation: 'Explanation | Explanations',
    figure: 'Figure | Figures',
    explanationFigure: 'Explanation Figure | Explanation Figures',
    correctAnswer: 'Correct Answer | Correct Answers',
    minute: 'Minute | Minutes',
    image: 'Image | Images',
    test: 'Test | Tests',
    preExam: 'Pre-Exam | Pre-Exams',
    progressTest: 'Progress-Test | Progress-Tests',
    finalTest: 'Final-Test | Final-Tests',
    testGroup: 'Test Group | Test Groups',
    staticQuestion: 'Static Question | Static Questions',
  },
  action: {
    back: 'Back',
    reset: 'Reset',
    submit: 'Submit',
    save: 'Save',
    cancel: 'Cancel',
    close: 'Close',
    delete: 'Delete',
    edit: 'Edit',
    add: 'Add',
    create: 'Create',
    upload: 'Upload',
    signIn: 'Sign In',
    recalculateResults: 'Recalculate Results',
    grantInsight: 'Grant Insight to the user',
    extraTry: 'Give extra try',
    removeExtraTry: 'Remove extra try',
    showItem: 'Show {item}',
    hideItem: 'Hide {item}',
    prevItem: 'Previous {item}',
    nextItem: 'Next {item}',
    activateItem: 'Activate {item}',
    archiveItem: 'Archive {item}',
    deleteItem: 'Delete {item}',
    removeItem: 'Remove {item}',
    importItem: 'Import {item}',
    editItem: 'Edit {item}',
    createItem: 'Create {item}',
    addItem: 'Add {item}',
    assignItem: 'Assign {item}',
    assignItemToTarget: 'Assign {item} to {target}',
    generateItem: 'Generate {item}',
    authorizeItem: 'Authorize {item}',
    exportItem: 'Export {item}',
    confirm: 'Confirm',
    apply: 'Apply',
    saveAsCopy: 'Save as copy',
    select: 'Select',
    selectFile: 'Select File',
    uploadFile: 'Upload File',
    changeLanguage: 'Change Language',
    logout: 'Logout',
    search: 'Search',
    enableDarkTheme: 'Enable Dark Theme',
    disableDarkTheme: 'Disable Dark Theme',
    setAppVersion: 'Set App Version',
    generatePassword: 'Generate Password',
    importStudents: 'Import multiple users through CSV-file',
    assignTests: 'Assign Tests',
    changeUserPassword: 'Change User Password',
    generateSelectedPasswords: 'Generate passwords for selected users',
    showPreview: 'Open Preview',
    addDocToBlacklist: 'Deny document access for user',
    removeDocFromBlacklist: 'Grant document access for user',
    previewVersion: 'Open Preview',
    uploadVersion: 'Upload a new Version',
    enableZoom: 'Enable Zoom',
    disableZoom: 'Disable Zoom',
    manageItem: 'Manage {item}',
    printResults: 'Print Results',
    showUserDetails: 'Show User Details',
  },
  hint: {
    versionUpload: 'Please select a .pdf file',
    csvUpload: 'Valid CSV Headers: Nachname, Vorname, E-Mail, Login, Passwort',
    documentOverview: 'Accessible / Distinct / Total',
    initKey: 'You\'ll find the initialization key in server logs.',
    schoolLogo: 'Max. Image size: 1MB. Supported formats: png, jpeg, svg.',
    itemsSelected: '{count} {item} selected',
    itemsOfTotalSelected: '{count} of {total} {item} selected',
  },
  msg: {
    confirm: {
      removeQuestion: 'Are you sure you want to remove {question} from "{section}" and all related sub-categories?',
    },
    confirmExtraTry: {
      title: 'Confirm Extra Try',
      text: 'This Pre-Exam has previously been passed. Please confirm, that this student should take this Pre-Exam again.',
    },
    noAnswerGiven: 'No answer given.',
    additionalTryGiven: 'An additional try was given to the user.',
    noQuestionsFound: 'No questions found for your search request.',
    archiveSuccess: '{item} was archived successfully.',
    activateSuccess: '{item} was activated successfully.',
    addSuccess: '{item} was added successfully.',
    updateSuccess: '{item} was updated successfully.',
    studentImportSuccess: 'The user was imported successfully. | All {amount} users were imported successfully.',
    deleteSuccess: '{item} was deleted successfully.',
    confirmDeletion: 'Be aware that the deletion of this element could cause unexpected consequences of the integrity of the data-structure',
    uploadSuccess: '{item} was uploaded successfully.',
    importSuccess: '{item} have been imported successfully.',
    passwordChangeSuccess: 'Password changed successfully.',
    passwordChangeNotifySuccess: 'Password changed successfully, and user has been notified at "{email}".',
  },
  error: {
    searchQuestionCondition: 'Searches across all licences are currently not supported. Please select a licence first.',
    unexpected: 'An unexpected error occurred: {error}',
    alreadyInUse: '{item} "{value}" is already in use.',
    isInvalid: '{field} is invalid.',
    emailOrLoginInUse: 'E-Mail or Login is already in use.',
  },
  form: {
    isRequired: '{field} is mandatory.',
    maxLength: '{field} may not be longer than {max} characters.',
    minLength: '{field} must contain at least {min} characters.',
    length: '{field} need to have a length of {length} characters.',
    isInvalidEmail: '{field} is not a valid email address.',
    isNumeric: '{field} needs to be a numeric value.',
    minValue: '{field} needs to be at least {val}.',
    maxValue: '{field} needs be max {val}.',
    hasNoPdfFileEnding: 'The filename needs to end with ".pdf".',
  },
  page: {
    initialSetup: {
      headline: 'Initial Setup',
      text: `Welcome to the LunaTech Mentoring System.<br/><br/>
Please enter the user data of the Administrator.
Be mindful of correctly filling all fields,
because false entries could necessitate a reset of the user database.`,
    },
    signIn: {
      headline: 'Please sign in',
      submit: 'Submit',
    },
    dashboard: {
      headline: 'Dashboard',
      documents: 'There are <strong>no documents</strong> available. | There is <strong>one document</strong> available. | There are <strong>{documentCount} documents</strong> available,<br/>which have been downloaded <b>{totalDownloads} times</b> in total.',
      students: 'There are <strong>no users</strong> available. | There is <strong>one user</strong> available. | There are <strong>{count} users</strong> available.',
      courses: 'There are <strong>no courses</strong> available. | There is <strong>one course</strong> available. | There are <strong>{count} courses</strong> available.',
      schools: 'There are <strong>no schools</strong> available. | There is <strong>one school</strong> available. | There are <strong>{count} schools</strong> available.',
    },
    documents: {
      headline: 'Overview of Documents',
    },
    students: {
      headline: 'User Administration',
    },
  },
  search: {
    noResults: 'Your search for "{search}" produced no results.',
  },
  language: {
    german: 'German',
    english: 'English',
  },
  user: {
    presence: 'Presence Learning',
    distance: 'Distance Learning',
    staff: 'Staff',
    script: 'Script User',
  },
  table: {
    noData: 'No entries available.',
  },
  pagination: {
    pageText: 'Entries from {start} to {stop} of {length}',
    rowsPerPage: 'Rows per Page',
  },
};
