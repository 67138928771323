




























import Vue from 'vue';
import {Answer, Question} from '~/models';

export default Vue.extend({
  name: 'answer-dialog',
  props: {
    value: {type: Boolean, default: false},
    instance: {type: Object},
    questionId: {type: Number},
  },
  data() {
    return {
      isValid: false,
      form: {
        answer: null,
      },
    };
  },
  watch: {
    value(value) {
      if (value && this.question) {
        const answer = this.instance.answers.find(a => a.questionId === this.question.id);
        if (answer) {
          this.form.answer = answer.id;
        }
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    question(): Question {
      return this.instance.questions.find(q => q.id === this.questionId);
    },
    answer(): Answer {
      return this.instance.answers.find(q => q.questionId === this.questionId);
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }
      try {
        await this.$store.dispatch('test/editInstanceAnswer', {
          instanceId: this.instance.id,
          answerId: this.form.answer,
          questionId: this.questionId,
        });
        await this.$store.dispatch('showSuccess', this.$t('msg.updateSuccess', {item: this.$tc('p.answer')}));
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
