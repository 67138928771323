import {Model} from '@vuex-orm/core';
import {Student} from './index';

export class StudentDevice extends Model {
  static entity = 'students-devices';

  static fields() {
    return {
      id: this.attr(null),
      identifier: this.attr(''),
      key: this.attr(''),
      student: this.belongsTo(Student, 'studentId'),
      // foreignKeys
      studentId: this.attr(null),
    };
  }

  id: string;
  identifier: string;
  key: string;
  student: Student;
}
