









import Vue from 'vue';
import {date, datetime, fromNow} from '../../util/filters';

export default Vue.extend({
  name: 'TooltipDate',
  props: {
    value: {type: [String, Date]},
    display: {type: String, default: 'date'},
    tooltip: {type: String, default: 'fromNow'},
  },
  computed: {
    computedDisplay(): string {
      if (!this.value) {
        return '-';
      }
      const filter = this.getFilter(this.display);
      return filter(this.value);
    },
    computedTooltip(): string {
      const filter = this.getFilter(this.tooltip);
      return filter(this.value);
    },
  },
  methods: {
    getFilter(input) {
      switch (input) {
        case 'date':
          return date;
        case 'datetime':
          return datetime;
        case 'fromNow':
          return fromNow;
      }
      return date;
    },
  },
});
