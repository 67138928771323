import {Module} from 'vuex';
import {
  Course, CourseStudent, Document, School, Student, StudentBlacklistDocument, StudentDevice, StudentDocument, StudentTestState, TestGroupStudent, TestStudent,
} from '~/models';
import {api} from '~/util/api';

const UPDATE_THRESHOLD = 60000; // 60 sek

export interface StudentState {
  search: string,
  total: number,
  fetchedAt: number,
  optionsFetchedAt: number,
  studentFetchedAt: { [id: number]: number },
  dialogOptionsFetchedAt: number,
}

export const studentModule: Module<StudentState, any> = {
  namespaced: true,
  state: {
    search: '',
    total: 0,
    fetchedAt: 0,
    optionsFetchedAt: 0,
    studentFetchedAt: {},
    dialogOptionsFetchedAt: 0,
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
    SET_TOTAL_STUDENTS(state, total) {
      state.total = total;
    },
  },
  actions: {
    updateSearch: (context, search) => {
      context.commit('SET_SEARCH', search);
    },
    createStudent: async (context, payload) => {
      const response = await api.post(`/api/student`, payload);
      await Student.insert({data: response.data});
    },
    updateStudentPassword: async (context, payload) => {
      await api.put(`/api/student/${payload.id}/change-password`, payload.data);
    },
    updateStudent: async (context, payload) => {
      const response = await api.put(`/api/student/${payload.id}`, payload.data);
      await CourseStudent.delete(cs => cs.studentId === payload.id);
      await StudentDocument.delete(cs => cs.studentId === payload.id);
      await Student.insert({data: response.data});
    },
    fetchStudents: async (context) => {
      if (Date.now() - context.state.fetchedAt > UPDATE_THRESHOLD) {
        context.state.fetchedAt = Date.now();
        const response = await api.get('/api/student');
        await Student.insert({data: response.data});
      }
    },
    fetchStudentsPaginated: async (context, options) => {
      const response = await api.get('/api/student/students', {
        params: {
          page: options?.page || 1,
          limit: options?.itemsPerPage || 10,
          sortBy: options?.sortBy[0] || 'updatedAt',
          sortDesc: options?.sortDesc[0],
          search: options?.search,
        },
      });

      await Student.insert({data: response.data.data.map(record => Object.assign({}, record))});
      context.commit('SET_TOTAL_STUDENTS', response.data.count);
      return response.data;
    },
    fetchProgressTrackerData: async (context, filter) => {
      const params: any = {};
      if (filter.userType !== 'all') {
        params.type = filter.userType;
      }
      if (filter.schoolId && filter.schoolId !== '-1') {
        params.schoolId = filter.schoolId;
      }
      if (filter.studentId && filter.studentId !== '-1') {
        params.studentId = filter.studentId;
      }
      const response = await api.get('/api/student/progress-tracker', {params});
      await Student.insertOrUpdate({data: response.data});
      return response.data;
    },
    fetchStudent: async (context, id) => {
      if (!context.state.studentFetchedAt[id] || Date.now() - context.state.studentFetchedAt[id] > UPDATE_THRESHOLD) {
        context.state.studentFetchedAt[id] = Date.now();
        const response = await api.get(`/api/student/${id}`);
        await Student.insert({data: response.data});
      }
    },
    fetchDialogOptions: async (context) => {
      if (Date.now() - context.state.dialogOptionsFetchedAt > UPDATE_THRESHOLD) {
        context.state.dialogOptionsFetchedAt = Date.now();
        const response = await api.get('/api/student/dialog-options');
        await Promise.all([
          Course.insertOrUpdate({data: response.data.courses}),
          Document.insertOrUpdate({data: response.data.documents}),
          School.insertOrUpdate({data: response.data.schools}),
        ]);
      }
    },
    fetchStudentOptions: async (context, options = {insert: true}) => {
      if (!options.insert) {
        const response = await api.get('/api/student/options');
        return response.data;
      } else if (Date.now() - context.state.optionsFetchedAt > UPDATE_THRESHOLD) {
        context.state.optionsFetchedAt = Date.now();
        const response = await api.get('/api/student/options');
        await Student.insertOrUpdate({data: response.data});
      }
    },
    deleteStudent: async (context, id) => {
      await api.delete(`/api/student/${id}`);
      await Student.delete(id);
    },
    uploadStudentCSV: async (context, file) => {
      const formData = new FormData();
      formData.append('file', file);
      const uploadConfig = {headers: {'Content-Type': 'multipart/form-data'}};
      const response = await api.post('/api/student/csv', formData, uploadConfig);
      return response.data;
    },
    importStudents: async (context, students) => {
      const response = await api.post('/api/student/import', students);
      await Student.insert({data: response.data});
      return response.data;
    },
    removeDevice: async (context, device) => {
      await api.delete(`/api/student/device/${device.id}`);
      await StudentDevice.delete(device.id);
    },
    removeFromSchool: async (context, student) => {
      const updatedStudent = await api.delete(`/api/student/${student.id}/school`);
      await Student.insert(updatedStudent);
    },
    addToBlacklist: async (context, payload) => {
      await api.put(`/api/student/${payload.studentId}/blacklist/add/document/${payload.documentId}`);
      await StudentBlacklistDocument.insert({data: payload});
    },
    removeFromBlacklist: async (context, payload) => {
      await api.put(`/api/student/${payload.studentId}/blacklist/remove/document/${payload.documentId}`);
      await StudentBlacklistDocument.delete([payload.studentId, payload.documentId]);
    },
    assignTests: async (context, payload) => {
      await api.put(`/api/student/${payload.studentId}/assign-tests`, payload.body);
      await TestStudent.delete(ts => ts.studentId === payload.studentId);
      const testRelations = payload.body.tests.map(testId => ({studentId: payload.studentId, testId: testId}));
      await TestStudent.insert({data: testRelations});

      await TestGroupStudent.delete(ts => ts.studentId === payload.studentId);
      const testGroupRelations = payload.body.testGroups.map(testGroupId => ({studentId: payload.studentId, testGroupId: testGroupId}));
      await TestGroupStudent.insert({data: testGroupRelations});
    },
    authorizeTest: async (context, payload) => {
      await api.put(`/api/student/${payload.studentId}/authorize-test/${payload.testId}`, payload.form);
      await StudentTestState.insertOrUpdate({
        data: {
          studentId: payload.studentId,
          testId: payload.testId,
          state: payload.form.state,
          password: payload.form.password,
          autoInsight: payload.form.autoInsight,
        },
      });
    },
  },
  getters: {},
};
