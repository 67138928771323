
















import Vue from 'vue';

export default Vue.extend({
  name: 'tooltip-button',
  props: {
    icon: {type: String, default: 'menu'},
    tooltip: {type: String, default: null},
    fab: {type: Boolean, default: false},
    small: {type: Boolean, default: false},
    color: {type: String, default: ''},
    bottom: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
  },
});
