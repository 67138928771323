









import Vue from 'vue';

export default Vue.extend({
  props: {
    of: {type: Array, required: true},
    to: {type: String, required: true},
  },
  data() {
    return {
      items: this.of,
    };
  },
  methods: {
    getName(item) {
      return item.name || item.title || item.email;
    },
  },
});
