import {Module} from 'vuex';
import {Course, CourseDocument} from '~/models';
import {api} from '~/util/api';

const UPDATE_THRESHOLD = 60000; // 60 sek

export interface CourseState {
  search: string,
  fetchedAt: number,
  optionsFetchedAt: number,
  courseFetchedAt: { [id: number]: number },
}

export const courseModule: Module<CourseState, any> = {
  namespaced: true,
  state: {
    search: '',
    fetchedAt: 0,
    optionsFetchedAt: 0,
    courseFetchedAt: {},
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
  },
  actions: {
    updateSearch: async (context, search) => {
      context.commit('SET_SEARCH', search);
    },
    fetchCourses: async (context) => {
      if (Date.now() - context.state.fetchedAt > UPDATE_THRESHOLD) {
        context.state.fetchedAt = Date.now();
        const response = await api.get('/api/course');
        await Course.insert({data: response.data});
      }
    },
    fetchCourseOptions: async (context) => {
      if (Date.now() - context.state.optionsFetchedAt > UPDATE_THRESHOLD) {
        context.state.optionsFetchedAt = Date.now();
        const response = await api.get('/api/course/options');
        await Course.insertOrUpdate({data: response.data});
      }
    },
    fetchCourse: async (context, id) => {
      if (!context.state.courseFetchedAt[id] || Date.now() - context.state.courseFetchedAt[id] > UPDATE_THRESHOLD) {
        context.state.courseFetchedAt[id] = Date.now();
        const response = await api.get(`/api/course/${id}`);
        await Course.insert({data: response.data});
      }
    },
    updateCourse: async (context, payload) => {
      const response = await api.put(`/api/course/${payload.id}`, payload.data);
      await CourseDocument.delete(cd => cd.courseId === payload.id);
      await Course.insert({data: response.data});
    },
    createCourse: async (context, payload) => {
      const response = await api.post(`/api/course`, payload);
      await Course.insert({data: response.data});
    },
    deleteCourse: async (context, payload) => {
      await api.delete(`/api/course/${payload.id}`);
      await Course.delete(payload.id);
    },
  },
  getters: {},
};
