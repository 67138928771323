import {RuleGenerator} from '~/util/RuleGenerator';

export const testConstraints = {
  limit: {isRequired: true, minValue: 1},
  title: {isRequired: true, maxLength: 100},
  displayName: {isRequired: true, maxLength: 100},
  tries: {isRequired: true, isNumeric: true, minValue: 1},
  passRate: {isRequired: true, isNumeric: true, minValue: 0, maxValue: 100},
  timeLimit: {isNumeric: true, minValue: 0},
  coolDown: {isRequired: true, isNumeric: true, minValue: 0},
};

export function testRules(this: any) {
  return RuleGenerator.generateRules.call(this, testConstraints);
}
