import {Model} from '@vuex-orm/core';
import {School, SchoolTestGroup, Student, Test, TestGroupStudent, TestGroupTest} from '..';

export class TestGroup extends Model {
  static entity = 'test-groups';

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(''),
      state: this.attr(null),

      tests: this.belongsToMany(Test, TestGroupTest, 'testGroupId', 'testId'),
      students: this.belongsToMany(Student, TestGroupStudent, 'testGroupId', 'studentId'),
      schools: this.belongsToMany(School, SchoolTestGroup, 'testGroupId', 'schoolId'),

      createdAt: this.attr(''),
      updatedAt: this.attr(''),
    };
  }

  id: number;
  title: string;
  state: string;
  tests: Test[];
  students: Student[];
  schools: School[];

  createdAt: string;
  updatedAt: string;
}
