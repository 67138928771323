























import Vue from 'vue';
import {DataTable, DeleteDialog, TooltipButton} from '~/components/common';
import PermissionDialog from '~/components/permission/PermissionDialog.vue';
import {Permission} from '~/models';

export default Vue.extend({
  name: 'PermissionTable',
  components: {TooltipButton, DeleteDialog, DataTable, PermissionDialog},
  props: {
    search: {type: String, default: ''},
    dense: {type: Boolean, default: false},
    permissions: {type: Array, default: null},
    actionsWidth: {type: String, default: null},
    hideFields: {type: Array, default: () => []},
    actions: {type: Array, default: () => ['edit', 'delete']},
    itemsPerPage: {type: Number, default: -1},
    expansion: {type: Boolean, default: false},
  },
  data() {
    return {
      isLoading: false,
      edit: {dialog: false, permission: null},
      remove: {dialog: false, permission: null},
    };
  },
  async created() {
    if (!this.permissions) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('permission/fetchPermissions');
      } catch (err) {
        if (err.response.status === 403) {
          await this.$store.dispatch('showError', err.response.data.message);
        }
      }
      this.isLoading = false;
    }
  },
  computed: {
    items(): Permission[] {
      return this.permissions as Permission[] || Permission.query().all();
    },
    additionalActions(): any[] {
      return this.actions.filter((action: any) => action && action.icon && action.tooltip && action.emit);
    },
    headers(): any[] {
      const fields = [
        {text: this.$t('label.name'), value: 'name'},
        {text: this.$t('label.actions'), value: 'actions', align: 'center', sortable: false, width: this.actionsWidth || this.actions.length * 52 + 'px'},
      ];
      return fields.filter(field => !this.hideFields.includes(field.value));
    },
  },
  methods: {
    searchFilter(title: string, search: string, permission: Permission) {
      const searchTerms = search.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && permission.name.toLowerCase().includes(term), true);
    },
    showEditDialog(permission) {
      this.edit.dialog = true;
      this.edit.permission = permission;
    },
    showDeleteDialog(permission) {
      this.remove.dialog = true;
      this.remove.permission = permission;
    },
    showField(field: string) {
      return !this.hideFields.includes(field);
    },
    async deletePermission() {
      try {
        await this.$store.dispatch('permission/deletePermission', this.remove.permission);
        this.remove.permission = null;
        const msg = this.$t('msg.deleteSuccess', {item: this.$tc('p.permission')});
        await this.$store.dispatch('showSuccess', msg);
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
