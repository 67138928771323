import {Model} from '@vuex-orm/core';

export class SchoolClassCourse extends Model {
  static entity = 'school-class_courses';

  static primaryKey = ['schoolClassId', 'courseId'];

  static fields() {
    return {
      schoolClassId: this.attr(null),
      courseId: this.attr(null),
    };
  }

  courseId: string;
  schoolClassId: string;
}
