


















































import Vue from 'vue';
import {TranslateResult} from 'vue-i18n';
import {Course, Document, Student} from '~/models';
import {schoolClassConstraints, schoolClassRules} from './school.rules';

export default Vue.extend({
  name: 'school-dialog',
  props: {
    value: {type: Boolean, default: false},
    schoolClass: {type: Object, default: null},
  },
  data() {
    return {
      isValid: true,
      isLoading: false,
      formDefaults: null,
      form: {
        name: '',
        students: [],
        documents: [],
        courses: [],
      },
      rules: schoolClassRules.call(this),
      constraint: schoolClassConstraints,
    };
  },
  created() {
    this.setFormDefaults();
  },
  watch: {
    async value(value) {
      if (value) {
        this.isLoading = true;
        await Promise.all([
          this.$store.dispatch('student/fetchStudentOptions'),
          this.$store.dispatch('document/fetchDocumentsOptions'),
          this.$store.dispatch('course/fetchCourseOptions'),
        ]);
        this.isLoading = false;
      }
    },
    schoolClass() {
      this.setFormDefaults();
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    students(): Student[] {
      return Student.all();
    },
    documents(): Document[] {
      return Document.all();
    },
    courses(): Course[] {
      return Course.all();
    },
    headline(): TranslateResult {
      return this.schoolClass ? this.$t('action.editItem', {item: this.$tc('p.schoolClass')}) : this.$t('action.createItem', {item: this.$tc('p.schoolClass')});
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      try {
        let msg;
        if (this.schoolClass) {
          const payload = {id: this.schoolClass.id, data: this.form};
          await this.$store.dispatch('school/updateSchoolClass', payload);
          msg = this.$t('msg.updateSuccess', {item: this.$tc('p.schoolClass')});
        } else {
          await this.$store.dispatch('school/createSchoolClass', this.form);
          msg = this.$t('msg.addSuccess', {item: this.$tc('p.schoolClass')});
        }
        await this.$store.dispatch('showSuccess', msg);
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    setFormDefaults() {
      if (this.schoolClass) {
        this.formDefaults = {
          name: this.schoolClass.name,
          students: this.schoolClass.students.map(student => student.id),
          documents: this.schoolClass.documents.map(document => document.id),
          courses: this.schoolClass.courses.map(course => course.id),
        };
      } else {
        this.formDefaults = Object.assign({}, this.form);
      }

      this.reset();
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
