import { Module } from 'vuex';
import { ExtraTryTests, Student, Test, TestArea, TestInstance, TestInstanceAnswer, TestLicence, TestQuestion, TestStudent, TestSubArea, TestSubject } from '~/models';
import { api } from '~/util/api';

const UPDATE_THRESHOLD = 60000; // 60 sek

export interface ProgressTestState {
  search: string,
  fetchedAt: number,
  optionsFetchedAt: number,
}

export const testModule: Module<ProgressTestState, any> = {
  namespaced: true,
  state: {
    search: '',
    fetchedAt: 0,
    optionsFetchedAt: 0,
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
  },
  actions: {
    updateSearch: async (context, search) => {
      context.commit('SET_SEARCH', search);
    },
    create: async (context, body) => {
      const response = await api.post('/api/test', body);
      await Test.insert({data: response.data});
      return response.data;
    },
    update: async (context, payload) => {
      const response = await api.put(`/api/test/${payload.id}`, payload.body);
      await Test.insert({data: response.data});
      return response.data;
    },
    fetchAll: async (context) => {
      if (Date.now() - context.state.fetchedAt > UPDATE_THRESHOLD) {
        context.state.fetchedAt = Date.now();
        const response = await api.get('/api/test');
        await Test.insert({data: response.data});
      }
    },
    fetchOptions: async (context, options = {insert: true}) => {
      if (!options.insert) {
        const response = await api.get('/api/test/options');
        return response.data;
      } else if (Date.now() - context.state.optionsFetchedAt > UPDATE_THRESHOLD) {
        context.state.optionsFetchedAt = Date.now();
        const response = await api.get('/api/test/options');
        await Test.insertOrUpdate({data: response.data});
      }
    },
    fetchById: async (context, id) => {
      const response = await api.get(`/api/test/${id}`);
      await TestLicence.delete(item => item.testId === id);
      await TestSubject.delete(item => item.testId === id);
      await TestArea.delete(item => item.testId === id);
      await TestSubArea.delete(item => item.testId === id);
      await TestQuestion.delete(item => item.testId === id);
      await Test.insert({data: response.data});
    },
    findInstances: async (context, filter) => {
      const response = await api.post(`/api/test/instances/find`, filter);
      return response.data;
    },
    fetchInstanceById: async (context, id) => {
      const response = await api.get(`/api/test/instance/${id}`);
      const responseData = Object.assign({}, response.data);
      await TestInstance.insert({data: response.data});
      return responseData;
    },
    getTestInstanceAdditionalInfo: async (context, id) => {
      const response = await api.get(`/api/test/instance/${id}/additional`);
      return response.data;
    },
    giveAdditionalTry: async (context, payload: { testId: number, studentId: string, }) => {
      await api.put(`/api/test/extra-try`, payload);
      await ExtraTryTests.insert({data: payload});
    },
    removeAdditionalTry: async (context, payload: { testId: number, studentId: string, }) => {
      await api.put(`/api/test/extra-try/remove`, payload);
      await ExtraTryTests.delete(ett => ett.testId === payload.testId && ett.studentId === payload.studentId);
    },
    assignStudents: async (context, payload: { testId: string, students: Student[] }) => {
      await api.put(`/api/test/${payload.testId}/students`, {students: payload.students.map(s => s.id)});
      await TestStudent.delete(ts => ts.testId === payload.testId);
      await TestStudent.insert({data: payload.students.map(s => ({testId: payload.testId, studentId: s.id}))});
    },
    archive: async (context, progressTest: Test) => {
      await api.put(`/api/test/archive/${progressTest.id}`);
      await Test.update({where: progressTest.id, data: {state: 'archived'}});
    },
    activate: async (context, progressTest: Test) => {
      await api.put(`/api/test/activate/${progressTest.id}`);
      await Test.update({where: progressTest.id, data: {state: 'active'}});
    },
    delete: async (context, progressTest: Test) => {
      await api.delete(`/api/test/${progressTest.id}`);
      await Test.delete(progressTest.id);
    },
    denyInsight: async (context, instance: TestInstance) => {
      await api.put(`/api/test/instance/${instance.id}/deny-insight`);
      await TestInstance.update({where: instance.id, data: {insightGranted: false}});
    },
    grantInsight: async (context, instance: TestInstance) => {
      await api.put(`/api/test/instance/${instance.id}/grant-insight`);
      await TestInstance.update({where: instance.id, data: {insightGranted: true}});
    },
    deleteInstance: async (context, instance: TestInstance) => {
      await api.delete(`/api/test/instance/${instance.id}`);
      await TestInstance.delete(instance.id);
    },
    editInstanceAnswer: async (context, payload: { instanceId: number, questionId: number, answerId: number }) => {
      await api.put(`/api/test/instance/${payload.instanceId}/answer`, payload);

      const instance = TestInstance.query().whereId(payload.instanceId).withAll().with('answers').first();
      const oldAnswer = instance.answers.find(a => a.questionId === payload.questionId);

      if (oldAnswer) {
        await TestInstanceAnswer.delete(tia => tia.testInstanceId === payload.instanceId && tia.answerId === oldAnswer.id);
      }
      await TestInstanceAnswer.insert({data: {testInstanceId: payload.instanceId, answerId: payload.answerId}});
    },
    recalculateResults: async (context, instanceId) => {
      const response = await api.put(`/api/test/instance/${instanceId}/recalculate`);
      await TestInstance.insertOrUpdate({data: response.data});
    },
  },
  getters: {},
};
