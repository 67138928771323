import {Model} from '@vuex-orm/core';
import {Licence, Question} from '~/models';

export class LicenceQuestion extends Model {
  static entity = 'licence_questions';

  static primaryKey = ['licenceId', 'questionId'];

  static fields() {
    return {
      licenceId: this.attr(null),
      licence: this.belongsTo(Licence, 'licenceId'),
      questionId: this.attr(null),
      question: this.belongsTo(Question, 'questionId'),
    };
  }

  licenceId: string;
  licence: Licence;
  questionId: string;
  question: Question;
}
