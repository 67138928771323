






































import Vue from 'vue';
import {testGroupConstraints, testGroupRules} from '~/components/test/test-group.rules';
import {Student} from '~/models';

export default Vue.extend({
  name: 'test-group-dialog',
  props: {
    value: {type: Boolean, default: false},
    testGroup: {type: Object, default: null},
  },
  data() {
    return {
      isLoading: false,
      isValid: false,
      rule: testGroupRules.call(this),
      constraint: testGroupConstraints,
      options: {
        tests: [],
        students: [],
      },
      form: {
        title: '',
        students: [],
        tests: [],
      },
    };
  },
  watch: {
    async value(value) {
      if (value) {
        this.isLoading = true;
        const result = await Promise.all([
          this.$store.dispatch('test/fetchOptions', {insert: false}),
          this.$store.dispatch('student/fetchStudentOptions', {insert: false}),
        ]);
        this.options.tests = result[0].filter(t => t.state === 'active');
        this.options.students = result[1].map(s => ({...s, fullName: s.forename + ' ' + s.surname}));
        this.form.title = this.testGroup.title;
        this.form.students = this.testGroup.students.map(student => student.id);
        this.form.tests = this.testGroup.tests.map(test => test.id);
        this.isLoading = false;
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      },
    },
    sectionHeaders(): any[] {
      return [
        {text: 'name', value: 'fullName'},
        {text: 'limit', value: 'limit', width: '120px'},
        {text: 'actions', value: 'actions', width: '40px'},
      ];
    },
    students(): Student[] {
      return Student.query().all();
    },
  },
  methods: {
    async editTest(): Promise<void> {
      try {
        const payload = {
          id: this.testGroup.id,
          body: this.form,
        };
        await this.$store.dispatch('testGroup/update', payload);
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
