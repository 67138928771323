import {Model} from '@vuex-orm/core';
import {Subject, Test} from '~/models';

export class TestSubject extends Model {
  static entity = 'test_subjects';

  static primaryKey = ['testId', 'subjectId'];

  static fields() {
    return {
      limit: this.attr(null),
      subjectId: this.attr(null),
      testId: this.attr(null),
      subject: this.belongsTo(Subject, 'subjectId'),
      test: this.belongsTo(Test, 'testId'),
    };
  }

  testId: string;
  test: Test;
  subjectId: string;
  subject: Subject;
  limit: number;
}
