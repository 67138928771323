import {RuleGenerator} from '~/util/RuleGenerator';

export const documentConstraints = {
  title: {isRequired: true, maxLength: 100},
  displayName: {isRequired: true, maxLength: 100},
  description: {maxLength: 500},
  state: {isRequired: true},
};

export const versionConstraints = {
  name: {isRequired: true},
  author: {isRequired: true},
  file: {isRequired: true, isPdf: true},
};

export function documentRules(this: any) {
  return RuleGenerator.generateRules.call(this, documentConstraints);
}

export function versionRules(this: any) {
  return RuleGenerator.generateRules.call(this, versionConstraints);
}
