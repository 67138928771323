import { render, staticRenderFns } from "./TestInstanceTable.vue?vue&type=template&id=6d1df2f6&scoped=true&"
import script from "./TestInstanceTable.vue?vue&type=script&lang=ts&"
export * from "./TestInstanceTable.vue?vue&type=script&lang=ts&"
import style0 from "./TestInstanceTable.vue?vue&type=style&index=0&id=6d1df2f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d1df2f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VDataTable,VSelect,VSpacer,VTextField,VToolbar})
