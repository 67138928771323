export const de = {
  nav: {
    dashboard: 'Dashboard',
    documents: 'Dokumente',
    students: 'Benutzer',
    courses: 'Kurse',
    schools: 'Flugschulen',
    questions: 'Fragen',
    tests: 'Tests',
    testGroups: 'Test Gruppen',
    analysis: 'Analyse',
    progressTracker: 'Lernfortschritte',
    administration: 'Administration',
    admins: 'Admins',
    roles: 'Rollen',
    permissions: 'Rechte',
    testInstances: 'Test Instanzen',
  },
  label: {
    noEndDate: 'Kein Enddatum',
    firstName: 'Vorname',
    lastName: 'Nachname',
    optional: 'Optional',
    mandatory: 'Erforderlich',
    feedback: 'Feedback',
    endsAt: 'Endet am',
    lastLogin: 'Letzter Login',
    noFlightSchoolAssigned: 'Keiner Flugschule zugeordnet',
    locked: 'Gesperrt',
    available: 'Verfügbar',
    attempted: 'Offen',
    submittedAt: 'Abgegeben am',
    lastResult: 'Letztes Ergebnis',
    status: 'Status',
    testStartFrom: 'Ausbildungsbeginn',
    testEndDate: 'Ausbildungsende',
    points: 'Punkte',
    tries: 'Versuche',
    userType: 'Benutzer Typ',
    isCustomQuestion: 'CAT Frage',
    insight: 'Einsicht',
    granted: 'Gewährt',
    denied: 'Entzogen',
    archived: 'Archiviert',
    limit: 'Limit',
    unlimited: 'Unbegrenzt',
    isCorrect: 'Ist Richtig',
    result: 'Ergebnis',
    percentage: 'Prozentsatz',
    duration: 'Dauer',
    all: 'Alle',
    language: 'Sprache',
    text: 'Text',
    test: 'Test',
    number: 'Nummer',
    author: 'Autor',
    id: 'ID',
    name: 'Name',
    icon: 'Icon',
    displayName: 'Anzeigename',
    login: 'Anmeldename',
    surname: 'Nachname',
    forename: 'Vorname',
    password: 'Passwort',
    username: 'Benutzername',
    email: 'E-Mail',
    subject: 'Betreff',
    message: 'Nachricht',
    classes: 'Klassen',
    phoneNr: 'Telefonnummer',
    versionName: 'Versionsname',
    search: 'Suchen',
    state: 'Status',
    title: 'Titel',
    logo: 'Logo',
    filename: 'Dateiname',
    file: 'Datei',
    downloads: 'Downloads',
    description: 'Beschreibung',
    active: 'Aktiv',
    inactive: 'Inaktiv',
    actions: 'Aktionen',
    createdAt: 'Erstellt',
    finishedAt: 'Abgegeben',
    updatedAt: 'Geändert',
    uploadedAt: 'Hochgeladen',
    none: 'keine',
    notify: 'per Email benachrichtigen',
    displayLogo: 'Logo Einblenden',
    isDuplex: 'Ist Duplex',
    latestVersion: 'Letzte Version',
    schoolLogo: 'Logo der Flugschule',
    validFrom: 'Gültig ab',
    validUntil: 'Gültig bis',
    initKey: 'Initialisierungsschlüssel',
    dependentOn: 'Abhängig von',
    dependants: 'Hat abhängigen Test',
    passRate: 'Bestehens Quote',
    preExamPassRate: 'Pre-Exam Bestehens Quote',
    timeLimit: 'Zeitlimit',
    passed: 'Bestanden',
    notPassed: 'Noch nicht Bestanden',
    deliveredTests: 'Abgegebene Tests',
    givenAnswer: 'Antwort',
    assignedItems: 'Zugewiesene {items}',
    directlyAssignedTests: 'Direkt zugewiesene Tests',
    evaluation: 'Auswertung',
    coolDown: 'Cooldown Timer',
    type: 'Typ',
    hours: 'Std.',
    attention: 'Aufmerksamkeit',
    default: 'Standard',
    preExam: 'Pre-Exam',
    finalTest: 'Final Test',
    progressTest: 'Progress Test',
    correct: 'Richtig',
    incorrect: 'Falsch',
    weakpointAnalysis: 'Weakpoint Analysis',
    weakpointAnalysisFor: 'Weakpoint Analysis für {target}',
    startDate: 'Start Datum',
    endDate: 'End Datum',
    minPercentage: 'Min. Prozentsatz',
    advancedFilters: 'Erweiterte Filter',
    stacking: 'Gestapelt',
    percentaged: 'Prozentual',
    sort: 'Sortieren',
    ascending: 'Aufsteigend',
    authorized: 'Authorisiert',
    unauthorized: 'Nicht Authorisiert',
    protected: 'Geschützt',
    activationCode: 'Aktivierungs-Code',
    explanation: 'Erklärung',
  },
  p: {
    none: 'keins | keine',
    permission: 'Recht | Rechte',
    role: 'Rolle | Rollen',
    admin: 'Admin | Admins',
    point: 'Punkt | Punkte',
    database: 'Datenbank | Datenbanken',
    evalPool: 'Auswertungs Pool | Auswertungs Pools',
    pool: 'Pool | Pools',
    testInstance: 'Test Instanz | Test Instanzen',
    extraTry: 'Zusatz Versuch | Zusätzliche Versuche',
    student: 'Benutzer | Benutzer',
    school: 'Flugschule | Flugschulen',
    schoolClass: 'Schulklasse | Schulklassen',
    course: 'Kurs | Kurse',
    document: 'Dokument | Dokumente',
    version: 'Version | Versionen',
    device: 'Gerät | Geräte',
    slot: 'Slot | Slots',
    try: 'Versuch | Versuche',
    licence: 'Lizenz | Lizenzen',
    subject: 'Thema | Themen',
    area: 'Bereich | Bereiche',
    subArea: 'Unterbereich | Unterbereiche',
    answer: 'Antwort | Antworten',
    question: 'Frage | Fragen',
    explanation: 'Erklärung | Erklärungen',
    figure: 'Schaubild | Schaubilder',
    explanationFigure: 'Eklärungsschaubild | Eklärungsschaubilder',
    correctAnswer: 'Richtige Antwort | Richtige Antworten',
    minute: 'Minute | Minuten',
    image: 'Bild | Bilder',
    test: 'Test | Tests',
    preExam: 'Pre-Exam | Pre-Exams',
    progressTest: 'Progress-Test | Progress-Tests',
    finalTest: 'Final-Test | Final-Tests',
    testGroup: 'Testgruppe | Testgruppen',
    staticQuestion: 'Statische Frage | Statische Fragen',
  },
  action: {
    back: 'Zurück',
    reset: 'Zurücksetzen',
    submit: 'Bestätigen',
    save: 'Speichern',
    cancel: 'Abbrechen',
    close: 'Schließen',
    delete: 'Löschen',
    edit: 'Ändern',
    add: 'Hinzufügen',
    create: 'Anlegen',
    upload: 'Hochladen',
    signIn: 'Anmelden',
    recalculateResults: 'Ergebnis aktualisieren',
    grantInsight: 'Dem Schüler Einsicht gewähren',
    extraTry: 'Weiteren Versuch freischalten',
    removeExtraTry: 'Weiteren Versuch entziehen',
    showItem: '{item} anzeigen',
    hideItem: '{item} verbergen',
    prevItem: 'Vorheriger {item}',
    nextItem: 'Nächster {item}',
    activateItem: '{item} aktivieren',
    archiveItem: '{item} archivieren',
    deleteItem: '{item} löschen',
    removeItem: '{item} entfernen',
    importItem: '{item} importieren',
    editItem: '{item} bearbeiten',
    createItem: '{item} erstellen',
    addItem: '{item} hinzufügen',
    assignItem: '{item} zuordnen',
    assignItemToTarget: '{item} zu {target} zuordnen',
    generateItem: '{item} generieren',
    authorizeItem: '{item} freigeben',
    exportItem: '{item} exportieren',
    confirm: 'Bestätigen',
    apply: 'Anwenden',
    saveAsCopy: 'Als Kopie speichern',
    select: 'Auswählen',
    selectFile: 'Datei auswählen',
    uploadFile: 'Datei hochladen',
    changeLanguage: 'Sprache ändern',
    logout: 'Abmelden',
    search: 'Suchen',
    enableDarkTheme: 'Dark Theme aktivieren',
    disableDarkTheme: 'Dark Theme deaktivieren',
    setAppVersion: 'App Version definieren',
    generatePassword: 'Passwort generieren',
    importStudents: 'Mehrere Benutzer über eine CSV-Datei importieren',
    assignTests: 'Tests zuordnen',
    changeUserPassword: 'Benutzer-Passwort ändern',
    generateSelectedPasswords: 'Passwörter für die ausgewählten Benutzer generieren',
    showPreview: 'Vorschau ansehen',
    addDocToBlacklist: 'Dem Nutzer den Zugriff auf das Dokument enziehen',
    removeDocFromBlacklist: 'Dem Nutzer den Zugriff auf das Dokument gewähren',
    previewVersion: 'Version ansehen',
    uploadVersion: 'Version hochladen',
    enableZoom: 'Zoom Aktivieren',
    disableZoom: 'Zoom Deaktivieren',
    manageItem: '{item} verwalten',
    printResults: 'Ergebnisse Drucken',
    showUserDetails: 'Nutzer Details Anzeigen',
  },
  hint: {
    versionUpload: 'Bitte wählen sie eine .pdf datei aus',
    csvUpload: 'Gültige CSV Headers: Nachname, Vorname, E-Mail, Login, Passwort',
    documentOverview: 'Zugänglich / Unterschiedlich / Gesamt',
    initKey: 'Den Initialisierungsschlüssel findest du in den server logs.',
    schoolLogo: 'Max. Dateigröße: 1MB. Zugelassene Formate: png, jpeg und svg',
    itemsSelected: '{count} {item} ausgewählt',
    itemsOfTotalSelected: '{count} von {total} {item} ausgewählt',
  },
  msg: {
    confirm: {
      removeQuestion: 'Bist du dir sicher, dass du Frage {question} aus "{section}" und allen Unterkategorien entfernen willst?',
    },
    confirmExtraTry: {
      title: 'Weiteren Versuch Bestätigen',
      text: 'Dieses Pre-Exam wurde bereits bestanden. Bitte bestätigen Sie, dass der Schüler dieses Pre-Exam erneut absolvieren soll.',
    },
    noAnswerGiven: 'Nicht beantwortet.',
    additionalTryGiven: 'Ein weiterer Versuch wurde für den Schüler freigeschaltet.',
    noQuestionsFound: 'Keine Fragen für deine Suchanfrage gefunden.',
    archiveSuccess: '{item} wurde erfolgreich archiviert.',
    activateSuccess: '{item} wurde erfolgreich aktiviert.',
    addSuccess: '{item} wurde erfolgreich hinzugefügt.',
    updateSuccess: '{item} wurde erfolgreich aktualisiert.',
    studentImportSuccess: 'der Benutzer wurde erfolgreich importiert. | Alle {amount} Benutzer wurden erfolgreich importiert.',
    deleteSuccess: '{item} wurde erfolgreich gelöscht.',
    confirmDeletion: 'Bitte beachten Sie, dass das Löschen dieses Elements unvorhergesehene Konsequenzen für Integrität der Datenbankstruktur haben könnte.',
    uploadSuccess: '{item} wurde erfolgreich hochgeladen.',
    importSuccess: '{item} wurden erfolgreich importiert.',
    passwordChangeSuccess: 'Das Passwort wurde erfolgreich geändert.',
    passwordChangeNotifySuccess: 'Das Passwort wurde erfolgreich geändert, und der Schüler darüber per email an "{email}" informiert.',
  },
  error: {
    searchQuestionCondition: 'Suchen über alle Lizezen werden aktuell nicht unterstützt. Bitte wähle zuerst eine Linzenz aus.',
    unexpected: 'Ein unerwarteter Fehler ist aufgetreten: {error}',
    alreadyInUse: '{item} "{value}" wird bereits verwendet.',
    isInvalid: '{field} ist ungültig.',
    emailOrLoginInUse: 'E-Mail oder Login wird bereits verwendet.',
  },
  form: {
    isRequired: '{field} ist ein Pflichtfeld.',
    maxLength: '{field} darf nicht mehr als {max} Zeichen enthalten.',
    minLength: '{field} muss mindestens {min} Zeichen enthalten.',
    length: '{field} muss genau {length} Zeichen enthalten.',
    isInvalidEmail: '{field} ist keine gültige E-Mail Adresse.',
    isNumeric: '{field} muss einen Zahlenwert enthalten.',
    minValue: '{field} muss mindestens {val} sein.',
    maxValue: '{field} darf maximal {val} sein.',
    hasNoPdfFileEnding: 'Der Dateiname muss mit ".pdf" enden.',
  },
  page: {
    initialSetup: {
      headline: 'Ersteinrichtung',
      text: `Herzlich willkommen im LunaTech Mentoring System.<br/><br/>
Bitte legen Sie die Benutzerdaten des Administrator's fest.
Achten sie hierbei auf die genaue Schreibweise Ihrer Eingaben,
da Schreibfehler eine Zurücksetzung der Benutzerdatenbank erfordern könnten.`,
    },
    signIn: {
      headline: 'Melde dich an',
      submit: 'Anmelden',
    },
    dashboard: {
      headline: 'Dashboard',
      documents: 'Es sind <b>keine Dokumente</b> vorhanden. | Es ist <b>ein Dokument</b> vorhanden. | Es sind <b>{documentCount} Dokumente</b> vorhanden,<br/>die zusammen <b>{totalDownloads} mal</b> herunter geladen wurden.',
      students: 'Es sind <b>keine Benutzer</b> vorhanden. | Es ist <b>ein Benutzer</b> vorhanden. | Es sind <b>{count} Benutzer</b> vorhanden.',
      courses: 'Es sind <b>keine Kurse</b> vorhanden. | Es ist <b>ein Kurs</b> vorhanden. | Es sind <b>{count} Kurse</b> vorhanden.',
      schools: 'Es sind <b>keine Schulen</b> vorhanden. | Es ist <b>eine Schule</b> vorhanden. | Es sind <b>{count} Schulen</b> vorhanden.',
    },
    documents: {
      headline: 'Dokumentübersicht',
    },
    students: {
      headline: 'Benutzerverwaltung',
    },
  },
  search: {
    noResults: 'Deine Suche nach "{search}" brachte keine Ergebnisse.',
  },
  language: {
    german: 'Deutsch',
    english: 'Englisch',
  },
  user: {
    presence: 'Präsenzschüler',
    distance: 'Fernschüler',
    staff: 'Mitarbeiter',
    script: 'Scriptnutzer',
  },
  table: {
    noData: 'Keine Einträge Vorhanden',
  },
  pagination: {
    pageText: 'Einträge {start} bis {stop} von {length}',
    rowsPerPage: 'Zeilen pro Seite',
  },
};
