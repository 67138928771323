import {Model} from '@vuex-orm/core';
import {Area, Test} from '~/models';

export class TestArea extends Model {
  static entity = 'test_areas';

  static primaryKey = ['testId', 'areaId'];

  static fields() {
    return {
      limit: this.attr(null),
      areaId: this.attr(null),
      testId: this.attr(null),
      area: this.belongsTo(Area, 'areaId'),
      test: this.belongsTo(Test, 'testId'),
    };
  }

  testId: string;
  test: Test;
  areaId: string;
  area: Area;
  limit: number;
}
