


















































import Vue from 'vue';
import {TranslateResult} from 'vue-i18n';
import {Course} from '~/models';
import {sortBy} from '~/util';
import {documentConstraints, documentRules} from './document.rules';

export default Vue.extend({
  name: 'document-dialog',
  props: {
    value: {type: Boolean, default: false},
    document: {type: Object, default: null},
  },
  data() {
    return {
      isLoading: true,
      isValid: true,
      formDefaults: null,
      form: {
        title: '',
        displayName: '',
        description: '',
        state: 'active',
        courses: [],
      },
      options: {
        state: [
          {val: 'active', name: this.$t('label.active')},
          {val: 'inactive', name: this.$t('label.inactive')},
        ],
      },
      rules: documentRules.call(this),
      constraint: documentConstraints,
    };
  },
  watch: {
    async value(value) {
      if (value) {
        this.isLoading = true;
        await this.$store.dispatch('course/fetchCourseOptions');
        this.setFormDefaults();
        this.isLoading = false;
      }
    },
    'form.title'(newValue, oldValue) {
      if (oldValue === this.form.displayName) {
        this.form.displayName = newValue;
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    shouldTextareaGrow(): boolean {
      const rowCount = (this.form.description.match(/\n/g) || []).length + 1;
      return rowCount < 10;
    },
    courses(): any[] {
      return Course.all().sort(sortBy('name'));
    },
    headline(): TranslateResult {
      return this.document ? this.$t('action.editItem', {item: this.$tc('p.document')}) : this.$t('action.createItem', {item: this.$tc('p.document')});
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      try {
        let msg;
        if (this.document) {
          await this.$store.dispatch('document/updateDocument', {id: this.document.id, data: this.form});
          msg = this.$t('msg.updateSuccess', {item: this.$tc('p.document')});
        } else {
          await this.$store.dispatch('document/createDocument', this.form);
          msg = this.$t('msg.addSuccess', {item: this.$tc('p.document')});
          this.reset();
        }
        await this.$store.dispatch('showSuccess', msg);
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', this.getError(err));
      }
    },
    getError(err) {
      if (err.response?.status === 409) {
        return this.$t('error.alreadyInUse', {item: this.$t('label.name'), value: this.form.title});
      }
      return err;
    },
    setFormDefaults() {
      if (this.document) {
        this.formDefaults = {
          title: this.document.title,
          displayName: this.document.displayName,
          description: this.document.description,
          state: this.document.state,
          courses: this.document.courses.map(course => course.id),
        };
      } else {
        this.formDefaults = Object.assign({}, this.form);
      }
      this.reset();
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
