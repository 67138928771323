import {Model} from '@vuex-orm/core';
import {Area, Question, SubAreaQuestion} from '.';

export class SubArea extends Model {
  static entity = 'sub-areas';

  static fields() {
    return {
      id: this.attr(null),
      areaId: this.attr(null),
      number: this.attr(''),
      name: this.attr(''),
      area: this.belongsTo(Area, 'areaId'),
      questions: this.belongsToMany(Question, SubAreaQuestion, 'subAreaId', 'questionId'),
    };
  }

  id: number;
  areaId: number;
  area: Area;
  number: string;
  name: string;
  areas: Area[];
  questions: Question[];
  createdAt: string;
  updatedAt: string;

  get fullName() {
    return `${this.number} - ${this.name}`;
  }
}
