




















import Vue from 'vue';
import {AdminDialog, AdminTable} from '~/components/admin';
import {TooltipButton} from '~/components/common';

export default Vue.extend({
  components: {TooltipButton, AdminTable, AdminDialog},
  data() {
    return {
      addDialog: false,
    };
  },
  created() {
    document.title = `LTMS - Admins`;
  },
  computed: {
    search: {
      get(): string {
        return this.$store.state.admin.search;
      },
      set(value: string) {
        this.$store.dispatch('admin/updateSearch', value);
      },
    },
  },
});
