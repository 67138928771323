import {Model} from '@vuex-orm/core';

export class SubAreaQuestion extends Model {
  static entity = 'sub-area_questions';

  static primaryKey = ['subAreaId', 'questionId'];

  static fields() {
    return {
      subAreaId: this.attr(null),
      questionId: this.attr(null),
    };
  }

  subAreaId: string;
  questionId: string;
}
