export class StorageManager {
  public static DEFAULT_LOCALE = 'de';

  static getLocale() {
    return window.localStorage.getItem('locale') || StorageManager.DEFAULT_LOCALE;
  }

  static setLocale(locale) {
    window.localStorage.setItem('locale', locale);
  }

  static isDarkTheme(): boolean {
    return window.localStorage.getItem('darkTheme') === 'true';
  }

  static setDarkTheme(isDark: boolean) {
    return window.localStorage.setItem('darkTheme', String(isDark));
  }

  static getTestInstanceFilter(): any {
    const defaultFilter = {school: null, student: null, type: null};
    try {
      return JSON.parse(window.localStorage.getItem('test-instance-filter')) || defaultFilter;
    } catch (e) {
      return defaultFilter;
    }
  }

  static setTestInstanceFilter(filter: any) {
    return window.localStorage.setItem('test-instance-filter', JSON.stringify(filter));
  }
}
