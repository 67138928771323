




































































































































import moment from 'moment';
import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {options, studentConstraints, studentRules} from '~/components/student/student.rules';
import {Course, Document, School} from '~/models';
import {Randomizer, sortBy, StorageManager} from '~/util';
import {date} from '~/util/filters';

export default Vue.extend({
  name: 'student-add-dialog',
  components: {TooltipButton},
  props: {
    value: {type: Boolean, default: false},
  },
  data() {
    return {
      isLoading: false,
      isValid: false,
      showPassword: true,
      menu: {
        validUntil: false,
        validFrom: false,
        testStartFrom: false,
      },
      updateKey: 0,
      formDefaults: null,
      form: {
        login: '',
        forename: '',
        surname: '',
        email: '',
        password: '',
        phoneNr: '',
        type: 'distance',
        courses: [],
        documents: [],
        deviceSlots: 3,
        school: null,
        validFrom: null,
        validUntil: null,
        testStartFrom: null,
        noTestEndDate: false,
        schoolClass: null,
        language: 'en',
        notify: false,
      },
      options: {
        deviceSlots: options.deviceSlots,
        classes: [],
      },
      rules: studentRules.call(this),
      constraint: studentConstraints,
    };
  },
  async created() {
    this.formDefaults = Object.assign({}, this.form);
  },
  watch: {
    async value(value) {
      if (value) {
        this.isLoading = true;
        await this.$store.dispatch('student/fetchDialogOptions');
        this.isLoading = false;
      }
    },
    'form.email'(newValue, oldValue) {
      if (oldValue === this.form.login) {
        this.form.login = newValue;
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    languages(): any[] {
      return [
        {text: this.$t('language.german'), value: 'de'},
        {text: this.$t('language.english'), value: 'en'},
      ];
    },
    userTypes(): any[] {
      return [
        {text: this.$t('user.distance'), value: 'distance'},
        {text: this.$t('user.presence'), value: 'presence'},
        {text: this.$t('user.staff'), value: 'staff'},
        {text: this.$t('user.script'), value: 'script'},
      ];
    },
    courses(): Course[] {
      return Course.all().sort(sortBy('name'));
    },
    schools(): School[] {
      return School.query().with('classes').all().sort(sortBy('name'));
    },
    documents(): Document[] {
      return Document.all();
    },
    locale(): string {
      return StorageManager.getLocale();
    },
    formattedValidFrom(): string {
      return this.form.validFrom ? date(this.form.validFrom) : '';
    },
    formattedValidUntil(): string {
      return this.form.validUntil ? date(this.form.validUntil) : '';
    },
    formattedTestStartFrom(): string {
      return this.form.testStartFrom ? date(this.form.testStartFrom) : '';
    },
    testEndDateHint(): string {
      if (!this.form.testStartFrom || this.form.noTestEndDate) {
        return null;
      }
      const endDate = new Date(this.form.testStartFrom);
      endDate.setMonth(endDate.getMonth() + 18);
      return this.$t('label.endsAt') + ' ' + moment(endDate).format('DD.MM.YYYY');
    },
  },
  methods: {
    courseSearchFilter(course, input, text) {
      const lowerText = text.toLowerCase();
      const searchTerms = input.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && lowerText.includes(term), true);
    },
    documentSearchFilter(document, input, text) {
      const lowerText = text.toLowerCase();
      const searchTerms = input.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && lowerText.includes(term), true);
    },
    onSchoolSelected(schoolId) {
      const school = this.schools.find(s => s.id === schoolId);
      this.form.schoolClass = null;
      if (school?.classes?.length > 0) {
        this.options.classes = school.classes.map(schoolClass => ({name: schoolClass.name, id: schoolClass.id}));
      } else {
        this.options.classes = [];
      }
    },
    async submit() {
      if (!this.isValid) {
        return;
      }

      try {
        const payload = Object.assign({}, this.form, {
          validFrom: this.form.validFrom ? moment(this.form.validFrom).toISOString(true) : null,
          validUntil: this.form.validUntil ? moment(this.form.validUntil).toISOString(true) : null,
          testStartFrom: this.form.testStartFrom ? moment(this.form.testStartFrom).toISOString(true) : null,
        });
        await this.$store.dispatch('student/createStudent', payload);
        this.$emit('input', false);
        const msg = this.$t('msg.addSuccess', {item: this.$tc('p.student')});
        await this.$store.dispatch('showSuccess', msg);
        this.reset();
        this.updateKey++;
      } catch (err) {
        const msg = this.getErrorMessage(err);
        await this.$store.dispatch('showError', msg);
      }
    },
    getErrorMessage(err) {
      if (err.response.status === 409) {
        return this.$t('error.emailOrLoginInUse');
      }
      return err;
    },
    generatePassword() {
      this.form.password = Randomizer.generateRandomStringOfLength(options.generatedPasswordLength);
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
