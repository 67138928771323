






























import Vue from 'vue';
import {testConstraints, testRules} from '~/components/test/test.rules';

export default Vue.extend({
  components: {},
  data() {
    return {
      search: '',
      isValid: false,
      rule: testRules.call(this),
      constraint: testConstraints,
      options: {
        tests: [],
        students: [],
      },
      form: {
        title: '',
        students: [],
        tests: [],
      },
    };
  },
  async created() {
    document.title = 'LTMS - TestGroup Creation';
    const result = await Promise.all([
      this.$store.dispatch('test/fetchOptions', {insert: false}),
      this.$store.dispatch('student/fetchStudentOptions', {insert: false}),
    ]);

    this.options.tests = result[0].filter(t => t.state === 'active');
    this.options.students = result[1].map(s => ({...s, fullName: s.forename + ' ' + s.surname}));
  },
  computed: {
    sectionHeaders() {
      return [
        {text: 'name', value: 'fullName'},
        {text: 'limit', value: 'limit', width: '120px'},
        {text: 'actions', value: 'actions', width: '40px'},
      ];
    },
  },
  methods: {
    async createTest() {
      try {
        const payload = Object.assign({}, this.form);
        const testGroup = await this.$store.dispatch('testGroup/create', payload);
        await this.$router.push({name: 'test-group-details', params: {id: testGroup.id}});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    testFilter(test, input, text) {
      const lowerText = text.toLowerCase();
      const searchTerms = input.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && lowerText.includes(term), true);
    },
  },
});
