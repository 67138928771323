import {Module} from 'vuex';
import {Student, Test, TestGroup, TestGroupStudent, TestGroupTest} from '~/models';
import {api} from '~/util/api';

export interface TestGroupState {
  search: string,
  fetchedAt: number,
  fetchedOptionsAt: number,
}

const UPDATE_THRESHOLD = 60000; // 60 sek

export const testGroupModule: Module<TestGroupState, any> = {
  namespaced: true,
  state: {
    search: '',
    fetchedAt: 0,
    fetchedOptionsAt: 0,
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
  },
  actions: {
    updateSearch: async (context, search) => {
      context.commit('SET_SEARCH', search);
    },
    create: async (context, body) => {
      const response = await api.post('/api/test-group', body);
      await TestGroup.insert({data: response.data});
      return response.data;
    },
    update: async (context, payload) => {
      const response = await api.put(`/api/test-group/${payload.id}`, payload.body);
      await TestGroupStudent.delete(tgs => tgs.testGroupId === payload.id);
      await TestGroupTest.delete(tgt => tgt.testGroupId === payload.id);
      await TestGroup.insert({data: response.data});
      return response.data;
    },
    assignStudents: async (context, payload: { testGroupId: number, students: Student[] }) => {
      await api.put(`/api/test-group/${payload.testGroupId}/students`, {students: payload.students.map(s => s.id)});
      await TestGroupStudent.delete(tgs => tgs.testGroupId === payload.testGroupId);
      await TestGroupStudent.insert({data: payload.students.map(s => ({testGroupId: payload.testGroupId, studentId: s.id}))});
    },
    fetchAll: async (context) => {
      if (Date.now() - context.state.fetchedAt > UPDATE_THRESHOLD) {
        context.state.fetchedAt = Date.now();
        const response = await api.get('/api/test-group');
        await TestGroup.insert({data: response.data});
      }
    },
    fetchTestAndGroupOptions: async (context) => {
      if (Date.now() - context.state.fetchedOptionsAt > UPDATE_THRESHOLD) {
        context.state.fetchedOptionsAt = Date.now();
        const response = await api.get('/api/test-group/test-and-group-options');
        await Promise.all([
          Test.insertOrUpdate({data: response.data.tests}),
          TestGroup.insertOrUpdate({data: response.data.testGroups}),
        ]);
      }
    },
    fetchById: async (context, id) => {
      const response = await api.get(`/api/test-group/${id}`);
      await TestGroup.insert({data: response.data});
    },
    archive: async (context, testGroup: TestGroup) => {
      await api.put(`/api/test-group/archive/${testGroup.id}`);
      await TestGroup.update({where: testGroup.id, data: {state: 'archived'}});
    },
    activate: async (context, testGroup: TestGroup) => {
      await api.put(`/api/test-group/activate/${testGroup.id}`);
      await TestGroup.update({where: testGroup.id, data: {state: 'active'}});
    },
    delete: async (context, testGroup: Test) => {
      await api.delete(`/api/test-group/${testGroup.id}`);
      await TestGroup.delete(testGroup.id);
    },
  },
  getters: {},
};
