





























import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {options, studentConstraints, studentRules} from '~/components/student/student.rules';
import {Randomizer} from '~/util';

export default Vue.extend({
  name: 'change-password-dialog',
  components: {TooltipButton},
  props: {
    value: {type: Boolean, default: false},
    student: {type: Object},
  },
  data() {
    return {
      isValid: false,
      showPassword: true,
      formDefaults: null,
      form: {
        password: '',
        notify: false,
      },
      rules: studentRules.call(this),
      constraint: studentConstraints,
    };
  },
  created(): void {
    this.formDefaults = Object.assign({}, this.form);
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }
      try {
        const payload = {id: this.student.id, data: this.form};
        await this.$store.dispatch('student/updateStudentPassword', payload);
        const msg = this.form.notify ? this.$t('msg.passwordChangeNotifySuccess', {email: this.student.email}) : this.$t('msg.passwordChangeSuccess');
        await this.$store.dispatch('showSuccess', msg);
        this.$emit('input', false);
        this.reset();
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    generatePassword() {
      this.form.password = Randomizer.generateRandomStringOfLength(options.generatedPasswordLength);
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
