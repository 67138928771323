































































































































import moment from 'moment';
import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {Course, Document, School} from '~/models';
import {date, Randomizer, StorageManager} from '~/util';
import {options} from './student.rules';

export default Vue.extend({
  name: 'csv-import-dialog',
  components: {TooltipButton},
  props: {
    value: {type: Boolean, default: false},
  },
  data() {
    return {
      isLoading: false,
      uploadForm: {
        isValid: false,
        model: {
          file: null,
        },
      },
      importForm: {
        isValid: false,
        menu: {
          validFrom: false,
          validUntil: false,
        },
        model: {
          students: [],
          courses: [],
          documents: [],
          school: null,
          schoolClass: null,
          validFrom: null,
          validUntil: null,
          deviceSlots: 3,
          language: 'de',
          notify: false,
          type: 'distance',
        },
        options: {
          classes: [],
          languages: [
            {text: this.$t('language.german'), value: 'de'},
            {text: this.$t('language.english'), value: 'en'},
          ],
          deviceSlots: options.deviceSlots,
        },
      },
      items: [],
    };
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    schools(): School[] {
      return School.query().with('classes').all();
    },
    documents(): Document[] {
      return Document.all();
    },
    courses(): Course[] {
      return Course.all();
    },
    headers(): any[] {
      return [
        {text: this.$t('label.surname'), value: 'surname'},
        {text: this.$t('label.forename'), value: 'forename'},
        {text: this.$t('label.login'), value: 'login'},
        {text: this.$t('label.email'), value: 'email'},
        {text: this.$t('label.password'), value: 'password'},
      ];
    },
    userTypes(): any[] {
      return [
        {text: this.$t('user.distance'), value: 'distance'},
        {text: this.$t('user.presence'), value: 'presence'},
        {text: this.$t('user.staff'), value: 'staff'},
        {text: this.$t('user.script'), value: 'script'},
      ];
    },
    locale(): string {
      return StorageManager.getLocale();
    },
    selectedSchool(): string {
      return this.schools.find(school => school.id === this.importForm.model.school).name;
    },
    formattedValidFrom(): string {
      const validFrom = this.importForm.model.validFrom;
      return validFrom ? date(validFrom) : '';
    },
    formattedValidUntil(): string {
      const validUntil = this.importForm.model.validUntil;
      return validUntil ? date(validUntil) : '';
    },
    importFormRef(): HTMLFormElement {
      return this.$refs.importForm as HTMLFormElement;
    },
    uploadFormRef(): HTMLFormElement {
      return this.$refs.uploadForm as HTMLFormElement;
    },
  },
  methods: {
    onSchoolClassInput(schoolClass) {
      if (typeof (schoolClass) === 'string') {
        this.importForm.model.schoolClass = schoolClass;
      }
    },
    onSchoolSelected(schoolId) {
      const school = this.schools.find(s => s.id === schoolId);
      this.importForm.model.schoolClass = null;

      if (school && school.classes.length > 0) {
        this.importForm.options.classes = school.classes.map(schoolClass => ({
          name: schoolClass.name,
          id: schoolClass.id,
        }));
      } else {
        this.importForm.options.classes = [];
      }
    },
    generatePasswords() {
      this.importForm.model.students = this.importForm.model.students.map(item => {
        item.password = Randomizer.generateRandomStringOfLength(options.generatedPasswordLength);
        return item;
      });
    },
    async submitUpload() {
      if (!this.uploadFormRef.validate()) {
        return;
      }
      try {
        this.isLoading = true;
        const results = await Promise.all([
          this.$store.dispatch('student/uploadStudentCSV', this.uploadForm.model.file),
          this.$store.dispatch('student/fetchDialogOptions'),
        ]);
        this.items = results[0];
        this.importForm.model.students = this.items;
        this.isLoading = false;
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    async submitImport() {
      if (!this.importFormRef.validate()) {
        return;
      }
      try {
        const body = {
          ...this.importForm.model,
          validFrom: moment(this.importForm.model.validFrom).toISOString(true),
          validUntil: moment(this.importForm.model.validUntil).toISOString(true),
        };
        const students = await this.$store.dispatch('student/importStudents', body);
        const msg = this.$tc('msg.studentImportSuccess', students.length, {amount: students.length});
        await this.$store.dispatch('showSuccess', msg);
        this.close();
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    close() {
      this.reset();
      this.$emit('input', false);
    },
    reset() {
      this.items = [];
      this.uploadForm.model.file = null;
      this.importForm.model.students = [];
      this.importForm.model.deviceSlots = 2;
    },
  },
});
