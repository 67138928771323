



















import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {SchoolDialog, SchoolTable} from '~/components/school';
import {School} from '~/models';

export default Vue.extend({
  components: {SchoolDialog, SchoolTable, TooltipButton},
  data() {
    return {
      add: {dialog: false},
    };
  },
  computed: {
    schools(): School[] {
      return School.query().withAll().all();
    },
    text(): string {
      return this.$tc('page.dashboard.schools', this.schools.length);
    },
  },
});
