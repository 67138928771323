





























import Vue from 'vue';
import TestInstanceTable from '~/components/test/TestInstanceTable.vue';
import { School, Student } from '~/models';
import { StorageManager } from '~/util';

export default Vue.extend({
  components: {
    TestInstanceTable,
  },
  data() {
    return {
      loading: false,
      studentsKey: 0,
      filter: {
        school: null,
        student: null,
        type: null,
      },
      instances: [],
    };
  },
  mounted() {
    this.filter = StorageManager.getTestInstanceFilter();
  },
  async created() {
    try {
      await Promise.all([
        this.$store.dispatch('school/fetchOptions'),
        this.$store.dispatch('student/fetchStudentOptions'),
      ]);
    } catch (err) {
      await this.$store.dispatch('showError', err);
    }
  },
  computed: {
    schools(): School[] {
      return School.all();
    },
    testTypes(): any[] {
      return [
        {text: this.$t('label.default'), value: 'default'},
        {text: this.$t('label.progressTest'), value: 'progress'},
        {text: this.$t('label.finalTest'), value: 'final'},
        {text: this.$t('label.preExam'), value: 'preExam'},
      ];
    },
    students(): Student[] {
      return Student.all();
    },
    studentOptions(): Student[] {
      if (this.filter.school) {
        return this.students.filter(student => student.schoolId === this.filter.school);
      }
      return this.students;
    },
    studentNames(): { [id: number]: string } {
      return this.students.reduce((names, student) => Object.assign(names, {[student.id]: student.fullName}), {});
    },
    payload(): any {
      return {
        type: this.filter.type || undefined,
        schoolId: this.filter.school || undefined,
        studentId: this.filter.student || undefined,
      };
    },
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.instances = await this.$store.dispatch('test/findInstances', this.payload);
      } catch (e) {
        // ignore error
      }
      this.loading = false;
    },
    async onSchoolChange(newSchool) {
      if (newSchool && this.filter.student?.schoolId !== newSchool) {
        this.filter.student = null;
      }
      this.studentsKey++;
      this.onFilterChange();
    },
    onFilterChange() {
      StorageManager.setTestInstanceFilter(this.filter);
    },
  },
});
