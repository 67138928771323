








































































































import Vue from 'vue';
import {DeleteDialog, StateChip, TableRow, TooltipButton, TooltipDate} from '~/components/common';
import {AssignTestsToSchoolDialog, SchoolClassTable, SchoolDialog} from '~/components/school';
import {StudentTable} from '~/components/student';
import {School, SchoolClass, TestInstance} from '~/models';

export default Vue.extend({
  components: {
    TooltipDate,
    TooltipButton,
    StateChip,
    TableRow,
    StudentTable,
    DeleteDialog,
    SchoolDialog,
    SchoolClassTable,
    AssignTestsToSchoolDialog,
  },
  data() {
    return {
      assign: {dialog: false},
      edit: {dialog: false},
      remove: {dialog: false},
      removeStudentFromSchool: {dialog: false, student: null},
    };
  },
  async created() {
    await this.$store.dispatch('school/fetchSchool', this.$route.params.id);
    document.title = `LTMS - School: ${this.school.name}`;
  },
  computed: {
    school(): School {
      return School.query()
          .withAll()
          .with('classes.students|documents|courses')
          .with('students.devices|courses')
          .with('students.testInstances.student|test')
          .find(this.$route.params.id);
    },
    schoolClasses(): SchoolClass[] {
      return SchoolClass.query().withAll().where('school.id', this.$route.params.id).all();
    },
    breadcrumbs(): any[] {
      return [
        {text: this.$t('nav.schools'), to: {name: 'schools'}, exact: true},
        {text: this.school?.name || this.$route.params.id, disabled: true},
      ];
    },
    studentActions(): any[] {
      return [
        'edit',
        {icon: 'delete_outline', tooltip: this.$t('action.removeItem', {item: this.$tc('p.student')}), emit: 'remove'},
      ];
    },
    studentTestInstances(): any[] {
      return this.school.students.reduce((arr, student) => arr.concat(student.testInstances), []);
    },
    testInstanceHeaders(): any[] {
      return [
        {text: this.$tc('p.student'), value: 'name'},
        {text: this.$t('label.title'), value: 'title'},
        {text: this.$tc('p.correctAnswer', 2), value: 'correctAnswers'},
        {text: this.$tc('p.try'), value: 'try'},
        {text: this.$tc('label.passed'), value: 'passed'},
        {text: this.$t('label.finishedAt'), value: 'finishedAt'},
      ];
    },
    passRate(): string {
      if (this.school.passRate) {
        return `${this.school.passRate} %`;
      }

      return this.$t('label.none') as string;
    },
  },
  methods: {
    getColorClass(item: TestInstance) {
      if (item.passed) {
        return 'instance-green';
      }
      if (item.try < item.test?.tries) {
        return 'instance-orange';
      }

      const student = this.school.students.find(student => student.id === item.studentId);
      const latestTestInstance = student.testInstances.filter(ti => ti.testId === item.testId).sort((a, b) => a.try < b.try ? 1 : a.try > b.try ? -1 : 0)[0];
      if (latestTestInstance.id === item.id) {
        return 'instance-red';
      }

      return 'instance-orange';
    },
    showRemoveFromSchoolDialog(student) {
      this.removeStudentFromSchool.dialog = true;
      this.removeStudentFromSchool.student = student;
    },
    async deleteSchool() {
      try {
        await this.$store.dispatch('school/deleteSchool', this.school);
        await this.$store.dispatch('showSuccess', this.$t('msg.deleteSuccess', {item: this.$tc('p.school')}));
        await this.$router.push({name: 'schools'});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    async removeFromSchool() {
      try {
        await this.$store.dispatch('student/removeFromSchool', this.removeStudentFromSchool.student);
        this.removeStudentFromSchool.student = null;
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
