




































import Vue from 'vue';
import {TranslateResult} from 'vue-i18n';

export default Vue.extend({
  name: 'licence-dialog',
  props: {
    value: {type: Boolean, default: false},
    licence: {type: Object, default: null},
    databaseId: {type: Number},
  },
  data() {
    return {
      isValid: true,
      formDefaults: null,
      form: {
        language: 'de',
        name: '',
      },
    };
  },
  watch: {
    licence: {
      immediate: true,
      handler() {
        this.formDefaults = this.licence ? {language: this.licence.language, name: this.licence.name} : {language: 'de', name: ''};
        this.reset();
      },
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    headline(): TranslateResult {
      return this.licence ? this.$t('action.editItem', {item: this.$tc('p.pool')}) : this.$t('action.createItem', {item: this.$tc('p.pool')});
    },
    languages(): any[] {
      return [
        {value: 'de', text: this.$t('language.german')},
        {value: 'en', text: this.$t('language.english')},
      ];
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      try {
        if (this.licence) {
          const payload = {id: this.licence.id, data: this.form};
          await this.$store.dispatch('question/updateLicence', payload);
          await this.$store.dispatch('showSuccess', this.$t('msg.updateSuccess', {item: this.$tc('p.pool')}));
          this.$emit('update');
        } else {
          await this.$store.dispatch('question/createLicence', {databaseId: this.databaseId, data: this.form});
          await this.$store.dispatch('showSuccess', this.$t('msg.addSuccess', {item: this.$tc('p.pool')}));
        }
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
