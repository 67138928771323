import {Module} from 'vuex';
import {api} from '~/util/api';
import {CourseDocument, Document, DocumentVersion} from '../models';

const UPDATE_THRESHOLD = 60000; // 60 sek

export interface DocumentState {
  search: string,
  fetchedAt: number,
  optionsFetchedAt: number,
  documentFetchedAt: { [id: number]: number },
}

export const documentModule: Module<DocumentState, any> = {
  namespaced: true,
  state: {
    search: '',
    fetchedAt: 0,
    optionsFetchedAt: 0,
    documentFetchedAt: {},
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
  },
  actions: {
    updateSearch: async (context, search) => {
      context.commit('SET_SEARCH', search);
    },
    fetchDocuments: async (context) => {
      if (Date.now() - context.state.fetchedAt > UPDATE_THRESHOLD) {
        context.state.fetchedAt = Date.now();
        const response = await api.get('/api/document');
        await Document.insert({data: response.data});
      }
    },
    fetchDocumentsOptions: async (context) => {
      if (Date.now() - context.state.optionsFetchedAt > UPDATE_THRESHOLD) {
        context.state.optionsFetchedAt = Date.now();
        const response = await api.get('/api/document/options');
        await Document.insertOrUpdate({data: response.data});
      }
    },
    fetchDocument: async (context, id) => {
      if (!context.state.documentFetchedAt[id] || Date.now() - context.state.documentFetchedAt[id] > UPDATE_THRESHOLD) {
        context.state.documentFetchedAt[id] = Date.now();
        const response = await api.get('/api/document/' + id);
        await Document.insert({data: response.data});
      }
    },
    createDocument: async (context, payload) => {
      const response = await api.post('/api/document', payload);
      await Document.insert({data: response.data});
    },
    updateDocument: async (context, payload) => {
      const response = await api.put(`/api/document/${payload.id}`, payload.data);
      await CourseDocument.delete(cd => cd.documentId === payload.id);
      await Document.insert({data: response.data});
    },
    updateVersion: async (context, payload) => {
      const response = await api.put(`/api/document/version/${payload.id}`, payload.data);
      await DocumentVersion.insert({data: response.data});
    },
    uploadVersion: async (context, payload) => {
      const response = await api.post(`/api/document/${payload.id}/version`, payload.data);
      response.data.documentId = payload.id;
      await DocumentVersion.insert({data: response.data});
    },
    deleteDocument: async (context, document) => {
      await api.delete(`/api/document/${document.id}`);
      await Document.delete(document.id);
    },
    deleteVersion: async (context, version) => {
      await api.delete(`/api/document/version/${version.id}`);
      await DocumentVersion.delete(version.id);
    },
  },
  getters: {},
};
