import {Model} from '@vuex-orm/core';
import {Database, LicenceQuestion, Question, Subject} from '.';

export class Licence extends Model {
  static entity = 'licences';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      language: this.attr(''),
      isCustom: this.attr(false),
      subjects: this.hasMany(Subject, 'licenceId'),
      databaseId: this.attr(null),
      database: this.belongsTo(Database, 'databaseId'),
      questions: this.belongsToMany(Question, LicenceQuestion, 'licenceId', 'questionId'),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
    };
  }

  id: number;
  name: string;
  language: string;
  isCustom: boolean;
  databaseId: number;
  database: Database;
  subjects: Subject[];
  questions: Question[];
  createdAt: string;
  updatedAt: string;

  get fullName() {
    return `${this.name} (${this.language})`;
  }
}
