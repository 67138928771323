import { Module } from 'vuex';
import { Admin } from '~/models';
import { store } from '~/store';
import { api } from '~/util/api';

let unwatch;

function setAuthPromise(state: any) {
  state.authPromise = new Promise<void>(resolve => {
    unwatch = store.watch((state: any) => state.auth.isAuthenticated, (isAuthenticated) => {
      if (isAuthenticated) {
        resolve();
      }
    });
  });
}

export interface AuthState {
  isAuthenticated: boolean,
  unavailable: boolean,
  accessToken: string,
  isAuthPending: boolean,
  refreshTimeoutId: number,
  user?: Admin,
  authPromise: any,
}

export const authModule: Module<AuthState, any> = {
  namespaced: true,
  state: {
    isAuthPending: true,
    isAuthenticated: false,
    unavailable: false,
    accessToken: '',
    refreshTimeoutId: 0,
    user: null,
    authPromise: undefined,
  },
  mutations: {
    backendUnavailable(state) {
      state.isAuthenticated = false;
      state.unavailable = true;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setProfile(state, user) {
      state.user = user;
      state.isAuthenticated = true;
      state.isAuthPending = false;
    },
    signedOut(state) {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.user = null;
      state.authPromise = undefined;
    },
    setAuthPending(state, isPending) {
      state.isAuthPending = isPending;
    },
  },
  actions: {
    async untilAuthenticated({state}) {
      if (!state.authPromise) {
        setAuthPromise(state);
      }
      await state.authPromise;
      unwatch();
    },
    async signIn({commit, dispatch}, credentials) {
      const response = await api.post('/api/auth/sign-in', credentials);
      commit('setAccessToken', response.data.token);
      commit('setProfile', response.data.profile);
      dispatch('renewToken', response.data.token);
    },
    async signInByToken({commit, dispatch}) {
      try {
        const response = await api.post('/api/auth/refresh');
        commit('setAccessToken', response.data.token);
        commit('setProfile', response.data.profile);
        dispatch('renewToken', response.data.token);
      } catch (err) {
        commit('setAuthPending', false);
      }
    },
    async signOut(context) {
      clearTimeout(context.state.refreshTimeoutId);
      await api.get('/api/auth/sign-out');
      context.commit('signedOut');
    },
    async refreshToken({commit, dispatch}) {
      const response = await api.post('/api/auth/refresh');
      commit('setAccessToken', response.data.token);
      dispatch('renewToken', response.data.token);
    },
    renewToken(context, token) {
      const payloadString = token.split('.')[1];
      const payload = JSON.parse(atob(payloadString));
      const expiresIn = payload.exp * 1000 - Date.now();

      // request fresh access-token 10s before it will expire
      context.state.refreshTimeoutId = setTimeout(() => context.dispatch('refreshToken'), expiresIn - 10000);
    },
  },
  getters: {},
};
