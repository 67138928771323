import VuexORM from '@vuex-orm/core';
import Vue from 'vue';
import Vuex from 'vuex';
import { models } from '~/models';
import { questionModule } from '~/store/question.module';
import { roleModule } from '~/store/role.module';
import { snackbarModule } from '~/store/snackbar.module';
import { testGroupModule } from '~/store/testGroup.module';
import { adminModule } from './admin.module';
import { appModule } from './app.module';
import { authModule } from './auth.module';
import { courseModule } from './course.module';
import { documentModule } from './document.module';
import { permissionModule } from './permission.module';
import { schoolModule } from './school.module';
import { screenModule } from './screen.module';
import { studentModule } from './student.module';
import { testModule } from './test.module';

Vue.use(Vuex);

const database = new VuexORM.Database();
models.forEach(model => database.register(model));

export const store = new Vuex.Store({
  plugins: [VuexORM.install(database)],
  modules: {
    permission: permissionModule,
    role: roleModule,
    admin: adminModule,
    app: appModule,
    auth: authModule,
    screen: screenModule,
    document: documentModule,
    course: courseModule,
    student: studentModule,
    school: schoolModule,
    question: questionModule,
    snackbar: snackbarModule,
    test: testModule,
    testGroup: testGroupModule,
  },
  state: {
    untilAuthenticatedPromise: null,
  },
  actions: {},
  mutations: {},
  getters: {
    async waitForAuth() {
      // @ts-ignore
      if (!store.state.auth.isAuthenticated) {
        await store.dispatch('auth/untilAuthenticated');
      }
    },
    hasPermission: () => (permission: string) => {
      // @ts-ignore
      const user = store.state.auth.user;
      if (!user) {
        return false;
      }
      let permissions = user.roles.reduce((permissions, role) => permissions.concat(role.permissions), user.permissions).map(p => p.name);
      permissions = [...new Set(permissions)];
      return permissions.includes(permission);
    },
    hasRole: () => (role: string) => {
      // @ts-ignore
      const user = store.state.auth.user;
      if (!user) {
        return false;
      }
      return user.roles.reduce((result, r) => result || r.name === role, false);
    },
  }
  ,
});
