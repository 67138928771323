import {Model} from '@vuex-orm/core';
import {DatabaseQuestion, Licence, Question} from '.';

export class Database extends Model {
  static entity = 'databases';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      licences: this.hasMany(Licence, 'databaseId'),
      questions: this.belongsToMany(Question, DatabaseQuestion, 'licenceId', 'questionId'),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
    };
  }

  id: number;
  name: string;
  licences: Licence[];
  questions: Question[];
  createdAt: string;
  updatedAt: string;
}
