






import {Chart as highcharts} from 'highcharts-vue';
import Vue from 'vue';

export default Vue.extend({
  components: {highcharts},
  props: {
    options: {type: Object},
  },
  data() {
    return {};
  },
  methods: {
    sort(seriesIndex) {
      const serie = this.options.series[seriesIndex];
      const xaxis = this.options.xAxis;

      serie.data = serie.data.sort((a, b) => a.y > b.y ? 1 : b.y > a.y ? -1 : 0);
      xaxis.categories = serie.data.map(p => xaxis.categories[p.x]);

      this.options.series.forEach((s, idx) => {
        if (idx !== seriesIndex) {
          s.data = xaxis.categories.map(c => s.data.find(d => d.id === c.id));
        }
      });
    },
  },
});
