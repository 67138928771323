





































import Vue from 'vue';
import AdminDialog from '~/components/admin/AdminDialog.vue';
import {DataTable, DeleteDialog, TooltipButton, TooltipDate} from '~/components/common';
import {Admin} from '~/models';

export default Vue.extend({
  name: 'AdminTable',
  components: {TooltipDate, TooltipButton, DeleteDialog, DataTable, AdminDialog},
  props: {
    search: {type: String, default: ''},
    dense: {type: Boolean, default: false},
    actionsWidth: {type: String, default: null},
    hideFields: {type: Array, default: () => []},
    actions: {type: Array, default: () => ['edit', 'delete']},
    itemsPerPage: {type: Number, default: -1},
    expansion: {type: Boolean, default: false},
  },
  data() {
    return {
      isLoading: false,
      edit: {dialog: false, admin: null},
      remove: {dialog: false, admin: null},
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.$store.dispatch('admin/fetchAdmins');
    } catch (err) {
      if (err.response.status === 403) {
        await this.$store.dispatch('showError', err.response.data.message);
      }
    }
    this.isLoading = false;
  },
  computed: {
    items(): Admin[] {
      return Admin.query().with('roles|permissions').all();
    },
    additionalActions(): any[] {
      return this.actions.filter((action: any) => action && action.icon && action.tooltip && action.emit);
    },
    headers(): any[] {
      const fields = [
        {text: this.$t('label.email'), value: 'email'},
        {text: this.$t('label.firstName'), value: 'firstName'},
        {text: this.$t('label.lastName'), value: 'lastName'},
        {text: this.$tc('p.role', 2), value: 'roles'},
        {text: this.$tc('p.permission', 2), value: 'permissions'},
        {text: this.$t('label.lastLogin'), value: 'lastLoginAt', align: 'end', class: 'sm-and-up'},
        {text: this.$t('label.actions'), value: 'actions', align: 'center', sortable: false, width: this.actionsWidth || this.actions.length * 52 + 'px'},
      ];
      return fields.filter(field => !this.hideFields.includes(field.value));
    },
  },
  methods: {
    searchFilter(title: string, search: string, admin: Admin) {
      const searchTerms = search.toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && (admin.name.toLowerCase().includes(term) || admin.email.toLowerCase().includes(term)), true);
    },
    showEditDialog(admin) {
      this.edit.dialog = true;
      this.edit.admin = admin;
    },
    showDeleteDialog(admin) {
      this.remove.dialog = true;
      this.remove.admin = admin;
    },
    showField(field: string) {
      return !this.hideFields.includes(field);
    },
    async deleteAdmin() {
      try {
        await this.$store.dispatch('admin/deleteAdmin', this.remove.admin);
        this.remove.admin = null;
        const msg = this.$t('msg.deleteSuccess', {item: this.$tc('p.admin')});
        await this.$store.dispatch('showSuccess', msg);
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
