























































import Vue from 'vue';
import {DeleteDialog, TableRow, TooltipButton, TooltipDate} from '~/components/common';
import {RoleDialog} from '~/components/role';
import {Role} from '~/models';

export default Vue.extend({
  components: {
    TooltipButton,
    TooltipDate,
    TableRow,
    RoleDialog,
    DeleteDialog,
  },
  data() {
    return {
      editDialog: false,
      removeDialog: false,
    };
  },
  async created() {
    await this.$store.dispatch('role/fetchRole', this.$route.params.id);
    document.title = `LTMS - Role: ${this.role.name}`;
  },
  computed: {
    role(): Role {
      return Role.query().with('permissions|admins').find(this.$route.params.id);
    },
    breadcrumbs(): any[] {
      return [
        {text: this.$t('nav.roles'), to: {name: 'roles'}, exact: true},
        {text: this.role?.name || this.$route.params.id, disabled: true},
      ];
    },
  },
  methods: {
    async deleteRole() {
      try {
        await this.$store.dispatch('role/deleteRole', this.role);
        await this.$store.dispatch('showSuccess', this.$t('msg.deleteSuccess', {item: this.$tc('p.role')}));
        await this.$router.push({name: 'roles'});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
