








import Vue from 'vue';
import {ScreenState} from '~/store/screen.module';

export default Vue.extend({
  name: 'preview-dialog',
  props: {
    value: {type: Boolean, default: false},
    url: {type: String, default: ''},
  },
  computed: {
    screen(): ScreenState {
      return this.$store.state.screen;
    },
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    previewUrlWithToken(): string {
      return this.url + '?token=' + this.$store.state.auth.accessToken;
    },
    iframeHeight(): string {
      return (this.screen.height - 200) + 'px';
    },
    iframeStyle(): any {
      return {
        height: (this.screen.height - 195) + 'px',
        overflow: 'hidden',
      };
    },
  },
});
