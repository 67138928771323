














































import Vue from 'vue';
import {DeleteDialog, TooltipButton} from '~/components/common';
import {CourseTable} from '~/components/course';
import {DocumentDialog, DocumentInfoTable, VersionTable, VersionUploadDialog} from '~/components/document';
import {Document} from '~/models';

export default Vue.extend({
  components: {
    TooltipButton,
    DeleteDialog,
    VersionTable,
    CourseTable,
    DocumentDialog,
    VersionUploadDialog,
    DocumentInfoTable,
  },
  data() {
    return {
      editDialog: false,
      removeDialog: false,
      uploadDialog: false,
    };
  },
  async created() {
    await this.$store.dispatch('document/fetchDocument', this.$route.params.id);
    document.title = `LTMS - Document: ${this.document.title}`;
  },
  computed: {
    document(): Document {
      return Document.query().whereId(this.$route.params.id).with('versions').with('courses.documents|students').first();
    },
    breadcrumbs(): any[] {
      return [
        {text: this.$t('nav.documents'), to: {name: 'documents'}, exact: true},
        {text: this.document?.title || this.$route.params.id, disabled: true},
      ];
    },
  },
  methods: {
    async deleteDocument() {
      try {
        const docTitle = this.document.title;
        await this.$store.dispatch('document/deleteDocument', this.document);
        await this.$store.dispatch('showSuccess', this.$t('msg.deleteSuccess', {item: docTitle}));
        await this.$router.push({name: 'documents'});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
