import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import {i18n, vuetify} from './plugins';
import {router} from './router';
import {store} from './store';

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
});

app.$mount('#app');
