import {Model} from '@vuex-orm/core';

export class RolePermission extends Model {
  static entity = 'role_permissions';

  static primaryKey = ['roleId', 'permissionId'];

  static fields() {
    return {
      roleId: this.attr(null),
      permissionId: this.attr(null),
    };
  }

  roleId: number;
  permissionId: number;
}
