















































































































































import {isNumeric} from 'rxjs/internal-compatibility';
import Vue from 'vue';
import {TooltipButton} from '~/components/common';
import {QuestionTable} from '~/components/question';
import QuestionSelector from '~/components/question/QuestionSelector.vue';
import {testConstraints, testRules} from '~/components/test/test.rules';
import {Area, Licence, SubArea, Subject} from '~/models';

export default Vue.extend({
  components: {QuestionSelector, QuestionTable, TooltipButton},
  data() {
    return {
      search: '',
      isValid: false,
      rule: testRules.call(this),
      constraint: testConstraints,
      count: {
        licence: {},
        subject: {},
        area: {},
        subArea: {},
      },
      options: {
        tests: [],
      },
      form: {
        title: '',
        displayName: '',
        tries: 3,
        type: 'default',
        coolDown: 0,
        passRate: 75,
        language: 'de',
        timeLimit: null,
        feedback: null,
        dependentOn: null,
        licences: [],
        subjects: [],
        areas: [],
        subAreas: [],
        questions: [],
        eval: {
          licence: null,
        },
        limit: {
          licence: {},
          subject: {},
          area: {},
          subArea: {},
        },
      },
    };
  },
  async created() {
    const tests = await this.$store.dispatch('test/fetchOptions', {insert: false});
    this.options.tests = tests.filter(test => test.state === 'active');
    document.title = 'LTMS - Test Creation';
  },
  watch: {
    'form.title'(newValue, oldValue) {
      if (oldValue === this.form.displayName) {
        this.form.displayName = newValue;
      }
    },
  },
  computed: {
    isPreExam(): boolean {
      return this.form.type === 'preExam';
    },
    licences(): Licence[] {
      return Licence.all().sort((a, b) => a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : 0);
    },
    languages(): any[] {
      return [
        {value: 'de', text: this.$t('language.german')},
        {value: 'en', text: this.$t('language.english')},
      ];
    },
    feedbackItems(): any[] {
      return [
        {value: 'optional', text: this.$t('label.optional')},
        {value: 'mandatory', text: this.$t('label.mandatory')},
      ];
    },
    testTypes() {
      return [
        {text: this.$t('label.default'), value: 'default'},
        {text: this.$t('label.progressTest'), value: 'progress'},
        {text: this.$t('label.finalTest'), value: 'final'},
        {text: this.$t('label.preExam'), value: 'preExam'},
      ];
    },
    sectionHeaders() {
      return [
        {text: 'name', value: 'fullName'},
        {text: 'limit', value: 'limit', width: '120px'},
        {text: 'actions', value: 'actions', width: '40px'},
      ];
    },
    preExamTimeLimitRule(): any[] {
      return [
        (v: string) => !!v || this.$t('form.isRequired', {field: this.$t('label.timeLimit')}),
        (v: number) => v >= 1 || this.$t('form.minValue', {field: this.$t('label.timeLimit'), val: 1}),
      ];
    },
  },
  methods: {
    customTestFilter(item, search) {
      const searchTerms = search.trim().toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && item.title.toLowerCase().includes(term), true);
    },
    customLicenceFilter(item, search) {
      const searchTerms = search.trim().toLowerCase().split(' ');
      return searchTerms.reduce((result, term) => result && item.fullName.toLowerCase().includes(term), true);
    },
    onTypeChange(type) {
      switch (type) {
        case 'preExam':
          this.form.tries = 1;
          this.form.passRate = 85;
          this.form.coolDown = 0;
          break;
        case 'final':
          this.form.coolDown = 72;
          this.form.passRate = 75;
          this.form.tries = 3;
          break;
        case 'progress':
          this.form.coolDown = 24;
          this.form.passRate = 75;
          this.form.tries = 3;
          break;
      }
      this.$nextTick(() => (this.$refs.form as HTMLFormElement).validate());
    },
    removeLicence(licence) {
      const i = this.form.licences.findIndex(s => s.id === licence.id);
      this.form.licences.splice(i, 1);
    },
    removeSubject(subject) {
      const i = this.form.subjects.findIndex(s => s.id === subject.id);
      this.form.subjects.splice(i, 1);
    },
    removeArea(area) {
      const i = this.form.areas.findIndex(a => a.id === area.id);
      this.form.areas.splice(i, 1);
    },
    removeSubArea(subArea) {
      const i = this.form.subAreas.findIndex(sa => sa.id === subArea.id);
      this.form.subAreas.splice(i, 1);
    },
    removeQuestion(question) {
      const i = this.form.questions.findIndex(q => q.id === question.id);
      this.form.questions.splice(i, 1);
    },
    async addLicences(licencesIds) {
      const newLicenceIds = licencesIds.filter(id => !this.form.licences.find(s => s.id === id));
      const licences = Licence.findIn(newLicenceIds);
      const counts = await this.$store.dispatch('question/getCount', {type: 'licence', ids: licences.map(l => l.id)});
      Object.keys(counts).forEach(key => this.count.licence[key] = String(counts[key]));
      this.form.licences = this.form.licences.concat(licences);
    },
    async addSubjects(subjectsIds) {
      const newSubjectIds = subjectsIds.filter(id => !this.form.subjects.find(s => s.id === id));
      const subjects = Subject.query().whereIdIn(newSubjectIds).with('licence').all();
      const counts = await this.$store.dispatch('question/getCount', {type: 'subject', ids: subjects.map(l => l.id)});
      Object.keys(counts).forEach(key => this.count.subject[key] = String(counts[key]));
      this.form.subjects = this.form.subjects.concat(subjects);
    },
    async addAreas(areaIds) {
      const newAreaIds = areaIds.filter(id => !this.form.areas.find(s => s.id === id));
      const areas = Area.query().whereIdIn(newAreaIds).with('subject.licence').all();
      const counts = await this.$store.dispatch('question/getCount', {type: 'area', ids: areas.map(l => l.id)});
      Object.keys(counts).forEach(key => this.count.area[key] = String(counts[key]));
      this.form.areas = this.form.areas.concat(areas);
    },
    async addSubAreas(subAreaIds) {
      const newSubAreaIds = subAreaIds.filter(id => !this.form.subAreas.find(s => s.id === id));
      const subAreas = SubArea.query().whereIdIn(newSubAreaIds).with('area.subject.licence').all();
      const counts = await this.$store.dispatch('question/getCount', {type: 'subArea', ids: subAreas.map(l => l.id)});
      Object.keys(counts).forEach(key => this.count.subArea[key] = String(counts[key]));
      this.form.subAreas = this.form.subAreas.concat(subAreas);
    },
    addQuestions(questions) {
      questions.forEach(question => {
        const storedQuestion = this.form.questions.find(q => q.id === question.id);
        if (!storedQuestion) {
          this.form.questions.push(question);
        }
      });
    },
    async createTest() {
      try {
        const payload = Object.assign({}, this.form);
        if (!isNumeric(payload.timeLimit)) {
          delete payload.timeLimit;
        }
        if (!isNumeric(payload.dependentOn)) {
          delete payload.dependentOn;
        }
        payload.licences = payload.licences.map(item => item.id);
        payload.subjects = payload.subjects.map(item => item.id);
        payload.areas = payload.areas.map(item => item.id);
        payload.subAreas = payload.subAreas.map(item => item.id);
        payload.questions = payload.questions.map(item => item.id);
        const test = await this.$store.dispatch('test/create', payload);
        await this.$router.push({name: 'test-details', params: {id: test.id}});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
