






















































































































import Vue from 'vue';
import {DeleteDialog, TooltipButton, TooltipDate} from '~/components/common';
import {CourseTable} from '~/components/course';
import {AssignTestsDialog, ChangePasswordDialog, DocumentOverview, StudentEditDialog, StudentInfo, TestStateDialog} from '~/components/student';
import {Student, Test, TestInstance} from '~/models';

export default Vue.extend({
  components: {
    DocumentOverview,
    CourseTable,
    DeleteDialog,
    StudentEditDialog,
    ChangePasswordDialog,
    TooltipButton,
    TooltipDate,
    StudentInfo,
    AssignTestsDialog,
    TestStateDialog,
  },
  data() {
    return {
      edit: {dialog: false},
      remove: {dialog: false},
      changePassword: {dialog: false},
      assignTests: {dialog: false},
      authorize: {dialog: false, testState: null},
    };
  },
  async created() {
    await this.$store.dispatch('student/fetchStudent', this.$route.params.id);
    document.title = `LTMS - Student: ${this.student.fullName}`;
  },
  computed: {
    student(): Student {
      return Student.query()
          .with('tests')
          .with('extraTryTests')
          .with('testGroups.tests')
          .with('devices')
          .with('courses.documents')
          .with('documents')
          .with('school.classes')
          .with('schoolClass')
          .with('studentTestStates')
          .with('testInstances.test')
          .find(this.$route.params.id);
    },
    testGroups(): any[] {
      let testGroups = [].concat(this.student.testGroups);
      testGroups.unshift({title: this.$t('label.directlyAssignedTests'), tests: this.student.tests} as any);
      return testGroups;
    },
    breadcrumbs(): any[] {
      return [
        {text: this.$t('nav.students'), to: {name: 'students'}, exact: true},
        {text: this.student ? this.student.login : this.$route.params.id, disabled: true},
      ];
    },
    testGroupHeaders(): any[] {
      return [
        {text: this.$t('label.title'), value: 'title'},
        {text: this.$tc('p.test', 2), value: 'tests.length'},
        {text: '', value: 'actions', sortable: false, width: '60px'},
      ];
    },
    testHeaders(): any[] {
      return [
        {text: this.$t('label.title'), value: 'title'},
        {text: this.$t('label.timeLimit'), value: 'timeLimit'},
        {text: this.$t('label.passRate'), value: 'passRate'},
        {text: this.$tc('p.try', 2), value: 'tries'},
        {text: this.$t('label.actions'), value: 'actions', align: 'center', width: '105px', sortable: false},
      ];
    },
    testInstanceHeaders(): any[] {
      return [
        {text: this.$t('label.title'), value: 'title'},
        {text: this.$tc('p.correctAnswer', 2), value: 'correctAnswers'},
        {text: this.$tc('p.try'), value: 'try'},
        {text: this.$tc('label.passed'), value: 'passed'},
        {text: this.$t('label.finishedAt'), value: 'finishedAt'},
      ];
    },
  },
  methods: {
    async onAuthorizeClick(test: Test) {
      this.authorize.dialog = true;
      this.authorize.testState = this.student.studentTestStates.find(sts => sts.testId === test.id) || {studentId: this.student.id, testId: test.id};
    },
    async deleteStudent() {
      try {
        await this.$store.dispatch('student/deleteStudent', this.student.id);
        this.remove.dialog = false;
        await this.$router.push({name: 'students'});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    removeDocument(student, document) {
      const index = student.documents.findIndex(d => d.id === document.id);
      if (index >= 0) {
        student.documents.splice(index, 1);
      }
    },
    hasExtraTry(test: Test) {
      return !!this.student.extraTryTests.find(extraTryTest => extraTryTest.id === test.id);
    },
    async toggleExtraTry(test: Test) {
      if (this.hasExtraTry(test)) {
        return await this.$store.dispatch('test/removeAdditionalTry', {studentId: this.student.id, testId: test.id});
      }
      return await this.$store.dispatch('test/giveAdditionalTry', {studentId: this.student.id, testId: test.id});
    },
    getColorClass(item: TestInstance) {
      if (item.passed) {
        return 'instance-green';
      }
      if (item.try < item.test?.tries) {
        return 'instance-orange';
      }

      const latestTestInstance = this.student.testInstances.filter(ti => ti.testId === item.testId).sort((a, b) => a.try < b.try ? 1 : a.try > b.try ? -1 : 0)[0];
      if (latestTestInstance.id === item.id) {
        return 'instance-red';
      }

      return 'instance-orange';
    },
  },
});
