import {Model} from '@vuex-orm/core';

export class StudentActiveVersion extends Model {
  static entity = 'student_active-versions';

  static primaryKey = ['studentId', 'documentVersionId'];

  static fields() {
    return {
      studentId: this.attr(null),
      documentVersionId: this.attr(null),
    };
  }

  studentId: string;
  documentVersionId: string;
}
