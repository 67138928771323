




import Vue from 'vue';

export default Vue.extend({
  name: 'flag-icon',
  props: {
    locale: {type: String, required: true},
  },
  data() {
    return {
      languages: [
        {locale: 'de', name: 'Deutsch'},
        {locale: 'en', name: 'English'},
      ],
    };
  },
  computed: {
    flag(): string {
      if (this.$props.locale === 'en') {
        return 'gb';
      }
      return this.$props.locale;
    },
    cssClass(): string {
      return `flag-icon flag-icon-${this.flag}`;
    },
  },
});
