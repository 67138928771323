import {Model} from '@vuex-orm/core';
import {Admin, AdminPermission, Role, RolePermission} from '~/models';

export class Permission extends Model {
  static entity = 'permissions';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
      roles: this.belongsToMany(Role, RolePermission, 'permissionId', 'roleId'),
      admins: this.belongsToMany(Admin, AdminPermission, 'permissionId', 'adminId'),
    };
  }

  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  roles: Role[];
  admins: Admin[];
}
