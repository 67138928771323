


































import Vue from 'vue';
import {TranslateResult} from 'vue-i18n';
import {Permission} from '~/models';
import {roleConstraints, roleRules} from './role.rules';

export default Vue.extend({
  name: 'RoleDialog',
  props: {
    value: {type: Boolean, default: false},
    role: {type: Object, default: null},
  },
  data() {
    return {
      isLoading: false,
      isValid: true,
      formDefaults: null,
      form: {
        name: '',
        permissions: [],
      },
      rules: roleRules.call(this),
      constraint: roleConstraints,
    };
  },
  watch: {
    async value(value) {
      if (value) {
        this.isLoading = true;
        await this.$store.dispatch('permission/fetchPermissions');
        this.setFormDefaults();
        this.isLoading = false;
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    headline(): TranslateResult {
      return this.role ? this.$t('action.editItem', {item: this.$tc('p.role')}) : this.$t('action.createItem', {item: this.$tc('p.role')});
    },
    permissions(): Permission[] {
      return Permission.all();
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      try {
        let msg;
        if (this.role) {
          const data = this.form;
          await this.$store.dispatch('role/updateRole', {id: this.role.id, data});
          msg = this.$t('msg.updateSuccess', {item: this.$tc('p.role')});
        } else {
          await this.$store.dispatch('role/createRole', this.form);
          msg = this.$t('msg.addSuccess', {item: this.$tc('p.role')});
        }
        await this.$store.dispatch('showSuccess', msg);
        this.reset();
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('showError', this.getError(err));
      }
    },
    getError(err) {
      return err;
    },
    setFormDefaults() {
      if (this.role) {
        this.formDefaults = {
          name: this.role.name,
          permissions: this.role.permissions.map(permission => permission.id),
        };
      } else {
        this.formDefaults = Object.assign({}, this.form);
      }
      this.reset();
    },
    reset() {
      this.form = Object.assign({}, this.formDefaults);
      (this.$refs.form as HTMLFormElement)?.resetValidation();
    },
  },
});
