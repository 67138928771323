
























import Vue from 'vue';
import { UserRole } from '~/util';

export default Vue.extend({
  name: 'app-sidebar',
  data() {
    return {
      userItems: [
        {icon: 'dashboard', title: 'nav.dashboard', route: 'dashboard'},
        {icon: 'picture_as_pdf', title: 'nav.documents', route: 'documents'},
        {icon: 'person', title: 'nav.students', route: 'students'},
        {icon: 'library_books', title: 'nav.courses', route: 'courses'},
        {icon: 'school', title: 'nav.schools', route: 'schools'},
        {icon: 'mdi-comment-question', title: 'nav.questions', route: 'questions'},
        {icon: 'mdi-file-document-box-check', title: 'nav.tests', route: 'tests'},
        {icon: 'mdi-file-document-box-check-outline', title: 'nav.testInstances', route: 'test-instances'},
        {icon: 'mdi-checkbox-multiple-marked', title: 'nav.testGroups', route: 'test-groups'},
        {icon: 'mdi-chart-bar', title: 'nav.analysis', route: 'analysis'},
        {icon: 'mdi-finance', title: 'nav.progressTracker', route: 'progress-tracker'},
        {icon: 'mdi-shield-account', title: 'nav.administration', route: 'administration', condition: () => this.$store.getters.hasPermission('frontend:administration')},
      ],
      authorItems: [
        {icon: 'mdi-file-document-box-check-outline', title: 'nav.testInstances', route: 'test-instances'},
        {icon: 'mdi-file-document-box-check', title: 'nav.tests', route: 'tests'},
        {icon: 'mdi-comment-question', title: 'nav.questions', route: 'questions'},
        {icon: 'mdi-chart-bar', title: 'nav.analysis', route: 'analysis'},
      ],
    };
  },
  computed: {
    screen() {
      return this.$store.state.screen;
    },
    app() {
      return this.$store.state.app;
    },
    items(): any[] {
      return this.$store.getters.hasRole(UserRole.Author) ? this.authorItems : this.userItems;
    },
  },
  methods: {
    hideSidebar() {
      this.$store.dispatch('app/showSidebar', false);
    },
  },
});
