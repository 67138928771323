
































































import Vue from 'vue';
import {DeleteDialog, TableRow, TooltipButton, TooltipDate} from '~/components/common';
import {CourseTable} from '~/components/course';
import {DocumentTable} from '~/components/document';
import {SchoolClassDialog} from '~/components/school';
import {StudentTable} from '~/components/student';
import {SchoolClass} from '~/models';

export default Vue.extend({
  components: {
    TooltipDate,
    TooltipButton,
    TableRow,
    StudentTable,
    DocumentTable,
    CourseTable,
    DeleteDialog,
    SchoolClassDialog,
  },
  data() {
    return {
      edit: {dialog: false},
      remove: {dialog: false},
    };
  },
  async created() {
    await this.$store.dispatch('school/fetchSchoolClass', this.$route.params.id);
    document.title = `LTMS - SchoolClass: ${this.schoolClass.name}`;
  },
  computed: {
    schoolClass(): SchoolClass {
      return SchoolClass.query()
          .with('school')
          .with('students.devices|courses')
          .with('documents.versions')
          .with('courses.students|documents')
          .find(this.$route.params.id);
    },
    breadcrumbs(): any[] {
      return [
        {text: this.$t('nav.schools'), to: {name: 'schools'}, exact: true},
        {text: this.schoolClass?.school?.name, to: {name: 'school-details', params: {id: this.schoolClass?.school?.id}}, exact: true},
        {text: this.schoolClass?.name, disabled: true},
      ];
    },
  },
  methods: {
    async deleteSchoolClass() {
      try {
        const schoolId = this.schoolClass.school.id;
        await this.$store.dispatch('school/deleteSchoolClass', this.schoolClass);
        await this.$store.dispatch('showSuccess', this.$t('msg.deleteSuccess', {item: this.$tc('p.schoolClass')}));
        await this.$router.push({name: 'school-details', params: {id: schoolId}});
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
