import {Model} from '@vuex-orm/core';

export class SchoolClassDocument extends Model {
  static entity = 'school-class_documents';

  static primaryKey = ['schoolClassId', 'documentId'];

  static fields() {
    return {
      schoolClassId: this.attr(null),
      documentId: this.attr(null),
    };
  }

  schoolClassId: string;
  documentId: string;
}
