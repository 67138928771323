import {Model} from '@vuex-orm/core';
import {TestArea} from '~/models/test/relational/TestArea';
import {TestLicence} from '~/models/test/relational/TestLicence';
import {TestSubArea} from '~/models/test/relational/TestSubArea';
import {TestSubject} from '~/models/test/relational/TestSubject';
import {ExtraTryTests, Licence, Question, School, SchoolTest, Student, StudentTestState, TestGroup, TestGroupTest, TestInstance, TestQuestion, TestStudent} from '..';

export class Test extends Model {
  static entity = 'tests';

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(''),
      displayName: this.attr(''),
      passRate: this.attr(null),
      timeLimit: this.attr(null),
      type: this.attr(null),
      coolDown: this.attr(null),
      tries: this.attr(null),
      language: this.attr(null),
      feedback: this.attr(null),
      state: this.attr(null),
      dependentOnId: this.attr(null),
      dependentOn: this.belongsTo(Test, 'dependentOnId'),
      dependants: this.hasMany(Test, 'dependentOnId'),
      questions: this.belongsToMany(Question, TestQuestion, 'testId', 'questionId'),
      licences: this.hasMany(TestLicence, 'testId'),
      subjects: this.hasMany(TestSubject, 'testId'),
      areas: this.hasMany(TestArea, 'testId'),
      subAreas: this.hasMany(TestSubArea, 'testId'),
      instances: this.hasMany(TestInstance, 'testId'),
      students: this.belongsToMany(Student, TestStudent, 'testId', 'studentId'),
      schools: this.belongsToMany(School, SchoolTest, 'testId', 'schoolId'),
      studentTestStates: this.hasMany(StudentTestState, 'testId'),
      testGroups: this.belongsToMany(TestGroup, TestGroupTest, 'testId', 'testGroupId'),
      extraTryStudents: this.belongsToMany(Student, ExtraTryTests, 'testId', 'studentId'),
      evalLicenceId: this.attr(null),
      evalLicence: this.belongsTo(Licence, 'evalLicenceId'),

      createdAt: this.attr(''),
      updatedAt: this.attr(''),
    };
  }

  id: number;
  title: string;
  displayName: string;
  passRate: number;
  timeLimit: number;
  type: string;
  coolDown: number;
  tries: number;
  language: string;
  feedback: string;
  state: string;
  dependentOnId: number;
  dependentOn: Test;
  dependants: Test;
  questions: Question[];
  licences: TestLicence[];
  instances: TestInstance[];
  subjects: TestSubject[];
  areas: TestArea[];
  subAreas: TestSubArea[];

  testGroups: TestGroup[];
  students: Student[];
  schools: School[];
  studentTestStates: StudentTestState[];
  evalLicence: Licence;
  evalLicenceId: number;

  createdAt: string;
  updatedAt: string;

  get typeLabel(): string {
    switch (this.type) {
      case 'preExam':
        return 'label.preExam';
      case 'final':
        return 'label.finalTest';
      case 'progress':
        return 'label.progressTest';
      default:
        return 'label.default';
    }
  }

  get isDefault(): boolean {
    return this.type === 'default';
  }

  get isPreExam(): boolean {
    return this.type === 'preExam';
  }

  get isFinal(): boolean {
    return this.type === 'final';
  }

  get isProgress(): boolean {
    return this.type === 'progress';
  }
}
