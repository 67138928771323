

























import Vue from 'vue';
import {DataTable, DeleteDialog, TooltipButton} from '~/components/common';
import QuestionEditDialog from '~/components/question/QuestionEditDialog.vue';
import {Question} from '~/models';

export default Vue.extend({
  name: 'QuestionTable',
  components: {DataTable, TooltipButton, DeleteDialog, QuestionEditDialog},
  props: {
    search: {type: String, default: ''},
    dense: {type: Boolean, default: false},
    questions: {type: Array, default: null},
    actionsWidth: {type: String, default: null},
    hideFields: {type: Array, default: () => []},
    actions: {type: Array, default: () => ['edit', 'delete']},
    itemsPerPage: {type: Number, default: 10},
  },
  data() {
    return {
      edit: {dialog: false, item: null},
      remove: {dialog: false, item: null},
    };
  },
  computed: {
    items(): Question[] {
      // const questionIds = this.questions.map((q: any) => q?.id || q);
      // return Question.query().whereIdIn(questionIds).withAll().all();
      return this.questions as Question[];
    },
    additionalActions(): any[] {
      return this.actions.filter((action: any) => action && action.icon && action.tooltip && action.emit);
    },
    headers(): any[] {
      const fields = [
        {text: this.$t('label.number'), value: 'number', width: '97px'},
        {text: this.$t('label.text'), value: 'text'},
        {text: this.$t('label.actions'), value: 'actions', align: 'center', sortable: false, width: this.actionsWidth || this.actions.length * 52 + 'px'},
      ];
      return fields.filter(field => !this.hideFields.includes(field.value));
    },
  },
  methods: {
    showField(field: string) {
      return !this.hideFields.includes(field);
    },
    showEditDialog(item: Question) {
      this.edit.dialog = true;
      this.edit.item = item;
    },
    showDeleteDialog(item: Question) {
      this.remove.dialog = true;
      this.remove.item = item;
    },
    async deleteQuestion() {
      await this.$store.dispatch('question/delete', this.remove.item);
    },
  },
});
