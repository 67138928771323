





























import Vue from 'vue';
import {StateChip, TableRow, TooltipDate} from '~/components/common';

export default Vue.extend({
  name: 'document-info-table',
  components: {TooltipDate, TableRow, StateChip},
  props: {
    document: {type: Object, required: true},
  },
  computed: {
    downloads(): number {
      return this.document.versions.reduce((amount, version) => amount + version.downloads, 0);
    },
    latestVersion(): string {
      return this.document.latestVersion?.name || '-';
    },
  },
});
