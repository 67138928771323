











import Vue from 'vue';

export default Vue.extend({
  name: 'TableRow',
  props: {
    label: {type: String, required: true},
    verticalAlign: {type: String, default: 'middle'},
  },
});
