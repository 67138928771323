import {Model} from '@vuex-orm/core';
import {
  Answer, Area, AreaQuestion, Database, DatabaseQuestion, ExplanationFigure, Figure, Licence, LicenceQuestion, QuestionFigure, SubArea, SubAreaQuestion, Subject, SubjectQuestion,
} from '.';

export class Question extends Model {
  static entity = 'questions';

  static fields() {
    return {
      id: this.attr(null),
      number: this.attr(''),
      language: this.attr(''),
      text: this.attr(''),
      points: this.attr(null),
      isCustom: this.attr(false),
      explanation: this.attr(''),
      answers: this.hasMany(Answer, 'questionId'),
      databases: this.belongsToMany(Database, DatabaseQuestion, 'questionId', 'databaseId'),
      licences: this.belongsToMany(Licence, LicenceQuestion, 'questionId', 'licenceId'),
      subjects: this.belongsToMany(Subject, SubjectQuestion, 'questionId', 'subjectId'),
      areas: this.belongsToMany(Area, AreaQuestion, 'questionId', 'areaId'),
      subAreas: this.belongsToMany(SubArea, SubAreaQuestion, 'questionId', 'subAreaId'),
      figures: this.belongsToMany(Figure, QuestionFigure, 'questionId', 'figureId'),
      explanationFigures: this.belongsToMany(Figure, ExplanationFigure, 'questionId', 'figureId'),

      createdAt: this.attr(''),
      updatedAt: this.attr(''),
    };
  }

  id: number;
  number: string;
  language: string;
  text: string;
  points: number;
  isCustom: boolean;
  explanation: string;
  answers: Answer[];
  databases: Database[];
  licences: Licence[];
  subjects: Subject[];
  areas: Area[];
  subAreas: SubArea[];
  figures: Figure[];
  explanationFigures: Figure[];

  createdAt: string;
  updatedAt: string;
}
