





































import Vue from 'vue';
import {studentConstraints, studentRules} from '~/components/student/student.rules';
import {Student} from '~/models';

export default Vue.extend({
  name: 'assign-tests-dialog',
  props: {
    value: {type: Boolean, default: false},
    students: {type: Array, default: () => []},
    target: {type: String, default: ''},
  },
  data() {
    return {
      search: '',
      isLoading: false,
      isValid: false,
      form: {
        students: [],
      },
      rules: studentRules.call(this),
      constraint: studentConstraints,
    };
  },
  watch: {
    async value(value) {
      if (value) {
        this.isLoading = true;
        await this.$store.dispatch('student/fetchStudentOptions');
        this.form.students = [].concat(this.students);
        this.isLoading = false;
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    allStudents() {
      return Student.query().withAll().all();
    },
    studentHeaders() {
      return [
        {text: this.$t('label.name'), value: 'fullName'},
        {text: this.$tc('p.school'), value: 'school.name'},
        {text: this.$tc('p.schoolClass'), value: 'schoolClass.name'},
      ];
    },
    filteredStudents(): Student[] {
      return this.allStudents.filter(student => this.customFilter('', this.search, student));
    },
    all(): boolean {
      const reducer = (prev, student) => prev && !!this.form.students.find(s => s.id === student.id);
      return this.filteredStudents.reduce(reducer, true);
    },
    indeterminate(): boolean {
      return this.form.students.length > 0 && !this.all;
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }
      try {
        await this.$emit('confirm', this.form.students);
        this.dialog = false;
        this.search = '';
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
    customFilter(name, search, item: Student) {
      const lowerSearches = search.toLowerCase().split(' ');
      return lowerSearches.reduce((prev, search) => prev &&
          (item.surname.toLowerCase().includes(search)
              || item.forename.toLowerCase().includes(search)
              || item.schoolClass?.name.toLowerCase().includes(search)
              || item.school?.name.toLowerCase().includes(search))
          , true);
    },
    toggleAll(event) {
      if (this.form.students.length > 0) {
        this.form.students = [];
      } else {
        this.form.students = this.allStudents.filter(student => this.customFilter('', this.search, student));
      }
      event.preventDefault();
    },
    close() {
      this.dialog = false;
      this.form.students = [];
      this.search = '';
    },
  },
});
