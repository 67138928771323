import moment from 'moment';

export function datetime(value) {
  return moment(value).format('DD.MM.YYYY - HH:mm:ss');
}

export function date(value) {
  return moment(value).format('DD.MM.YYYY');
}

export function fromNow(value) {
  return moment(value).fromNow();
}

export function upperCase(value) {
  return value.toUpperCase();
}

export function csv(value) {
  return value.join(', ');
}

export function filesize(value) {
  if (value < 1024) {
    return value + ' bytes';
  }
  if (value < Math.pow(1024, 2)) {
    const kb = value / 1024;
    return kb.toFixed(2) + ' Kb';
  }
  if (value < Math.pow(1024, 3)) {
    const mb = value / Math.pow(1024, 2);
    return mb.toFixed(2) + ' Mb';
  }
  const gb = value / Math.pow(1024, 3);
  return gb.toFixed(2) + ' Gb';
}
