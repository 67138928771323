import {Module} from 'vuex';
import {Role, RolePermission} from '~/models';
import {api} from '~/util/api';

const UPDATE_THRESHOLD = 60000; // 60 sek

export interface RoleState {
  search: string,
  fetchedAt: number,
  optionsFetchedAt: number,
  roleFetchedAt: { [id: number]: number },
}

export const roleModule: Module<RoleState, any> = {
  namespaced: true,
  state: {
    search: '',
    fetchedAt: 0,
    optionsFetchedAt: 0,
    roleFetchedAt: {},
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
  },
  actions: {
    updateSearch: async (context, search) => {
      context.commit('SET_SEARCH', search);
    },
    fetchRoles: async (context) => {
      if (Date.now() - context.state.fetchedAt > UPDATE_THRESHOLD) {
        context.state.fetchedAt = Date.now();
        const response = await api.get('/api/role');
        await Role.insert({data: response.data});
      }
    },
    fetchRoleOptions: async (context) => {
      if (Date.now() - context.state.optionsFetchedAt > UPDATE_THRESHOLD) {
        context.state.optionsFetchedAt = Date.now();
        const response = await api.get('/api/role/options');
        await Role.insertOrUpdate({data: response.data});
      }
    },
    fetchRole: async (context, id) => {
      if (!context.state.roleFetchedAt[id] || Date.now() - context.state.roleFetchedAt[id] > UPDATE_THRESHOLD) {
        context.state.roleFetchedAt[id] = Date.now();
        const response = await api.get(`/api/role/${id}`);
        await Role.insert({data: response.data});
      }
    },
    updateRole: async (context, payload) => {
      const response = await api.put(`/api/role/${payload.id}`, payload.data);
      await RolePermission.delete(rp => rp.roleId === payload.id);
      await Role.insert({data: response.data});
    },
    createRole: async (context, payload) => {
      const response = await api.post(`/api/role`, payload);
      await Role.insert({data: response.data});
    },
    deleteRole: async (context, payload) => {
      await api.delete(`/api/role/${payload.id}`);
      await Role.delete(payload.id);
    },
  },
  getters: {},
};
