



































import Vue from 'vue';
import {DataTable, DeleteDialog, TooltipButton, TooltipDate} from '~/components/common';
import PreviewDialog from '~/components/document/PreviewDialog.vue';
import VersionDialog from '~/components/document/VersionDialog.vue';
import {DocumentVersion} from '~/models';
import {ScreenState} from '~/store/screen.module';

export default Vue.extend({
  name: 'version-table',
  components: {TooltipDate, TooltipButton, DeleteDialog, PreviewDialog, VersionDialog, DataTable},
  props: {
    search: {type: String, default: ''},
    document: {type: Object, default: null},
    hideFields: {type: Array, default: () => []},
    actions: {type: Array, default: () => ['preview', 'edit', 'delete']},
    itemsPerPage: {type: Number, default: 10},
  },
  data() {
    return {
      edit: {dialog: false, version: null},
      remove: {dialog: false, version: null, document: null},
      preview: {dialog: false, url: null},
    };
  },
  computed: {
    items(): DocumentVersion[] {
      return this.document.versions;
    },
    additionalActions(): any[] {
      return this.actions.filter((action: any) => action && action.icon && action.tooltip && action.emit);
    },
    headers(): any[] {
      const fields = [
        {text: this.$t('label.filename'), value: 'filename'},
        {text: this.$t('label.name'), value: 'name', class: 'xs-and-up'},
        {text: this.$t('label.author'), value: 'author', class: 'md-and-up'},
        {text: this.$t('label.id'), value: 'id', class: 'md-and-up'},
        {text: this.$t('label.downloads'), value: 'downloads', align: 'end', class: 'lg-and-up'},
        {text: this.$t('label.createdAt'), value: 'createdAt', align: 'end', class: 'sm-and-up'},
        {text: this.$t('label.actions'), value: 'actions', sortable: false, align: 'center', width: '112px'},
      ];
      return fields.filter(field => !this.hideFields.includes(field.value));
    },
    screen(): ScreenState {
      return this.$store.state.screen;
    },
  },
  methods: {
    showPreviewDialog(version) {
      this.preview.url = version.url;
      this.preview.dialog = true;
    },
    showEditDialog(version) {
      this.edit.version = version;
      this.edit.dialog = true;
    },
    showDeleteDialog(version) {
      this.remove.document = this.document;
      this.remove.version = version;
      this.remove.dialog = true;
    },
    hasAction(action: string) {
      return this.actions.includes(action);
    },
    showField(field: string) {
      return !this.hideFields.includes(field);
    },
    async deleteVersion() {
      try {
        await this.$store.dispatch('document/deleteVersion', this.remove.version);
        await this.$store.dispatch('showSuccess', this.$root.$t('msg.deleteSuccess', {item: this.$root.$tc('p.version')}));
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
    },
  },
});
